

      <ng-container *ngIf="purchaseOrderViewList">
         <ng-container *ngFor="let purchaseOrderView of purchaseOrderViewList?.purchaseOrderViews; index as povIndex">
            <mat-accordion multi class="example-headers-align">
               <mat-expansion-panel class="border-light border my-1" [expanded]="purchaseOrderViewList.purchaseOrderViews.length===1">
                   <mat-expansion-panel-header 
                   [collapsedHeight]="'40px'" [expandedHeight]="'40px'"><!--[style]="(ps.poNumber && ps.poNumber!==null &&
                     (ps.poStatus === 'PO_CREATION_SUCCESS' || ps.poStatus === 'PO_UPDATE_SUCCESS'))?'background: #dbf4f5ab;':((ps.poStatus === 'PO_CANCELLATION_REQUIRED'))?'background:#f3a7a7':'background:#f8e5cfed'"-->
                       <mat-panel-title>
                         
                        <span class="p-1 f-12" > {{purchaseOrderView.poNumber==='N/A'?'Pending':purchaseOrderView.poNumber}}</span> <!--<span *ngIf="ps.fullPackage">( Full Package )</span>-->
                         
                        
                       </mat-panel-title>
                      <mat-panel-description >
                         
                        <span class="p-1 f-12" >Purchase Order Summary
                           <span *ngIf="purchaseOrderView?.poStatus==='PO_CANCELLATION_SUCCESS' 
                           &&  purchaseOrderView?.poProcessMethod!=='CANCELLED_POST_RECEIPT'" class="text-danger">(Cancelled)</span>
                           <span *ngIf="purchaseOrderView?.poStatus==='PO_CANCEL_OVERRIDDEN'" class="text-danger">(Overridden Cancellation)</span>
                           <span *ngIf="purchaseOrderView?.poStatus==='PO_UPDATE_OVERRIDDEN'" class="text-danger">(Overridden Update)</span>
                           <span *ngIf="purchaseOrderView?.poProcessMethod && purchaseOrderView?.poProcessMethod==='UPDATE_POST_RECEIPT'" class="text-danger">(Updated Post Receipt)</span>
                           <span *ngIf="purchaseOrderView?.poProcessMethod && purchaseOrderView?.poProcessMethod==='CANCELLED_POST_RECEIPT'" class="text-danger">(Cancelled Post Receipt)</span>                  
                        </span> 
                        
                            
                       </mat-panel-description>
                   </mat-expansion-panel-header>
                   <div class="row">
                        <div class="col-lg-9 col-sm-6">
                           
                                 <form name="purchaseOrderForm_{{povIndex}}" #purchaseOrderForm="ngForm" autocomplete="off" novalidate class="mb-3">
                                 <div class="row mt-1">
                                    <!--   
                                    <div class="col-1">
                                       <img style="display: block; max-width:70px; max-height:70px; width: auto; height: auto;" src="{{'brandIcon'|env}}" alt="Icon">
                                    </div>
                                    -->
                                    <div class="col-sm-3">
                                       <span class="ml-2">
                                          <div>
                                             <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Select Sage Company</label>
                                             <select class="form-control form-control-sm p-0" required style="font-size: 11px !important;" name="companyCode_{{povIndex}}" 
                                             [(ngModel)]="purchaseOrderView.companyCode"  (change)="poCompanyCodeChanged(purchaseOrderView)" [disabled]="!purchaseOrderView.editable || purchaseOrderView.poNumber!=='N/A'"
                                             #companyCodeVar="ngModel">
                                             <option class="f11" value="PHILCOS_CANADA">Philcos Canada</option>
                                             <option  class="f11" value="PHILCOS_USA">Philcos USA</option>
                                             </select>
                                       </div>
                                       </span>
                                       <!--!purchaseOrderView.editable || purchaseOrderView.poNumber!=='N/A'-->
                                    </div>
                                    <div class="col-sm-2">
                                    </div>
                                    <div class="col-3">
                                       <span class="p-1"  *ngIf="purchaseOrderView?.poStatus!=='PO_CANCELLATION_SUCCESS' && purchaseOrderView?.poStatus!=='PO_CANCEL_OVERRIDDEN'" >&nbsp;</span>
                                       <div *ngIf="purchaseOrderView?.poStatus==='PO_CANCELLATION_SUCCESS' || purchaseOrderView?.poStatus==='PO_CANCEL_OVERRIDDEN'">
                                          <div class="f-10"   >Cancellation Reason:</div>
                                          <div class="f-10 text-danger"   >({{purchaseOrderView.cancellationReason?purchaseOrderView.cancellationReason:'Reason not available'}})</div>
                                       </div>
                                       
                                    </div>

                                    <div class="col-4">
                                       <table class="table table-condensed table-sm table-bordered m-0">
                                          <thead class="thead-dark">
                                             <tr class="f-11">
                                                <th style="width: auto;" class=" align-middle text-center text-nowrap border">PO Date</th>
                                                <th style="width: auto;" class=" align-middle text-center text-nowrap border">Purchase Order Number</th>
                                             </tr>
                                          </thead>
                                          <tr class="f-11">
                                             <td  class="align-middle text-center text-nowrap">
                                                <span *ngIf="!purchaseOrderView.editable">{{purchaseOrderView?.poDate | date:'mediumDate'}}</span>
                                                <div class="d-flex d-inline" *ngIf="purchaseOrderView.editable">
                                                   <input matInput [matDatepicker]="poDate" id="poDateVarPicker" name="poDateVarPicker_{{povIndex}}" [(ngModel)]="purchaseOrderView.poDate" style="cursor: pointer;font-size: 11px;" 
                                                      class="form-control  form-control-sm" required #poDateVar="ngModel" [ngClass]="{'is-invalid':poDateVar.touched && poDateVar.errors}" [disabled]="!purchaseOrderView.editable">
                                                   <mat-datepicker #poDate></mat-datepicker>
                                                   <button class="btn btn-sm" type="button" (click)="poDate.open()" [disabled]="!purchaseOrderView.editable"><i class="fas fa-calendar-alt"></i></button>
                                                </div>
                                             </td>
                                             <!--<td class=" align-middle text-center text-nowrap">{{purchaseOrderView.poDate | date:'mediumDate'}}</td>-->
                                             <td class=" align-middle text-center text-nowrap">{{purchaseOrderView.poNumber}}
                                                <span *ngIf="purchaseOrderView?.poStatus==='PO_CANCELLATION_SUCCESS'" class="f-9 badge badge-pill badge-danger">CANCELLED</span>
                                             </td>
                                          </tr>
                                       </table>
                                    </div>
                                 </div>

                                    <div class="card card-body p-2 mt-2">
                                       <div class="row" *ngIf="true">
                                          <div class="col-lg-6">
                                             <label class="mb-0" style="font-size: .60rem; color: lightslategray;">Vendor Address:</label>
                                          </div>
                                          <div class="col-lg-6" *ngIf="true">
                                             <label class="mb-0" style="font-size: .60rem; color: lightslategray;">Ship To:</label>
                                          </div>
                                       </div>

                                       <div class="row" *ngIf="true">
                                          <div class="col-lg-6">
                                             <span class="form-control form-control-sm" style="background: transparent; font-size: 13px; padding-left: .30rem;border-width: 0px;">
                                                {{purchaseOrderView?.vendorName}}
                                             </span>
                                          </div>
                                          <div class="col-lg-3" *ngIf="true">
                                             <div>
                                                <select class="form-control form-control-sm p-0" required style="font-size: 10px !important;" name="purchaseOrderViewShipToCode_{{povIndex}}" 
                                                [(ngModel)]="purchaseOrderView.shipToCode"  [disabled]="!purchaseOrderView.editable"
                                                #purchaseOrderViewShipToCodeVar="ngModel">
                                                <option *ngFor="let shipToItem of purchaseOrderView.shipToList" class="f10" [value]="shipToItem.LOCATION">{{shipToItem.LOCATION}} - {{shipToItem.DESC}}</option>
                                                
                                                </select>
                                          </div>
                                          </div>
                                       </div>
                                    </div>


                                       <div class="mt-2">
                                          <table class="table table-condensed table-sm table-bordered mb-1">
                                             <thead class="thead-dark">
                                                <tr class="f-11">
                                                   <th style="width: auto;" class=" align-middle text-center text-nowrap border">Reference</th>
                                                   <th style="width: auto;" class=" align-middle text-center text-nowrap border">Description</th>
                                                   <th style="width: auto;" class=" align-middle text-center text-nowrap border">Vendor No.</th>
                                                   <!--<th style="width: auto;" class=" align-middle text-center text-nowrap border">Bill To</th>-->
                                                   <th style="width: auto;" class=" align-middle text-center text-nowrap border">Ship Via</th>
                                                   <th style="width: auto;" class=" align-middle text-center text-nowrap border">EX Factory</th>
                                                   <th style="width: auto;" class=" align-middle text-center text-nowrap border">ETA</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <tr class="f-11">
                                                   <td style="width: auto;" class="align-middle text-center text-nowrap">{{purchaseOrderView?.reference}}</td>
                                                   <td style="width: auto;" class="align-middle text-center text-nowrap">{{purchaseOrderView?.description}}</td>
                                                   <td style="width: auto;" class="align-middle text-center text-nowrap">{{purchaseOrderView?.vendorCode}}</td>
                                                   <!--<td style="width: auto;" class="align-middle text-center text-nowrap">{{purchaseOrderView?.terms}}</td>-->
                                                   <td style="width: auto;" class="align-middle text-center text-nowrap">
                                                      <div>
                                                         <select class="form-control form-control-sm p-0" required style="font-size: 10px !important;" name="purchaseOrderViewShipViaCode_{{povIndex}}" 
                                                         [(ngModel)]="purchaseOrderView.shipViaCode"  [disabled]="!purchaseOrderView.editable"
                                                         #shipViaVar="ngModel">
                                                         <option *ngFor="let shipViaItem of purchaseOrderView.shipViaList" class="f10" [value]="shipViaItem.CODE">{{shipViaItem.CODE}} ({{shipViaItem.NAME}})</option>
                                                         
                                                         </select>
                                                   </div>
                                                   </td>
                                                   <td style="width: 10%;" class="align-middle text-center text-nowrap">
                                                      <span *ngIf="!purchaseOrderView.editable">{{purchaseOrderView?.exfactoryDate | date:'mediumDate'}}</span>
                                                      <div class="d-flex d-inline" *ngIf="purchaseOrderView.editable">
                                                         <input matInput [matDatepicker]="exfactoryDate" id="exfactoryDatePicker" name="exfactoryDatePicker_{{povIndex}}" [(ngModel)]="purchaseOrderView.exfactoryDate" style="cursor: pointer;font-size: 11px;" 
                                                            class="form-control  form-control-sm" required #exfactoryDateVar="ngModel" [ngClass]="{'is-invalid':exfactoryDateVar.touched && exfactoryDateVar.errors}" disabled><!--[disabled]="!purchaseOrderView.editable"-->
                                                         <mat-datepicker #exfactoryDate></mat-datepicker>
                                                         <button class="btn btn-sm" type="button" (click)="exfactoryDate.open()" [disabled]="!purchaseOrderView.editable"><i class="fas fa-calendar-alt"></i></button>
                                                      </div>
                                                   </td>

                                                   <td style="width: 10%;" class="align-middle text-center text-nowrap">
                                                      <span *ngIf="!purchaseOrderView.editable">{{purchaseOrderView?.etaDate | date:'mediumDate'}}</span>
                                                      <div class="d-flex d-inline" *ngIf="purchaseOrderView.editable">
                                                         <input matInput [matDatepicker]="etaDate" id="etaDatePicker" name="etaDatePicker_{{povIndex}}" [(ngModel)]="purchaseOrderView.etaDate" style="cursor: pointer;font-size: 11px;" 
                                                            class="form-control  form-control-sm" required #etaDateVar="ngModel" [ngClass]="{'is-invalid':etaDateVar.touched && etaDateVar.errors}" disabled><!--[disabled]="!purchaseOrderView.editable"-->
                                                         <mat-datepicker #etaDate></mat-datepicker>
                                                         <button class="btn btn-sm" type="button" (click)="etaDate.open()" [disabled]="!purchaseOrderView.editable"><i class="fas fa-calendar-alt"></i></button>
                                                      </div>
                                                   </td>
                                                   <!--<td style="width: auto;" class="align-middle text-center text-nowrap">{{purchaseOrderView?.etaDate | date:'mediumDate'}}</td>-->
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>


                                       <div class="mt-2">
                                          
                                          <table class="table table-condensed table-sm table-bordered table-striped mb-1">
                                             <thead class="thead-dark">
                                                <tr class="f-11">
                                                   <th style="width: 10%;" class=" align-middle text-center text-nowrap border">Qty Ord.</th>
                                                   <!--<th style="width: 15%;" class=" align-middle text-center text-nowrap border">Vendor SKU</th>-->
                                                   <th style="width: 15%;" class=" align-middle text-center text-nowrap border">SKU</th>
                                                   <th style="width: 30%;" class=" align-middle text-center text-nowrap border">Description</th>
                                                   <th style="width: 25%;" class=" align-middle text-center text-nowrap border">
                                                      Location
                                                      <div class="row">
                                                         <div class="col-sm-12">
                                                            <div>
                                                               <!--<label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Item Ship To Location</label> -->
                                                               <select class="form-control form-control-sm p-0" required style="font-size: 10px !important;" name="purchaseOrderDetailLocationMasterSelector_{{povIndex}}" 
                                                               [(ngModel)]="purchaseOrderView.povdLocationMasterSelector"  (change)="purchaseOrderDetailLocationMasterSelectionChanged(purchaseOrderView)"
                                                               #purchaseOrderDetailLocationMasterSelectorVar="ngModel" [disabled]="!purchaseOrderView.editable">
                                                               <option *ngFor="let shipToItem of purchaseOrderView.shipToList" class="f10"  [value]="shipToItem.LOCATION">{{shipToItem.LOCATION}} - {{shipToItem.DESC}}</option>
                                                               
                                                               </select>
                                                         </div>
                                                         </div>
                                                      </div>
                                                   </th>
                                                   <th style="width: 10%;" class=" align-middle text-center text-nowrap border">Unit Cost</th>
                                                   <th style="width: 10%;" class=" align-middle text-center text-nowrap border">UOM</th>
                                                   <th style="width: 10%;" class=" align-middle text-center text-nowrap border">Extended Price</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <ng-container *ngFor="let povd of purchaseOrderView.details; index as povDetailIndex">
                                                   <ng-container *ngIf="!purchaseOrderView.editable || (purchaseOrderView.editable && povd.entryMethod!=='MANUAL')">
                                                      <tr class="f-11">
                                                         <td class="align-middle text-right text-nowrap pr-3"> {{povd?.quantityOrdered}}</td>
                                                         
                                                         <td class="align-middle text-center text-nowrap">{{povd?.itemNumber}}</td>
                                                         <td class="align-middle text-center text-nowrap">{{povd?.itemDescription}}</td>
                                                         <td class="align-middle text-left text-nowrap pl-3">
                                                            <div>
                                                               <select class="form-control form-control-sm p-0" required style="font-size: 10px !important;" name="purchaseOrderDetailLocation_{{povIndex}}_{{povDetailIndex}}" 
                                                               [(ngModel)]="povd.location"  disabled
                                                               #purchaseOrderDetailLocationVar="ngModel">
                                                               <option *ngFor="let shipToItem of purchaseOrderView.shipToList" class="f10" [value]="shipToItem.LOCATION">{{shipToItem.LOCATION}} - {{shipToItem.DESC}}</option>
                                                               
                                                               </select>
                                                            </div>
                                                         </td>
                                                         <td class="align-middle text-right text-nowrap pr-3">{{povd?.unitCost}} ({{povd?.currency}})</td>
                                                         <td class="align-middle text-center text-nowrap">EA</td>
                                                         <td class="align-middle text-right text-nowrap pr-3">&nbsp;</td>
                                                      </tr>
                                                   </ng-container>
                                                   <ng-container *ngIf="purchaseOrderView.editable && povd.entryMethod==='MANUAL'">
                                                      <tr class="f-11">
                                                         <td class="align-middle text-right text-nowrap pr-3">
                                                            <div class="d-flex">
                                                               <span class=" pr-1"><button type="button" class="btn btn-sm f-9 btn-danger" (click)="removeManualPurchaseOrderViewDetail(purchaseOrderView,povd)"><i class="fas fa-trash fa-sm text-light"></i></button></span>

                                                               <input type="number" name="qtyOrdered_{{povDetailIndex}}_{{povIndex}}" class="form-control form-control-sm" 
                                                               #qtyOrderedVar required style="text-align: right; font-size: 11px;" [(ngModel)]="povd.quantityOrdered"
                                                               [ngClass]="{'is-invalid':qtyOrderedVar.touched && qtyOrderedVar.invalid}">
                                                            </div>
                                                          </td><!-- {{povd?.quantityOrdered}}-->
                                                         
                                                         <td class="align-middle text-center text-nowrap">
                                                            <input type="text" name="itemNumber{{povDetailIndex}}_{{povIndex}}" class="form-control form-control-sm f-11"
                                                               style="font-size:11px; padding-left: 0.2rem; padding-right: 0.2rem; margin-bottom: 0.25rem;" required [disabled]="!purchaseOrderView.editable"
                                                               [(ngModel)]="povd.itemNumber">
                                                            </td>
                                                         <td class="align-middle text-center text-nowrap">
                                                            <input type="text" name="itemDescription_{{povDetailIndex}}_{{povIndex}}" class="form-control form-control-sm f-11"
                                                               style="font-size:11px; padding-left: 0.2rem; padding-right: 0.2rem; margin-bottom: 0.25rem;" required [disabled]="!purchaseOrderView.editable"
                                                               [(ngModel)]="povd.itemDescription">
                                                         </td>
                                                         <td class="align-middle text-left text-nowrap pl-3">
                                                            <div>
                                                               <select class="form-control form-control-sm p-0" required style="font-size: 10px !important;" name="purchaseOrderDetailLocation_{{povIndex}}_{{povDetailIndex}}" 
                                                               [(ngModel)]="povd.location" disabled
                                                               #purchaseOrderDetailLocationVar="ngModel">
                                                               <option *ngFor="let shipToItem of purchaseOrderView.shipToList" class="f10" [value]="shipToItem.LOCATION">{{shipToItem.LOCATION}} - {{shipToItem.DESC}}</option>
                                                               
                                                               </select>
                                                         </div>
                                                         </td>
                                                         <td class="align-middle text-right text-nowrap pr-3">
                                                            <div class="d-flex">
                                                               <input type="number" name="unitCost_{{povDetailIndex}}_{{povIndex}}" class="form-control form-control-sm" 
                                                                  #unitCostVar required style="text-align: right; font-size: 11px;" [(ngModel)]="povd.unitCost"
                                                                  [ngClass]="{'is-invalid':unitCostVar.touched && unitCostVar.invalid}">
                                                               <span>({{povd?.currency}})</span>
                                                            </div>
                                                         </td>
                                                         <td class="align-middle text-center text-nowrap">EA</td>
                                                         <td class="align-middle text-right text-nowrap pr-3">&nbsp;</td>
                                                      </tr>
                                                   </ng-container>
                                                </ng-container>

                                                <tr class="f-11">
                                                   <td class="align-middle text-right text-nowrap pr-3" style="border-top: double; font-weight:700;">{{purchaseOrderView.totalQty }}</td>
                                                   <td class="align-middle text-center text-nowrap" style="border-top: double; font-weight:700;">Total Qty Ordered</td>
                                                   <td colspan="5"> 
                                                      &nbsp;
                                                      <!--<span *ngIf="purchaseOrderView.editable &&
                                                         purchaseOrderView.poNumber!=='N/A' && allowPoCreation  && purchaseOrderView.poStatus!=='PO_CANCELLATION_REQUIRED'
                                                      ">
                                                         <button type="button" class="btn btn-sm f-9 btn-warning" (click)="addManualPurchaseOrderViewDetail(purchaseOrderView)">+ Add Line</button>
                                                      </span>-->
                                                   </td>
                                                </tr>

                                             </tbody>
                                          </table>
                                       </div>
                                       <div class="mt-1" style="float:right;" >
                                          <ng-container *ngIf="(
                                            (('PO_CANCELLATION_REQUIRED'=== purchaseOrderView.poStatus)|| (allowPoCreation && purchaseOrderView.poStatus!=='PO_CANCELLATION_SUCCESS' && purchaseOrderView.poStatus!=='PO_CANCEL_OVERRIDDEN'))
                                          ) then enableEditButton else disableEditButton"></ng-container>
                                          <ng-template #enableEditButton>
                                             <button type="button" *ngIf=" !purchaseOrderView.editable && userAccess?.canEditPurchaseOrder" class="btn btn-sm btn-warning ml-1" (click)="purchaseOrderView.editable=true;" >Edit</button>  
                                          </ng-template>
                                          <ng-template #disableEditButton>
                                             <span *ngIf="latestSummary && !allowPoCreation" class="text-muted f-10">**Costing is in-progress for the Sales Order. PO can be only created/updated post costing approval</span>
                                             <span *ngIf="!latestSummary" class="text-danger f-10">**This is not the latest version of the Summary.</span>
                                          </ng-template>
                                          <ng-container *ngIf="purchaseOrderView.editable">
                                             <button type="button" *ngIf="purchaseOrderView.poNumber==='N/A' && allowPoCreation" class="btn btn-sm btn-success ml-1" [disabled]="!purchaseOrderForm.valid" (click)="createPO(purchaseOrderView)">Create PO</button>
                                             <button type="button" *ngIf="purchaseOrderView.poNumber!=='N/A' && allowPoCreation  && purchaseOrderView.poStatus!=='PO_CANCELLATION_REQUIRED'" class="btn btn-sm btn-warning ml-1" (click)="updatePO(purchaseOrderView)" [disabled]="!purchaseOrderForm.valid">Update PO</button>
                                             <button type="button" *ngIf="purchaseOrderView.poNumber!=='N/A'" class="btn btn-sm btn-danger ml-1" [disabled]="!purchaseOrderForm.valid" (click)="cancelPO(purchaseOrderView)">Cancel PO</button>
                                             <button type="button" (click)="purchaseOrderView.editable=false;" class="btn btn-sm btn-dark ml-1" >Cancel Edit</button>
                                          </ng-container>
                                       </div>
                                    </form>
                           
                        </div>
                        <div class="col-lg-3 sol-sm-6">
                           <ng-container>
                              <div *ngIf="userAccess?.canRetryPurchaseOrderPdf">
                                 <button type="button" *ngIf="purchaseOrderView.poNumber!=='N/A' && latestSummary" class="btn btn-sm btn-danger ml-1"  (click)="retryPrinterPurchaseOrderPDF(purchaseOrderView)">Retrieve PO PDF</button>
                              </div>
                              <div class="mt-2">
                                 
                                          <app-attachment-operations [header]="purchaseOrderView?.vendorName+' - Printer PO File'" [editable]="false" [createAction]="false" 
                                          [attachments]="purchaseOrderView?.poAttachments"
                                          [canDownload]="userAccess?.canDownloadSalesFiles"
                                          [category]="'SALES_PRINTER_PO_FILES'" [showHeader]="true" [headerSize]="'s13'" [canQuickView]="userAccess?.canQuickViewSalesFiles">
                                    </app-attachment-operations>
                                 
                              </div>
                           </ng-container>
                        </div>
                     </div>
               </mat-expansion-panel>
            </mat-accordion>
   </ng-container>
         
</ng-container>
   
   
