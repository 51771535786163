
<ng-container *ngIf="product && product.type!=='BLANKS'">
<div class="card transform-on-hover h-80" *ngIf="product!==undefined">
    <div (click)="changePriority(product)" class="card-img-overlay p-0 m-0 d-flex justify-content-center align-items-center" 
         [matTooltip]="product.priority?'Remove priority':'Mark as priority'" matTooltipPosition="left" [matTooltipDisabled]="!userAccess?.canChangeProductPriority" matTooltipClass="bg-tomato"
                            style="display: block; height:1rem;width: 1rem;top:5%; left:90% !important;">
                        <span *ngIf="!product.priority && userAccess?.canChangeProductPriority">
                            <i class="far fa-star fa-lg"
                                style="color: #6c757d24;" 
                                onmouseover="this.style.color='tomato'" 
                                onmouseout="this.style.color='#6c757d24'">
                            </i>
                        </span>
                            <span *ngIf="product.priority">
                                <i class="fas fa-star fa-lg" style="color: tomato;"  >
                                </i>
                            </span>
    </div>
    <!--<div (click)="product.priority=false" *ngIf="product.priority" class="card-img-overlay p-0 m-0 d-flex justify-content-center align-items-center"  matTooltip="Remove priority" matTooltipPosition="left" matTooltipClass="bg-danger"
                            style="display: block; height:1rem;width: 1rem;top:5%; left:90% !important;">
                            <i class="fas fa-star fa-lg" 
                                style="color: tomato;" >
                            </i>
    </div>-->
    <a class="lightbox"  matTooltip="View product" matTooltipPosition="below" matTooltipClass="bg-dark"
    (click)="navigateToProduct()"  [routerLink]="!stopProductNavigation?['/product/view/'+product.licensorId+'/'+product.brandId+'/'+product.id]:[]"
    ><!--[routerLink]="['/product/view/'+product.licensorId+'/'+product.brandId+'/'+product.id]"-->
        
        <div class=" card-body d-flex justify-content-center align-items-center"
            style="height:250px;">
            <img [src]="product.productImages[0].thumbnailSignedUrl"
                        class="card-img-top img-auto-size zoom-2-delayed" *ngIf="product.productImages && product.productImages[0]" (error)="handleThumbnailError(product.productImages[0],$event)">
            <img src="assets/default-image.jpg" 
                class="card-img-top img-auto-size" *ngIf="!product.productImages || !product.productImages[0]">
            <div class="card-img-overlay d-flex justify-content-left p-0" *ngIf="product.exclusiveToCustomers" style="width: fit-content;height: fit-content;">
                <img src="assets/exclusiveBanner.png" 
                class="" width="100" height="100">
            </div>
        </div>
    </a>
    <div  [class]="'card-footer  card-footer-'+approvalStatusColor(product.approvalStatus)+' card-footer-bg-'+footerBgColor(product)">
        <span  *ngIf="evaluateIsSelectable()">
            <div class="d-flex justify-content-center align-items-center m-0">
                <h6 class="d-inline m-0 text-truncate f-13" style="cursor: pointer;" (click)="selectionChanged(product)" matTooltip="{{isProductSelectedForCatalog(product.id)?'Remove from ':'Add to '}}catalog selection" matTooltipClass="bg-dark">{{product.sku}}</h6>
            </div>
            <div (click)="selectionChanged(product)" style="cursor: pointer;" class="d-flex justify-content-center f-13 text-color-{{approvalStatusColor(product.approvalStatus)}}">{{product.approvalStatus}}&nbsp;</div>
            <div class="d-flex justify-content-center align-items-center m-0" style="cursor: pointer;">
                <div (click)="selectionChanged(product)" style="cursor: pointer;" class="d-inline-block text-truncate" style="font-size: smaller;">{{product.color}} | {{product.treatment}}</div>
            </div>
            <div class="d-flex justify-content-between align-items-center m-0" style="cursor: pointer;">
                <div class="d-flex justify-content-left" style="display: block; height:15px;" (click)="selectionChanged(product)"
                matTooltip="{{isProductSelectedForCatalog(product.id)?'Remove from ':'Add to '}}catalog selection" matTooltipClass="bg-dark">
                    <label class="cb-container">&nbsp;
                        <input type="checkbox" disabled (change)="selectionChanged(product)"
                            [checked]="isProductSelectedForCatalog(product.id)">
                        <span class="checkmark-sm"></span>
                    </label>
                </div>
                <div (click)="selectionChanged(product)" style="cursor: pointer;" class="d-inline-block text-truncate" style="font-size: smaller;">{{product.licensorName}} | {{product.brandName}}</div>
                <div class="d-inline ml-0" 
                *ngIf="(userAccess?.canDeleteProduct || (product.origination==='Estimation' && userAccess?.canDeleteAdhocProduct)) && !stopProductDeletion && !product.isCollectionMember" >
                <button type="button"
                class="btn btn-circle btn-circle-xsm"
                (click)="deleteProduct(product)" matTooltip="Delete Product" matTooltipClass="bg-dark"><i
                    class="fas fa-trash fa-sm text-light d-flex justify-content-center"></i></button>
                </div>
                <div class="d-inline ml-0" *ngIf="!(userAccess?.canDeleteProduct) || product.isCollectionMember || stopProductDeletion" >&nbsp; </div>
                
            </div>
            
            
        </span>
        
        <span *ngIf="!evaluateIsSelectable()">
            <div class="d-flex justify-content-center align-items-center m-0">
                <h6 class="d-inline m-0 text-truncate f-13">{{product.sku}}</h6>
            </div>
            <div class="d-flex justify-content-center text-color-{{approvalStatusColor(product.approvalStatus)}} f-13">{{product.approvalStatus}}&nbsp;</div>
            <div class="d-flex justify-content-center align-items-center m-0" style="cursor: pointer;">
                <div style="cursor: pointer;" class="d-inline-block text-truncate" style="font-size: smaller;">{{product.color}} | {{product.treatment}}</div>
            </div>
            <div class="d-flex justify-content-between align-items-center m-0">
                <div class="d-flex justify-content-left" style="display: block; height:15px;" >&nbsp;</div>
                <div class="d-inline-block text-truncate " style="font-size: smaller;">{{product.licensorName}} | {{product.brandName}}</div>
                <div class="d-inline ml-0" *ngIf="userAccess?.canDeleteProduct && !stopProductDeletion &&  !product.isCollectionMember" ><button type="button"
                    class="btn btn-circle btn-circle-xsm"
                    (click)="deleteProduct(product)" matTooltip="Delete Product" matTooltipClass="bg-dark"><i
                        class="fas fa-trash fa-sm text-light"></i></button>
                </div>
                <div class="d-inline ml-0" *ngIf="!(userAccess?.canDeleteProduct) || product.isCollectionMember || stopProductDeletion" >&nbsp; </div>
            </div>
            
        </span>
    </div>
</div>

</ng-container>


<!----------------------------------------- START - BLANKS PRODUCT TYPE ----------------------------------------->

<ng-container *ngIf="product && product.type==='BLANKS'">
   <div class="card transform-on-hover h-80" *ngIf="product!==undefined">
      <a class="lightbox" matTooltip="View product" matTooltipPosition="below" matTooltipClass="bg-dark" (click)="navigateToProduct()"
         [routerLink]="!stopProductNavigation?['/blanks-product/view/'+product.id]:[]">

         <div class=" card-body d-flex justify-content-center align-items-center" style="height:250px;">
            <img [src]="product.productImages[0].thumbnailSignedUrl" class="card-img-top img-auto-size zoom-2-delayed" *ngIf="product.productImages && product.productImages[0]"
               (error)="handleThumbnailError(product.productImages[0],$event)">
            <img src="assets/default-image.jpg" class="card-img-top img-auto-size" *ngIf="!product.productImages || !product.productImages[0]">
            <div class="card-img-overlay d-flex justify-content-left p-0" *ngIf="product?.blanksManufacturerMetadata?.specialityType" style="width: fit-content;height: fit-content;">
               <!-- <img src="assets/exclusiveBanner.png" class="" width="100" height="100"> -->
            </div>
         </div>
      </a>
      <div [class]="'card-footer'">
         <span *ngIf="evaluateIsSelectable()">
            <div class="d-flex justify-content-center align-items-center m-0">
               <h6 class="d-inline m-0 text-truncate f-13" style="cursor: pointer;" (click)="selectionChanged(product)"
                  matTooltip="{{isProductSelectedForCatalog(product.id)?'Remove from ':'Add to '}}catalog selection" matTooltipClass="bg-dark">{{product.sku}}
                  <ng-container *ngIf="product?.blanksManufacturerMetadata?.specialityType">
                     <span class="badge badge-warning superscript-badge f-9">{{product?.blanksManufacturerMetadata?.specialityDescription}}</span>
                  </ng-container>
               </h6>
            </div>
            <div class="d-flex justify-content-center align-items-center m-0" style="cursor: pointer;">
               <div (click)="selectionChanged(product)" style="cursor: pointer;" class="d-inline-block text-truncate" style="font-size: smaller;">{{product.color}}
                  {{product.treatment ? ' | ' + product.treatment : ''}}</div>
            </div>
            <div class="d-flex justify-content-between align-items-center m-0" style="cursor: pointer;">
               <div class="d-flex justify-content-left" style="display: block; height:15px;" (click)="selectionChanged(product)"
                  matTooltip="{{isProductSelectedForCatalog(product.id)?'Remove from ':'Add to '}}catalog selection" matTooltipClass="bg-dark">
                  <label class="cb-container">&nbsp;
                     <input type="checkbox" disabled (change)="selectionChanged(product)" [checked]="isProductSelectedForCatalog(product.id)">
                     <span class="checkmark-sm"></span>
                  </label>
               </div>
               <div (click)="selectionChanged(product)" style="cursor: pointer;" class="d-inline-block text-truncate" style="font-size: smaller;">
                  {{product?.blanksManufacturerMetadata?.manufacturer}} | {{product?.blanksManufacturerMetadata?.manufacturerSeries}}</div>
               <div class="d-inline ml-0"
                  *ngIf="(userAccess?.canDeleteProduct || (product.origination==='Estimation' && userAccess?.canDeleteAdhocProduct)) && !stopProductDeletion && !product.isCollectionMember">
                  <button type="button" class="btn btn-circle btn-circle-xsm" (click)="deleteProduct(product)" matTooltip="Delete Product" matTooltipClass="bg-dark"><i
                        class="fas fa-trash fa-sm text-light d-flex justify-content-center"></i></button>
               </div>
               <div class="d-inline ml-0" *ngIf="!(userAccess?.canDeleteProduct) || product.isCollectionMember || stopProductDeletion">&nbsp; </div>
            </div>
         </span>

         <span *ngIf="!evaluateIsSelectable()">
            <div class="d-flex justify-content-center align-items-center m-0">
               <h6 class="d-inline m-0 text-truncate f-13">{{product.sku}}</h6>
            </div>
            <div class="d-flex justify-content-center align-items-center m-0" style="cursor: pointer;">
               <div style="cursor: pointer;" class="d-inline-block text-truncate" style="font-size: smaller;">{{product.color}} {{product.treatment ? ' | ' + product.treatment : ''}}</div>
            </div>
            <div class="d-flex justify-content-between align-items-center m-0">
               <div class="d-flex justify-content-left" style="display: block; height:15px;">&nbsp;</div>
               <div class="d-inline-block text-truncate " style="font-size: smaller;">{{product?.blanksManufacturerMetadata?.manufacturer}} |
                  {{product?.blanksManufacturerMetadata?.manufacturerSeries}}</div>
               <div class="d-inline ml-0" *ngIf="userAccess?.canDeleteProduct && !stopProductDeletion &&  !product.isCollectionMember"><button type="button" class="btn btn-circle btn-circle-xsm"
                     (click)="deleteProduct(product)" matTooltip="Delete Product" matTooltipClass="bg-dark"><i class="fas fa-trash fa-sm text-light"></i></button>
               </div>
               <div class="d-inline ml-0" *ngIf="!(userAccess?.canDeleteProduct) || product.isCollectionMember || stopProductDeletion">&nbsp; </div>
            </div>

         </span>
      </div>
   </div>
</ng-container>