<div>

   <!--Generic filters start-->
<div class="backdrop" *ngIf="showBackDrop"></div>
   <div id="myNav" class="overlay shadow-lg">
      <div class="mt-4 d-flex justify-content-center">
         <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
         <h4>Advanced Filters</h4>

      </div>
      <hr>
      <div class="d-flex justify-content-end ">
         <div class="m-1 d-flex justify-content-end">
            <button class="btn bg-success text-white" (click)="closeNav()" type="button">Apply Filters</button>
         </div>
         <div class="m-1 d-flex justify-content-end">
            <button type="button" class="btn bg-secondary text-white mr-2" (click)="reset()" matTooltip="Reset applied filters"
               matTooltipPosition="right" matTooltipClass="bg-dark">
               Reset All Filters
            </button> <!-- (click)="resetAdvSearch()" <i class="fas fa-undo fa-sm"></i>-->
         </div>
      </div>
      <div class="container">
         <div class="mt-2" style="max-height: calc(80vh)"><!--overflow-hidden -->
            <div class="row">
               <div class="col-3 ml-1">
                  <div class="list-group list-group-flush" id="list-tab" role="tablist">
                     <ng-container *ngFor="let filterConfig of searchConfig.filterConfigs;index as fcIndex">
                        <a class="list-group-item list-group-item-action" [class]="fcIndex===0?'active':''" id="filter-item-list-section-select"
                           data-toggle="list" href="#filter-item-list-section" role="tab" aria-controls="home" (click)="selectedFilterConfig=filterConfig">
                           <span class="badge badge-pill badge-dark" *ngIf=" filterConfig.selectedCount && filterConfig.selectedCount>0">{{filterConfig.selectedCount}}</span>
                           
                           {{filterConfig.label}}
                        </a>

                     </ng-container>
                  </div>
               </div>
               <div class="col-8 border">
                  <div class="tab-content" id="nav-tabContent">
                     <div class="tab-pane fade show active" id="filter-item-list-section" role="tabpanel"
                        aria-labelledby="filter-item-list">
                        <div class="" *ngIf="selectedFilterConfig">
                           <!--prospect filters section-->
                          
                                 <!--DATE RANGE FILTER STARTS-->
                                 <ng-container *ngIf="selectedFilterConfig.filterType==='DATE_RANGE'">
                                    <div >
                                       <div class="d-flex justify-content-center ">
                                          <div class="w-80">
                                             <label class="mb-0 ml-1"
                                                style="font-size: .60rem; color: lightslategray;">{{selectedFilterConfig.label}}</label>
                                             <input type="text" matInput class="form-control form-control-sm mb-2" #daterange
                                                ngxDaterangepickerMd [(ngModel)]="selectedFilterConfig.currentSelection"
                                                [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [ranges]="ranges"
                                                [linkedCalendars]="true" name="dateRange" [showClearButton]="true"
                                                (change)="chooseDateRange(selectedFilterConfig,$event);" [locale]="locale"
                                                [showRangeLabelOnInput]="true" placeholder="Select Date Range..." readonly 
                                                style="background-color: transparent; z-index: 500; display: inline !important;" />
                                                
                                                <!--<ngx-daterangepicker-material [(ngModel)]="selectedFilterConfig.currentSelection"
                                                [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [ranges]="ranges"
                                                [linkedCalendars]="true" name="dateRange" [showClearButton]="true"
                                                (datesUpdated)="chooseDateRange(selectedFilterConfig,$event)" [locale]="locale"
                                                [showRangeLabelOnInput]="true" >
                                                </ngx-daterangepicker-material>-->
                                          </div>
                                       </div>
                                    </div>
                                 </ng-container>
                                 <!--DATE RANGE FILTER ENDS-->
                                 <!--CHECKED IN LIST  STARTS-->
                                 <ng-container *ngIf="selectedFilterConfig.filterType==='IN_CHECKLIST'">
                                    <div class="modal-body">
                                       <input class="form-control mb-2" #modalSearchInput
                                           (keyup)="onKeyupModalFilter(modalSearchInput.value,selectedFilterConfig)" placeholder="Find..">
                                       <div class="mb-2 d-flex justify-content-end">
                                           <a (click)=" selectedAllForSearch(true,selectedFilterConfig)" class="btn btn-sm btn-warning bg-white">Select All</a>
                                           <a (click)=" selectedAllForSearch(false,selectedFilterConfig)" class=" btn btn-sm btn-danger bg-white ml-2">Unselect All</a>
                                       </div>
                                       <div class="ml-2" *ngFor="let option of selectedFilterConfig.options">
                                           <div class="row" *ngIf="option[selectedFilterConfig.optionDislpayVar].toUpperCase().includes(modalSearchInput.value.toUpperCase())">
                                               <label class="cb-container">
                                                   <h6 class="ml-4 mt-1">{{option[selectedFilterConfig.optionDislpayVar]}}</h6>
                                                   <input type="checkbox" (change)="option.selected=$event.target.checked;chooseInCheckList(selectedFilterConfig)"
                                                       [checked]="option.selected" #check> <!-- (change)="selectedForSearch($event,item,type)"-->
                                                   <span class="checkmark"></span>
                                               </label>
                                           </div>
                                       </div>
                                   </div>
                                 </ng-container>
                                 <!--CHECKED IN LIST  ENDS-->
                                 <!--DROPDOWN IN LIST  STARTS-->
                                 <ng-container *ngIf="selectedFilterConfig.filterType==='IN_LIST'">
                                    <div class="col-auto">
                                       <div class="d-flex justify-content-center ">
                                          <div>
                                             <label class="mb-0 ml-1"
                                                style="font-size: .60rem; color: lightslategray;">{{selectedFilterConfig.label}}</label>
                                             <select class="form-control form-control-sm custom-select f-10" id="inList"
                                                name="inList" [(ngModel)]="selectedFilterConfig.currentSelection"
                                                (change)="chooseInList(selectedFilterConfig);">
                                                <!--[(ngModel)]="excludeProductType" -->
                                                <option value="ALL">Show All</option>
                                                <option *ngFor="let option of selectedFilterConfig.options"
                                                   [ngValue]="option[selectedFilterConfig.optionValueVar]">
                                                   {{option[selectedFilterConfig.optionDislpayVar]}}</option>
                                             </select>
                                          </div>
                                       </div>
                                    </div>
                                 </ng-container>
                                 <!--DROPDOWN IN LIST  ENDS-->
                        </div>
                     </div>

                  </div>
               </div>
            </div>
         </div>
         
      </div>
   </div>
   


   <!--<div class="ml-2 d-flex align-items-center">
      <button type="button" class="f-13 btn btn-link" style="color: tomato;" (click)="showHideFilter()" #showHideBtn *ngIf="!embedded">{{filterVisible?'Hide ':'Show '}} Filter</button>
  </div>-->

  <input type="hidden" [value]="getSelectedFilterCriteriaCount()" #getSelectedFilterCrtieraCountVar>
  
   <div class="row d-flex align-items-end">
      <div class="col-{{searchBarColLength}}">
         <div class="d-flex d-inline ">
            <input type="search" name="searchItemString" [(ngModel)]="searchConfig.searchCriteria.searchString"
               class="form-control f-13" (keyup.enter)="searchBtn.click()" id="inlineFormInputGroupUsername"
               [placeholder]="placeholder" #SkuCode (input)="searchInput($event)">
            <div class="ml-1">
               <button type="button" class="btn bg-dark text-white f-13" (click)="search($event)"
                  [disabled]="!(searchConfig.searchCriteria?.searchString?.length>0)" #searchBtn>Search</button>
            </div>
            <ng-container *ngIf="searchConfig.filterType==='INTERNAL'">
               <div class="ml-1 d-flex align-items-end">
                  <div style="cursor:pointer" class="ml-1 " (click)="openNav()" *ngIf="searchConfig.filterConfigs"><i class="fas fa-filter fa-x mr-1"
                        [class]="getSelectedFilterCrtieraCountVar.value>0?'text-warning':'text-muted'"></i></div>
                  <span class="badge badge-pill badge-danger f-8" style="position: absolute;top:0%" *ngIf="getSelectedFilterCrtieraCountVar.value>0">{{getSelectedFilterCrtieraCountVar.value}}</span>
               </div>
            
               <button *ngIf="getSelectedFilterCrtieraCountVar.value>0" type="button" class="btn btn-sm bg-white py-0 mr-2 f-9" (click)="reset()" matTooltip="Reset applied filters" matTooltipPosition="right"
                  matTooltipClass="bg-dark">
                  <i class="fas fa-undo fa-sm"></i> Reset All Filters
               </button>
            </ng-container>
            <!--<div class="ml-2 d-flex align-items-center" >
                                       <a class="badge badge-pill bg-dark" matTooltip="For all products &#13;&#8226; with Color - Blue&#13; Search : BLUE &#13;&#8226; with Color - Blue & Garment Type - Tee&#13; Search : BLUE TEE &#13;&#8226; with Color - Blue & Garment Type - Tee & Category - Baby  &#13; Search : BLUE TEE BABY" 
                                       matTooltipPosition="right" matTooltipClass="allow-cr extended-tooltip"><i class="fas fa-info" ></i></a>
                                    </div>-->
         </div>
      </div>
      <div class="col-{{searchAddlSectionColLength}}">
         <div class="row">
            <div class="col-auto d-flex d-inline" *ngIf="showSort && sortColumns.length>0">
               <div class="d-flex d-inline ">
                  <div>
                     <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Sort By</label>
                     <div class="d-flex d-inline justify-content-center">
                        <select class="form-control form-control-sm custom-select mr-1" id="sortBy" name="sortBy"
                           [(ngModel)]="searchConfig.searchCriteria.selectedSortByName" (change)="changeSortCriteria()">
                           <option *ngFor="let sortColumn of sortColumns" [ngValue]="sortColumn.columnName">
                              {{sortColumn.sortByName}}</option>
                        </select>
                        <span (click)="sort('DESC')"
                           *ngIf="searchConfig.searchCriteria.selectedSortByName && searchConfig.searchCriteria.sortMode==='ASC'"><i
                              class="fas fa-sort-amount-up-alt fa-lg mr-1"
                              style="color:black; vertical-align: middle;"></i></span>
                        <!--<span (click)="sort('DESC')"
                           *ngIf="searchConfig.searchCriteria.selectedSortByName && searchConfig.searchCriteria.sortMode==='ASC'"><i
                              class="fas fa-sort-amount-down fa-lg mr-1"
                              style="color:grey; vertical-align: middle;"></i></span>
                        <span (click)="sort('ASC')"
                           *ngIf="searchConfig.searchCriteria.selectedSortByName && searchConfig.searchCriteria.sortMode==='DESC'"><i
                              class="fas fa-sort-amount-up-alt fa-lg mr-1"
                              style="color:grey; vertical-align: middle;"></i></span>-->
                        <span (click)="sort('ASC')"
                           *ngIf="searchConfig.searchCriteria.selectedSortByName && searchConfig.searchCriteria.sortMode==='DESC'"><i
                              class="fas fa-sort-amount-down fa-lg"
                              style="color:black; vertical-align: middle;"></i></span>
                     </div>
                  </div>

               </div>
            </div>
            <!--prospect filters section-->
            <ng-container *ngIf="searchConfig.filterConfigs">
               <ng-container *ngFor="let filterConfig of searchConfig.filterConfigs;index as fcIndex">
                  <!--DATE RANGE FILTER STARTS
                  <ng-container *ngIf="filterConfig.filterType==='DATE_RANGE'">
                     <div class="col-auto">
                        <div class="d-flex d-inline ">
                           <div>
                              <label class="mb-0 ml-1"
                                 style="font-size: .60rem; color: lightslategray;">{{filterConfig.label}}</label>
                              <input type="text" class="form-control form-control-sm" #daterange_{{fcIndex}}
                                 ngxDaterangepickerMd [(ngModel)]="filterConfig.currentSelection"
                                 [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [ranges]="ranges"
                                 [linkedCalendars]="true" name="dateRange_{{fcIndex}}" [showClearButton]="true"
                                 (change)="chooseDateRange(filterConfig,$event,true);" [locale]="locale"
                                 [showRangeLabelOnInput]="true" placeholder="Select Date Range..." readonly
                                 style="background-color: transparent; z-index: 500;" />
                           </div>
                        </div>
                     </div>
                  </ng-container>
                  DATE RANGE FILTER ENDS-->
                  <!--DROPDOWN IN LIST  STARTS-->
                  <ng-container *ngIf="filterConfig.filterType==='IN_LIST' && filterConfig.location==='INLINE'">
                     <div class="col-auto">
                        <div class="d-flex d-inline ">
                           <div>
                              <label class="mb-0 ml-1"
                                 style="font-size: .60rem; color: lightslategray;">{{filterConfig.label}}</label>
                              <select class="form-control form-control-sm custom-select f-10" id="inList_{{fcIndex}}"
                                 name="inList_{{fcIndex}}" [(ngModel)]="filterConfig.currentSelection"
                                 (change)="chooseInList(filterConfig);search()">
                                 <!--[(ngModel)]="excludeProductType" -->
                                 <option value="ALL">Show All</option>
                                 <option *ngFor="let option of filterConfig.options"
                                    [ngValue]="option[filterConfig.optionValueVar]">
                                    {{option[filterConfig.optionDislpayVar]}}</option>
                              </select>
                           </div>
                        </div>
                     </div>
                  </ng-container>
                  <!--DROPDOWN IN LIST  ENDS-->
               </ng-container>
            </ng-container>
         </div>
      </div>
   </div>
   <div class="mt-1" *ngIf="!searchConfig?.searchCriteria?.loadAll">
      <p class="text-muted f-12">Showing {{existingList?.length}} of {{totalSearchCount}} search result. Use | as delimeter for multiple OR Condition
         <!--<small class="text-muted">&nbsp;&nbsp;&nbsp;Enhance search criteria for more result.</small>-->
      </p>
   </div>
</div>


<div class="d-flex justify-content-center" style="position: fixed;bottom: 2px;z-index: 10;left: 45%;">
   <hr class="mt-0">
   <button type="button" id="showMoreId" [disabled]="showMoreDisabled" class="btn btn-sm bg-dark text-white"
      *ngIf="existingList?.length!==totalSearchCount && !searchConfig?.searchCriteria?.loadAll" (click)="loadMore()">Show
      More</button>
</div>