import { VendorInfo } from './printer.model';

export class BlanksSupplier {
   id: string;
   name: string;
   status: boolean;
   notes: string;
   currency: string;
   lastModifiedDate: Date;
   lastModifiedBy: string;
   type: string;
   vendorInfoList: VendorInfo[] = [];
   countryCode: string;
}
