<form #BlanksProductForm="ngForm" (ngSubmit)="createOrUpdateProduct()" autocomplete="off" #formVar="ngForm" novalidate>
   <div class="container" id="containerId">
      <div class="d-flex justify-content-center align-items-center mt-3">
         <div>
            <h3>{{product.sku}}
               <ng-container *ngIf="product?.blanksManufacturerMetadata?.specialityType">
                  <!-- <span class="badge badge-warning superscript-badge f-11">{{product?.blanksManufacturerMetadata?.specialityDescription}}</span> -->
               </ng-container>
            </h3>
         </div>
      </div>

      <div *ngIf="product.blanksManufacturerMetadata?.manufacturer" class="separator-darkslategray"><h6>{{product.blanksManufacturerMetadata.manufacturer}}</h6></div>

      <div class="row mt-1">
         <!--Image & File section starts-->

         <div class="col-md-7 ml-1">
            <!--IMAGES Starts-->
            <div>
               <app-attachment-operations [header]="'Visuals'" [editable]="editable" [createAction]="createAction" [attachments]="product.productImages" [canUpload]="userAccess?.canUploadVisuals"
                  [canDownload]="userAccess?.canDownloadVisuals" [canDelete]="userAccess?.canDeleteVisuals" [category]="'IMAGES'" [viewType]="'IMAGES'"
                  [emptyListError]="'Visual is mandatory to create the product'" [viewCreatedBy]="false"></app-attachment-operations>
            </div>

            <div class="mt-4">
               <div class="overflow-hidden mt-3">
                  <app-attachment-operations [header]="'Mock Templates'" [editable]="editable" [showHeader]="true" [createAction]="createAction" [attachments]="product.mockTemplates"
                     [canUpload]="userAccess?.canMaintainMockTemplates" [canDownload]="userAccess?.canDownloadMockTemplates" [canDelete]="userAccess?.canMaintainMockTemplates" [canRenameFile]="true"
                     [category]="'MOCK_TEMPLATES'" [viewCreatedBy]="false"></app-attachment-operations>
               </div>
            </div>

            <div class="mt-4">
               <div class="overflow-hidden mt-3">
                  <app-attachment-operations [header]="'Spec Sheets'" [editable]="editable" [showHeader]="true" [createAction]="createAction" [attachments]="product.specSheets"
                     [canUpload]="userAccess?.canMaintainSpecSheets" [canDownload]="userAccess?.canDownloadSpecSheets" [canDelete]="userAccess?.canMaintainSpecSheets" [canRenameFile]="true"
                     [category]="'SPEC_SHEETS'" [viewCreatedBy]="false"></app-attachment-operations>
               </div>
            </div>

         </div>
         <!--Image & File section Ends-->



         <!-- Datails Starts-->
         <div class="col-md ml-1">
            <mat-tab-group (selectedTabChange)="selectedTabChange($event)">
               <mat-tab>
                  <ng-template mat-tab-label>
                     <h5>Details</h5>
                  </ng-template>
                  <div class="ml-2 mt-4 overflow-hidden">
                     <input type="hidden" name="ProductId" [(ngModel)]="product.id" #ProductId>

                     <ng-container>
                        <div class="form-group row">
                           <label class="col-sm-4 f-13">SKU</label>
                           <div class="col-sm-8">
                              <input id="skuId" name="sku" [(ngModel)]="product.sku" class="form-control form-control-sm" #skuVar="ngModel" readonly required pattern="^[^*]*$"
                                 [ngClass]="{'is-invalid': skuVar.touched && (skuVar.invalid || skuVar.value?.includes('*'))}" minlength="15" maxlength="15">
                              <div *ngIf="skuVar?.invalid && (skuVar.dirty || skuVar.touched)" class="invalid-feedback">
                                 <div *ngIf="skuVar.errors?.required">
                                    SKU is required.
                                 </div>
                                 <div *ngIf="skuVar.value?.includes('*')">
                                    SKU is not Valid.
                                 </div>
                                 <div *ngIf="skuVar.errors?.minlength || skuVar.errors?.maxlength">
                                    SKU must be exactly 15 characters long.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </ng-container>


                     <div class="form-group row">
                        <label class="col-sm-4 f-13">Description</label>
                        <div class="col-sm-8">
                           <textarea name="Description" [(ngModel)]="product.description"
                              rows="{{(product?.description?.length / 25)>2?(product?.description?.length / 25):2}}"
                              class="form-control form-control-sm" [disabled]="!editable" maxlength="250" #descriptionVar="ngModel" required readonly
                              [ngClass]="{'is-invalid':descriptionVar.touched && descriptionVar.errors}" appLegalSuperScripts>
                           </textarea>
                           <div *ngIf="descriptionVar?.invalid && (descriptionVar.dirty || descriptionVar.touched)" class="invalid-feedback">
                              <div *ngIf="descriptionVar.errors.required">
                                 Description is required.
                              </div>
                           </div>
                        </div>
                     </div>


                     <div class="form-group row">
                        <div class="col-sm-4">
                           <div class="row">
                              <label class="col-sm-4 f-13" style="margin-bottom: 0px;">Manufacturer</label>
                           </div>
                        </div>
                        <div class="col-sm-8" *ngIf="!createAction">
                           <input id="manufacturer" name="manufacturer" [(ngModel)]="product.blanksManufacturerMetadata.manufacturer" class="form-control form-control-sm" #manufacturerSkuVar readonly>
                        </div>
                        <div class="col-sm-8" *ngIf="createAction">
                           <select class="form-control form-control-sm custom-select" [(ngModel)]="product.blanksManufacturerMetadata.supplierId" id="manufacturer" name="manufacturer"
                              #manufacturerVar="ngModel" required [ngClass]="{'is-invalid':manufacturerVar.touched && manufacturerVar.errors}" [disabled]="!editable"
                              (change)="onManufacturerChange(product.blanksManufacturerMetadata?.supplierId)">
                              <option *ngFor="let blanksManufacturerMetadata of blanksManufacturerList" [ngValue]="blanksManufacturerMetadata.supplierId">
                                 {{blanksManufacturerMetadata.manufacturer}}</option>
                           </select>
                           <div *ngIf="manufacturerVar?.invalid && (manufacturerVar.dirty || manufacturerVar.touched)" class="invalid-feedback">
                              <div *ngIf="manufacturerVar.errors.required">
                                 Manufacturer is required.
                              </div>
                           </div>
                        </div>
                     </div>


                     <div class="form-group row">
                        <div class="col-sm-4">
                           <div class="row">
                              <label class="col-sm-4 f-13" style="margin-bottom: 0px;">Manufacturer Sku</label>
                           </div>
                        </div>
                        <div class="col-sm-8" *ngIf="!createAction">
                           <input name="manufacturerSku" [(ngModel)]="product.blanksManufacturerMetadata.manufacturerSeries" class="form-control form-control-sm" #manufacturerSkuVar readonly>
                        </div>
                        <div class="col-sm-8" *ngIf="createAction">
                           <select class="form-control form-control-sm custom-select" [(ngModel)]="product.blanksManufacturerMetadata.manufacturerSeries" name="manufacturerSkuVar"
                              #manufacturerSkuVar="ngModel" required [ngClass]="{'is-invalid':manufacturerSkuVar.touched && manufacturerSkuVar.errors}"
                              (change)="onManufacturerSkuChange(product.blanksManufacturerMetadata?.manufacturerSeries)">
                              <option *ngFor="let blanksMaufacturerMetadata of blanksManufacturerMetadataListForSupplier" [ngValue]="blanksMaufacturerMetadata.manufacturerSeries">
                                 {{blanksMaufacturerMetadata.manufacturerSeries}}</option>
                           </select>
                           <div *ngIf="manufacturerSkuVar?.invalid && (manufacturerSkuVar.dirty || manufacturerSkuVar.touched)" class="invalid-feedback">
                              <div *ngIf="manufacturerSkuVar.errors.required">
                                 Manufacturer Sku is required.
                              </div>
                           </div>
                        </div>
                     </div>


                     <div class="form-group row">
                        <div class="col-sm-4">
                           <div class="row">
                              <label class="col-sm-4 f-13" style="margin-bottom: 0px;">Blanks Type </label>
                           </div>
                        </div>
                        <div class="col-sm-8">
                           <input name="blanksTypeVar" [(ngModel)]="product.blanksManufacturerMetadata.blanksType" class="form-control form-control-sm" #blanksTypeVar readonly required>
                        </div>
                     </div>


                     <div class="form-group row">
                        <div class="col-sm-4">
                           <div class="row">
                              <label class="col-sm-4 f-13" style="margin-bottom: 0px;">Garment </label>
                           </div>
                        </div>
                        <div class="col-sm-8">
                           <input name="garmentVar" [(ngModel)]="product.blanksManufacturerMetadata.garment" class="form-control form-control-sm" #garmentVar readonly required>
                        </div>
                     </div>


                     <div class="form-group row">
                        <div class="col-sm-4">
                           <div class="row">
                              <label class="col-sm-4 f-13" style="margin-bottom: 0px;">Category</label>
                           </div>
                        </div>
                        <div class="col-sm-8">
                           <input name="categoryVar" [(ngModel)]="product.blanksManufacturerMetadata.category" class="form-control form-control-sm" #categoryVar readonly required>
                        </div>
                     </div>


                     <div class="form-group row">
                        <label class="col-sm-4 f-13">Composition</label>
                        <div class="col-sm-8">
                           <input name="compositionVar" [(ngModel)]="product.blanksManufacturerMetadata.composition" class="form-control form-control-sm" #compositionVar readonly required>
                        </div>
                     </div>


                     <div class="form-group row">
                        <label class="col-sm-4 f-13">Blanks Quality</label>
                        <div class="col-sm-8">
                           <input name="blanksQualityVar" [(ngModel)]="product.blanksManufacturerMetadata.quality" class="form-control form-control-sm" #blanksQualityVar readonly required>
                        </div>
                     </div>


                     <div class="form-group row">
                        <label class="col-sm-4 f-13">GSM</label>
                        <div class="col-sm-8">
                           <input name="gsmVar" [(ngModel)]="product.blanksManufacturerMetadata.gsm" class="form-control form-control-sm" #gsmVar readonly required>
                        </div>
                     </div>


                     <div class="form-group row">
                        <label class="col-sm-4 f-13" style="margin-bottom: 0px;">Country Of Origin</label>
                        <div class="col-sm-8">
                           <input name="cooInput" [(ngModel)]="product.blanksManufacturerMetadata.coo" class="form-control form-control-sm" #cooInputVar readonly>
                        </div>
                     </div>


                     <div class="form-group row">
                        <div class="col-sm-4">
                           <div class="row">
                              <label class="col-sm-4 f-13" style="margin-bottom: 0px;">Color </label>
                           </div>
                        </div>
                        <div class="col-sm-8" *ngIf="!editable">
                           <input name="colorVar" [(ngModel)]="product.color" class="form-control form-control-sm" #colorVar readonly>
                        </div>
                        <div class="col-sm-8" *ngIf="editable">
                           <mat-select class="form-control form-control-sm " [(ngModel)]="product.color" name="color.name" #colorVar="ngModel" required (selectionChange)="onColorChange($event)"
                              [ngClass]="{'is-invalid':colorVar.touched && colorVar.errors}" [disabled]="!product.blanksManufacturerMetadata.manufacturerSeries">
                              <ng-container *ngFor="let color of colorList">
                                 <mat-option *ngIf="color.threeDigitCode" [value]="color.name" (mouseenter)="previewColourImage(color,true,$event);" (mouseleave)="previewColourImage(color,false,$event)">
                                    <div class="d-flex justify-content-between align-items-center">
                                       {{ color.name }} {{ color.threeDigitCode ? ' - ' + color.threeDigitCode : '' }}
                                       <span *ngIf="color.swatchList && color.swatchList.length>0">
                                          <img [src]="color.swatchList[0].fileSignedUrl" class="" style="display: block;  width:30px;  height:30px; z-index: 100000;padding: 0%;"
                                             (error)="handleThumbnailError(color.swatchList[0],$event)">
                                       </span>
                                    </div>
                                 </mat-option>
                              </ng-container>
                           </mat-select>
                        
                           <div *ngIf="colorVar?.invalid && (colorVar.dirty || colorVar.touched)" class="invalid-feedback">
                              <div *ngIf="colorVar.errors.required">
                                 Color is required.
                              </div>
                           </div>
                        </div>
                        </div>


                     <ng-container *ngIf="product.blanksManufacturerMetadata.blanksType === 'Treated'">
                        <div class="form-group row">
                           <label class="col-sm-4 f-13">Treatment</label>
                           <div class="col-sm-8" *ngIf="!editable">
                              <input name="treatmentVar" [(ngModel)]="product.treatment" class="form-control form-control-sm" #treatmentVar readonly>
                           </div>
                           <div class="col-sm-8" *ngIf="editable">
                              <select class="form-control form-control-sm custom-select" [(ngModel)]="product.treatment" name="treatmentVar" #treatmentVar="ngModel" required
                                 [ngClass]="{'is-invalid':treatmentVar.touched && treatmentVar.errors}" (change)="onTreatmentChange(product.treatment)">
                                 <option *ngFor="let treatment of treatmentList" [ngValue]="treatment.name">
                                    {{ treatment.name }} {{ treatment.twoDigitCode ? ' - ' + treatment.twoDigitCode : '' }}
                                 </option>
                              </select>
                              <div *ngIf="treatmentVar?.invalid && (treatmentVar.dirty || treatmentVar.touched)" class="invalid-feedback">
                                 <div *ngIf="treatmentVar.errors.required">
                                    Treatment is required.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </ng-container>


                     <ng-container *ngIf="product?.blanksManufacturerMetadata && product?.blanksManufacturerMetadata?.specialityType">

                        <div class="form-group row">
                           <label class="col-sm-4 f-13" style="margin-bottom: 0px;">Speciality Type</label>
                           <div class="col-sm-8">
                              <input name="specialityType" [(ngModel)]="product.blanksManufacturerMetadata.specialityType" class="form-control form-control-sm" #specialityTypeInputVar readonly>
                           </div>
                        </div>                     


                        <div class="form-group row">
                           <label class="col-sm-4 f-13" style="margin-bottom: 0px;">Speciality Description</label>
                           <div class="col-sm-8">
                              <input name="specialityDescription" [(ngModel)]="product.blanksManufacturerMetadata.specialityDescription" class="form-control form-control-sm" #specialityDescriptionInputVar readonly>
                           </div>
                        </div>           
                        
                        
                        <div class="form-group row">
                           <label class="col-sm-4 f-13" style="margin-bottom: 0px;">Replenishment Qty</label>
                           <div class="col-sm-8">
                              <input name="replenishmentQty" type="number" [(ngModel)]="product.blanksManufacturerMetadata.replenishmentQty" class="form-control form-control-sm" #replenishmentQtyInputVar readonly>
                           </div>
                        </div>     

                     </ng-container>


                     <div class="form-group row">
                        <label class="col-sm-4 f-13">Notes</label>
                        <div class="col-sm-8">
                           <textarea name="notes" [(ngModel)]="product.notes" rows="{{(product.notes?.length / 25)>2?(product.notes?.length / 25):2}}" class="form-control form-control-sm"
                              [disabled]="!editable" maxlength="250" #notesVar="ngModel" [ngClass]="{'is-invalid':notesVar.touched && notesVar.errors}">
                              </textarea>
                           <div *ngIf="notesVar?.invalid && (notesVar.dirty || notesVar.touched)" class="invalid-feedback">
                              <div *ngIf="notesVar.errors.required">
                                 Notes is required.
                              </div>
                           </div>
                        </div>
                     </div>


                     <!-- ---------------------------------------------------- Product Vendor Info START  ------------------------------------------------------>

                     <div class="mt-4" *ngIf="editable || (!editable && product.productAdditionalDetails.productVendorInfoList && product.productAdditionalDetails.productVendorInfoList?.length>0)">
                        <div class="form-group d-flex d-inline mb-0">
                           <div class="mt-1">
                              <h5>Vendor(s)</h5>
                           </div>
                           <div class="d-flex justify-content-end mr-1 ml-auto" *ngIf="editable">
                              <div class="ml-1">
                                 <select class="form-control form-control-sm custom-select" [(ngModel)]="selectedProductVendorInfo.vendorId" name="selectedProductVendorInfo"
                                    #selectedProductVendorInfoVar="ngModel" [disabled]="!editable">
                                    <ng-container *ngFor="let bmm of blanksManufacturerList; index as i;">
                                       <option [ngValue]="bmm.supplierId">{{bmm.manufacturer}} - {{bmm.supplierId}}</option>
                                    </ng-container>
                                 </select>
                              </div>
                              <div class="ml-1">
                                 <button type="button" class="btn btn-info btn-sm" [disabled]="!product.blanksManufacturerMetadata.manufacturerSeries" (click)="addProductVendorInfo()"
                                    #addProductVendorInfoVar>
                                    <i class="fas fa-plus"></i></button>
                              </div>
                           </div>
                        </div>
                        <hr class="mt-2">
                     </div>

                     <div class="form-group" *ngIf="(product.productAdditionalDetails.productVendorInfoList && product.productAdditionalDetails.productVendorInfoList?.length>0)">
                        <div class="ml-1">
                           <div class="col-xs-1 mt-0">
                              <table class="table table-sm f-11">
                                 <thead>
                                    <tr>
                                       <th style="width: 40%;">Vendor</th>
                                       <th style="width: 25%;">Sku</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <ng-container *ngFor="let productVendorInfo of product.productAdditionalDetails.productVendorInfoList;index as i">
                                       <tr>
                                          <td>
                                             <span *ngIf="editable && !productVendorInfo.id" (click)="removeProductVendorInfo(productVendorInfo)">
                                                <i class="fas fa-trash " style="color: #dc3545;cursor: pointer;"></i> &nbsp;
                                             </span>
                                             <span>{{productVendorInfo.vendorName}}</span>
                                          </td>
                                          <td>
                                             <input name="vendorSku_{{i}}" [(ngModel)]="productVendorInfo.vendorSku" class="form-control form-control-sm f-11" [readonly]="!createAction" required>
                                          </td>
                                       </tr>
                                    </ng-container>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>

                     <!-- ---------------------------------------------------- Product Vendor Info END  ------------------------------------------------------>

                     <div class="mt-4" *ngIf="editable">

                     </div>

                     <div class="row">
                        <div class="mb-4 col-sm-4"></div>
                        <div class="mb-4 col-sm-8">
                           <button type="button" class="btn btn-info btn-block" (click)="createOrUpdateProduct()" *ngIf="editable && createAction"
                              [disabled]="!BlanksProductForm.valid || !isVisualAvailable()">Submit</button>
                        </div>
                     </div>

                  </div>

               </mat-tab>


            </mat-tab-group>

         </div>

      </div>


      <div class="mb-2"></div>

   </div>



   <button id="composeEmailToggler" type="button" style="display: none;" data-toggle="modal" data-target="#composeEmail"></button>

   <div class="floatBtn d-flex flex-column">

      <button id="sendEmail" type="button" class="btn btn-circle mb-2 bg-navy-blue" (click)="sendProductEmail()" *ngIf="viewAction && userAccess?.canMaintainBlanksProduct"
         matTooltip="Send Product Email" matTooltipPosition="left" matTooltipClass="bg-dark">
         <i class="fa fa-envelope text-light fa-2x"></i>
      </button>

      <button type="button" [routerLink]="['/blanks-product/create']" class="btn btn-circle mb-2" *ngIf="viewAction && userAccess?.canMaintainBlanksProduct" matTooltip="Create New Blanks Product"
         matTooltipPosition="left" matTooltipClass="bg-dark">
         <i class="fas fa-plus fa-lg text-light"></i>
      </button>

      <button type="button" class="btn btn-warning btn-circle  mb-2" (click)="clone()" *ngIf="viewAction && userAccess?.canMaintainBlanksProduct" style="background-color: #ffc107;"
         matTooltip="Create Duplicate Blanks Product" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-clone fa-2x"></i>
      </button>

      <button type="button" (click)="createOrUpdateProduct()" class=" btn btn-circle bg-info mb-2" *ngIf="editable && !createAction" matTooltip="Save Changes" matTooltipPosition="left"
         matTooltipClass="bg-dark" [disabled]="!BlanksProductForm.valid  || product.productImages?.length===0">
         <i class="fas fa-save fa-2x" style="color:white"></i>
      </button>


      <button type="button" class="btn btn-circle bg-dark" *ngIf="editable && !createAction" matTooltip="Cancel Edit" matTooltipPosition="left" matTooltipClass="bg-dark" (click)="cancelEdit()"><i
            class="fas fa-times fa-2x" style="color:white"></i>
      </button>

      <a class="btn btn-circle bg-dark mb-2" (click)="edit()" *ngIf="viewAction && (userAccess?.canMaintainBlanksProduct)" matTooltip="Edit Blanks Product" matTooltipPosition="left"
         matTooltipClass="bg-dark"> <i class="fas fa-pen fa-lg" style="color: white;"></i></a>

      <a (click)="goBack()" class="btn btn-circle" *ngIf="viewAction || createAction" style="background-color: tomato;" matTooltip="Exit" matTooltipPosition="left" matTooltipClass="bg-dark"><i
            class="fas fa-arrow-left  fa-2x"></i></a>

   </div>

   <div *ngIf="showEmailEditor">
      <app-compose-email [modalId]="'composeEmail'" [emailHeader]="'Email'" [composeEmail]="composeEmail" (onSend)="emailSent($event)"></app-compose-email>
   </div>

   <div style="font-size: x-small;" class="text-muted"><em>Product Version: {{product?.version}}</em></div>
</form>