import { Component, OnInit, AfterViewInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Product, ProductAttachment, ProductVendorInfo, ProductAdditionalDetails } from 'src/app/shared/model/product.model';
import { SearchCriteria } from 'src/app/shared/model/search-criteria.model';
import { ProductService } from 'src/app/shared/service/product.service';
import { ServiceStatus } from 'src/app/shared/model/service-status.model';
import { FileDeleteCriteria } from 'src/app/shared/model/file-delete-criteria.model';
import { Subscription, Observable } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { ToastrService } from 'ngx-toastr';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { ProductCatalogService } from 'src/app/shared/service/product-catalog.service';
import { CanComponentDeactivate } from 'src/app/auth/guards/deactivate.gaurd';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { ComposeEmailService } from 'src/app/shared/service/compose-email.service';
import { ComposeEmail } from 'src/app/shared/model/compose-email.model';
import { EmailTemplateConfig } from 'src/app/shared/model/email-template-config.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { SalesOrder } from 'src/app/shared/model/sales/sales-order.model';
import { SalesService } from 'src/app/shared/service/sales/sales.service';
import { User } from 'src/app/shared/model/user.model';
import { ProductMetadataService } from 'src/app/shared/service/product-metadata.service';
import { MetadataColour } from 'src/app/shared/model/product-metadata/metadata-colour.model';
import { BlanksManufacturerMetadataService } from 'src/app/shared/service/blanks-manufacturer-metadata.service';
import { BlanksManufacturerMetadata } from 'src/app/shared/model/blanks-manufacturer-metadata.model';
import { MetadataTreatment } from 'src/app/shared/model/product-metadata/metadata-treatment.model';

@Component({
   selector: 'app-blanks-product',
   templateUrl: './blanks-product.component.html',
   styleUrls: ['./blanks-product.component.css']
})

export class BlanksProductComponent implements OnInit, AfterViewInit, CanComponentDeactivate {
   clonedProduct: boolean;
   createAction: boolean;
   viewAction: boolean;
   editable: boolean;
   selectedImgAccesUrl: string = null;
   selectedImgId: string = '';
   SelectedImageToUpload: File = null;
   imagesToUploadPostProductCreation: File[] = [];
   mockTemplatesToUploadPostProductCreation: File[] = [];
   specSheetsToUploadPostProductCreation: File[] = [];

   product: Product;
   searchCriteria: SearchCriteria;
   progress: number = 0;
   fileUploading = false;
   fileUploaded = false;
   uploadError: boolean;
   currentTag: string = '';
   colorList: MetadataColour[] = [];
   treatmentList: MetadataTreatment[] = [];
   selectedProductVendorInfo: ProductVendorInfo = new ProductVendorInfo;
   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;

   selectedColor: MetadataColour = new MetadataColour(true, 0);
   selectedTreatment: MetadataTreatment = new MetadataTreatment(true, 0);

   composeEmail: ComposeEmail = new ComposeEmail;
   showEmailEditor: boolean = false;
   associatedSalesOrders: SalesOrder[] = [];
   blanksManufacturerList: BlanksManufacturerMetadata[] = [];
   blanksManufacturerMetadataListForSupplier: BlanksManufacturerMetadata[] = []
   @Output() productCreated = new EventEmitter<string>();

   constructor(private router: Router, private authService: AuthService, private productService: ProductService,
      private activatedRoute: ActivatedRoute, private location: Location, private toastrService: ToastrService,
      private productCatalogService: ProductCatalogService, private dialog: MatDialog, private composeEmailService: ComposeEmailService,
      private salesService: SalesService, private productMetadataService: ProductMetadataService,
      private blanksManufacturerMetadataService: BlanksManufacturerMetadataService) {

      this.router.routeReuseStrategy.shouldReuseRoute = function () {
         return false;
      }

      this.router.events.subscribe((evt) => {
         if (evt instanceof NavigationEnd) {
            // trick the Router into believing it's last link wasn't previously loaded
            //this.router.navigated = false;
            // if you need to scroll back to top, here is the right place
            window.scrollTo(0, 0);
         }
      });
   }

   ngOnInit() {
      // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.resetForm();
      this.handleSubscriptions();
      this.evaluateAction();
      this.setMetadata();
      this.getBlanksManufacturerList();
      if (this.createAction) {
         this.product.type = 'BLANKS';
         this.product.licensorId = 'BLANKS';
         this.product.brandId = 'BLANKS';
      } else if (this.viewAction) {
         this.loadProduct();
      }
      window.scrollTo(0, 0);
   }

   @HostListener('window:beforeunload')
   canDeactivate(): Observable<boolean> | boolean {
      // insert logic to check if there are pending changes here;
      // returning true will navigate without confirmation
      // returning false will show a confirm dialog before navigating away
      if (this.editable) {
         return false;
      } else {
         return true;
      }
   }

   ngAfterViewInit() {
      if (this.createAction) {
         setTimeout(() => {
            this.focusOnId('manufacturer');
         }, 100);
         window.scrollTo(0, 0);
      }
   }

   ngOnDestroy() {
      this.handleCloneProductClosure(false, false);
      this.router.navigated = true;
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
      this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
         this.currentLoggedInUser = currentLoggedInUser;
      });

      this.productMetadataService.colorMetadataListObservable.subscribe(res => {
         this.colorList.length = 0;
         this.colorList.push(...res);
      });

      this.productMetadataService.treatmentMetadataListObservable.subscribe(res => {
         this.treatmentList.length = 0;
         this.treatmentList.push(...res);
      });
   }


   evaluateAction() {
      /*change also similarDesignSelected()*/
      const action = this.activatedRoute.snapshot.paramMap.get('action');
      if (action === 'create') {
         this.createAction = true;
         this.editable = true;
         //document.getElementById('backButton').focus();
      } else if (action === 'view') {
         this.viewAction = true;
      }
   }

   setMetadata() {
      /*change also similarDesignSelected()*/
      this.searchCriteria.licensorId = 'BLANKS';// this.activatedRoute.snapshot.paramMap.get('licensorId');
      this.searchCriteria.brandId = 'BLANKS'//this.activatedRoute.snapshot.paramMap.get('brandId');
      this.searchCriteria.productId = this.activatedRoute.snapshot.paramMap.get('id');
      this.sanitizeMetadata();
      if (this.activatedRoute.snapshot.paramMap.get('sku') !== "undefined" && this.createAction) {
         this.searchCriteria.skuCode = this.activatedRoute.snapshot.paramMap.get('sku');
         this.product.sku = this.activatedRoute.snapshot.paramMap.get('sku');
         if (this.product.sku !== undefined && this.product.sku !== null && this.product.sku.length > 3) {
            this.product.sku = this.product.sku.substring(0, 3);
         }
         this.product.licensorId = this.searchCriteria.licensorId;//this.activatedRoute.snapshot.paramMap.get('licensorId');
         this.product.brandId = this.searchCriteria.brandId;//this.activatedRoute.snapshot.paramMap.get('brandId');
      }
   }


   sanitizeMetadata() {
      if (this.searchCriteria.licensorId === 'undefined') {
         this.searchCriteria.licensorId = undefined;
      }
      if (this.searchCriteria.brandId === 'undefined') {
         this.searchCriteria.brandId = undefined;
      }
      if (this.searchCriteria.productId === 'undefined') {
         this.searchCriteria.productId = undefined;
      }
   }

   clone() {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
         disableClose: true,
         data: {
            title: 'Create Duplicate Blanks Product',
            message: undefined,
            //confirmBtnText: 'Without files',
            //cancelBtnText: ' With files  ',
            allowDiscard: true
         }
      });
      confirmDialog.afterClosed().subscribe(result => {
         if (result !== 'NO_ACTION') {
            if (result === true) {
               this.productCloned();
            } else {
               //this.productCloneWithFiles();
            }
         }
      });
   }

   productCloneWithFiles() {
      let searchCriteriaToClone = new SearchCriteria;
      searchCriteriaToClone.productId = this.product.id;
      this.resetPostProductCreationUploads();
      this.toastrService.info('Creating Duplicate Blanks Product for ' + this.product.sku);
      this.productService.cloneProductWithFiles(searchCriteriaToClone).subscribe((res) => {
         let status: ServiceStatus = res as ServiceStatus;
         if (status.status == 'SUCCESS') {
            this.clonedProduct = true;
            this.createAction = false;
            this.editable = false;
            this.viewAction = true;
            this.searchCriteria = new SearchCriteria;
            this.searchCriteria.licensorId = this.product.licensorId;
            this.searchCriteria.brandId = this.product.brandId;
            this.searchCriteria.productId = status.id;
            this.product = new Product;
            this.loadProduct();
            window.scrollTo(0, 0);
            this.toastrService.success(status.message);
         } else {
            this.toastrService.error(status.message);
         }
      },
         (err) => {
            this.toastrService.error("Error while creating duplicate Blanks Product with files. " + err);
         });
   }

   productCloned() {
      this.product.id = undefined;
      this.resetAttachments();
      this.resetPostProductCreationUploads();
      this.createAction = true;
      this.viewAction = false;
      this.editable = true;
      this.clonedProduct = false;
      this.searchCriteria = new SearchCriteria;
      this.selectedImgAccesUrl = undefined
      this.product.sku = undefined;//this.product.sku.slice(0, 7);
      this.product.isCollectionMember = false;
      this.product.productAdditionalDetails = new ProductAdditionalDetails();
      this.product.exclusiveToCustomers = false;
      this.product.priority = false;
      this.product.version = 0;
      this.product.color = undefined;
      this.product.treatment = undefined;
      window.scrollTo(0, 0);
      setTimeout(() => {
         this.focusOnId('skuId');
      }, 100);
   }


   edit() {
      this.productService.isCurrentVersion(this.product.id, this.product.version).subscribe(res => {
         let serviceStatus: ServiceStatus = res as ServiceStatus;
         if (serviceStatus && serviceStatus != null && serviceStatus.status === "SUCCESS") {
            this.proceedWithEdit();
         } else {
            //this.toastrService.info('Product has been modified by other user.Reloading before Edit'); 
            this.loadProduct(true);
         }
      });
   }

   private proceedWithEdit() {
      this.editable = true;
      this.createAction = false;
      this.viewAction = false;
   }

   cancelEdit() {
      if (this.clonedProduct) {
         const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
               title: 'Delete Duplicate Blanks Product',
               message: 'The duplicate Blanks Product will be deleted if you cancel the update. Do you want to continue?',
               confirmBtnText: '  Yes  ',
               cancelBtnText: '   No   '
            }
         });
         confirmDialog.afterClosed().toPromise().then(result => {
            if (result === true) {
               this.handleCloneProductClosure(false, true);
            }
         });
      }
      else {
         this.editable = false;
         this.createAction = false;
         this.viewAction = true;
         this.loadProduct();
      }
   }

   handleCloneProductClosure(goBack: boolean, redirectToProductList: boolean) {
      if (this.clonedProduct) {
         this.toastrService.info('Duplicate Blanks Product ' + this.product.sku + ' will be deleted as it was not saved');
         this.deleteProduct(goBack, redirectToProductList);
      }
   }


   loadProduct(proceedToEditAfterLoad?: boolean) {
      console.log('LOAD PRODUCT Inside');
      this.refreshSearchCriteria();
      this.productService.loadProduct(this.searchCriteria).subscribe((res) => {
         this.product = res as Product;
         if (this.product !== null && this.product != undefined) {
            if (this.viewAction) {
               this.productService.loadProductImagesUrls(this.product, this.searchCriteria.licensorId, this.searchCriteria.brandId);
               this.productService.loadProductAttachmentsUrls(this.product, 'MOCK_TEMPLATES', this.searchCriteria.licensorId, this.searchCriteria.brandId);
               this.productService.loadProductAttachmentsUrls(this.product, 'SPEC_SHEETS', this.searchCriteria.licensorId, this.searchCriteria.brandId);
               this.loadBlanksManufacturerMetadata(this.product.blanksManufacturerMetadataId);
               this.loadProductVendorInfoList(this.product.id);
               if (this.clonedProduct) {
                  this.product.sku = undefined;//this.product.sku.slice(0, 7);
                  this.proceedWithEdit();
               } else if (proceedToEditAfterLoad) {
                  this.proceedWithEdit();
               } else {

               }
            }
         } else {
            this.product = new Product;
         }
      });
      console.log('LOAD PRODUCT going out');
   }

   private refreshSearchCriteria() {
      var productId = this.searchCriteria.productId;
      if ((productId === null || productId === undefined) && (this.product.id !== null && this.product.id !== undefined)) {
         this.searchCriteria.productId = this.product.id;
      }
   }

   goBack() {
      if (this.editable || this.createAction) {
         const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
               title: 'Cancel Edit',
               message: 'You have unsaved changes! If you confirm, your changes will be lost.',
            }
         });
         confirmDialog.afterClosed().subscribe(result => {
            if (result === true) {
               this.editable = false;
               this.createAction = false;
               this.location.back();
            }
         });
      } else {
         this.location.back();
      }
   }

   deleteProduct(goBack: boolean, redirectToProductList: boolean) {
      this.productService.deleteProduct(this.product.id).subscribe((res) => {
         this.clonedProduct = false;
         if (goBack) {
            this.editable = false;
            this.createAction = false;
            this.location.back();
         } if (redirectToProductList) {
            this.editable = false;
            this.createAction = false;
            this.router.navigate(['/blanks-product-search']);
         }
      },
         (err) => {
            this.toastrService.error('Duplicate Blanks Product deletion error' + err);
         });
   }

   resetForm(form?: NgForm) {
      if (form != null) {
         form.resetForm();
      }
      if (this.searchCriteria == null)
         this.searchCriteria = new SearchCriteria;
      this.product = new Product;
      this.product.productImages = [];
   }

   addTag() {
      if (this.product.tagList === undefined) {
         this.product.tagList = [];
      }
      this.product.tagList.push(this.currentTag);
      this.currentTag = "";
      this.focusOnId('tagId');
   }

   focusOnId(arg0: string) {
      document.getElementById(arg0).focus();
   }

   removeTag(toRemove: string) {
      this.product.tagList.splice(this.product.tagList.indexOf(toRemove), 1);
      this.focusOnId('tagId');
   }

   editForm() {
      this.createAction = true;
      this.viewAction = false;
   }

   createOrUpdateProduct() {
      this.populateLocalFileList();
      if (this.editable && !this.createAction) {
         let redirect: boolean = false;
         if (this.clonedProduct) {
            this.clonedProduct = false;
         }
         this.updateProduct(redirect);
      } else {
         this.createProduct();
         this.product.productName = this.product.sku;
      }
   }

   createProduct() {
      // This is required if we need to generate the SKU from Backend.
      /* 
      this.productService.generateBlanksSku(this.product).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let generatedSku = response.responsePayload;
            console.log(generatedSku);
            this.blanksSkuConfirmDialog(generatedSku);
         } else if (response.responseStatus.status === 'FAILED') {
            console.error("Blanks Product SKU generation failed......", response.responseStatus.message);
            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
      */
      this.createProductAfterSkuGenerationConfirm();

   }


   blanksSkuConfirmDialog(blanksSku: string) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
         disableClose: true,
         data: {
            title: 'Blanks Product Sku Confirmation',
            message: 'Sku generate for the Blanks Product is ' + blanksSku + '. Please confirm ?',
            confirmBtnText: 'Yes',
            cancelBtnText: 'Cancel'
         }
      });
      confirmDialog.afterClosed().subscribe(res => {
         if (res) {
            this.product.sku = blanksSku;
            this.createProductAfterSkuGenerationConfirm();
         } else {
         }
      });
   }


   createProductAfterSkuGenerationConfirm() {
      this.productService.createProduct(this.product).subscribe(async (res) => {
         let status: ServiceStatus = res as ServiceStatus;
         if (status.status == 'SUCCESS') {
            this.product.id = status.id;
            let productReturned = status.object as Product;// never set product returned to current product
            this.product.version = productReturned.version;
            this.createAction = false;
            this.editable = false;
            this.viewAction = true;

            await this.updateAttachments();
            await this.loadAdditionalDetails();
            await this.updateProductVendorInfoList();
            await this.router.navigate(['blanks-product/view/' + this.product.id], { skipLocationChange: false });
            this.toastrService.success(status.message);
         } else {
            this.toastrService.error(status.message);
         }

      },
         (err) => {
            this.toastrService.error(JSON.stringify(err));
         });
   }


   updateProduct(redirectPostUpdate: boolean) {
      this.productCatalogService.checkAndRemoveForCatalog(this.product);
      let tempProduct = JSON.parse(JSON.stringify(this.product));
      tempProduct.mockTemplates = null;
      tempProduct.specSheets = null;
      this.productService.updateProduct(tempProduct).subscribe((res) => {
         let status: ServiceStatus = res as ServiceStatus;
         if (status.status == 'SUCCESS') {
            this.updateProductVendorInfoList();
            this.product.id = status.id;
            let productReturned = status.object as Product;// never set product returned to current product
            this.product.version = productReturned.version;
            this.editable = false;
            this.createAction = false;
            this.viewAction = true;
            this.updateAttachments();
            if (redirectPostUpdate) {
               this.router.navigate(['/blanks-product/view/' + this.product.id], { skipLocationChange: false });
            } else {
               this.loadAdditionalDetails();
            }
            this.toastrService.success(status.message);
         } else {
            if (status.errorCode === 'STALE_OBJECT_ERROR') {
               this.toastrService.error('Product has been modified by other user. Please refresh and make your changes again before save.');
            } else {
               this.toastrService.error(status.message);
            }
         }
      },
         (err) => {
            this.toastrService.error(JSON.stringify(err));
         });
   }

   loadAdditionalDetails() {
      this.refreshSearchCriteria();
      this.productService.loadProduct(this.searchCriteria).subscribe((res) => {
         let productTemp = res as Product;
         if (productTemp !== null && productTemp != undefined) {
            this.product.convertedToSKU = productTemp.convertedToSKU;
         }
      });
   }


   downloadFile(url: string) {
      var link = document.createElement('a');
      link.href = url;
      link.download = 'Download.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
   }


   handleThumbnailError(productAttachment: ProductAttachment, event) {
      if (isNaN(productAttachment.thumbnailErrorCount)) {
         productAttachment.thumbnailErrorCount = 0;
      }
      event.target.src = productAttachment.fileSignedUrl;
      var x: number = productAttachment.thumbnailErrorCount;
      productAttachment.thumbnailErrorCount = x + 1;
      if (productAttachment.thumbnailErrorCount < 2) {
         event.target.src = productAttachment.fileSignedUrl;
         productAttachment.thumbnailSignedUrl = productAttachment.fileSignedUrl;
      } else {
         event.target.src = "/assets/default-image.jpg"
      }
   }

   isVisualAvailable() {
      if (this.product.productImages?.length === 0) {
         return false;
      } else if (this.product.productImages?.length > 0) {
         let retVal: boolean = false;
         this.product.productImages.forEach(element => {
            if (!element.toDelete) {
               retVal = true;
            }
            //element.toDelete && !element.islocal
         });
         return retVal;
      }
   }

   confirm(): boolean {
      return (!this.editable && !this.createAction);
   }

   async emailSent(composeEmail: ComposeEmail) {

   }

   sendProductEmail() {
      this.showEmailEditor = true;
      let emailConfig: EmailTemplateConfig = new EmailTemplateConfig;
      emailConfig.productIdList.push(this.product.id);
      emailConfig.templateName = 'productDetailsWithVisual';
      this.composeEmail = new ComposeEmail;
      this.getEmailTemplate(emailConfig);
   }

   getEmailTemplate(emailConfig: EmailTemplateConfig) {
      this.composeEmailService.getEmailTemplate(emailConfig).subscribe(response => {
         let composeEmailTemp: ComposeEmail = response.responsePayload;
         this.composeEmail.emailContent = composeEmailTemp.emailContent;
         this.composeEmail.emailTo = composeEmailTemp.emailTo;
         this.composeEmail.emailCc = composeEmailTemp.emailCc;
         this.composeEmail.emailSubject = composeEmailTemp.emailSubject;
         this.composeEmail.emailTemplateConfig = emailConfig;
         document.getElementById('composeEmailToggler').click();
      });
   }

   getEnterCount(str: string, max: number) {
      let rowCount = str.split(/\r\n|\r|\n/).length;
      if (rowCount > max) {
         return max;
      } else {
         return rowCount;
      }
   }

   newGuid(): string {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
         var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
         return v.toString(16);
      });
   }

   changePriority(product: Product) {
      if (this.userAccess?.canChangeProductPriority) {
         this.productService.changePriority(product.id, !product.priority).subscribe(res => {
            let serviceStatus: ServiceStatus = res as ServiceStatus;
            if (serviceStatus && serviceStatus.status === 'SUCCESS') {
               product.priority = !product.priority;
            } else {
               this.toastrService.error('Failed to change priority - ' + serviceStatus.message);
            }
         }, err => {
            this.toastrService.error('Error while changing priority - ' + err);
         });
      }
   }

   selectedTabChange(event) {
      switch (event.tab.textLabel) {
         case 'associatedSalesOrdersTab':
            this.loadAssociatedSalesOrder();
            break;
         default:
            break;
      }
   }

   loadAssociatedSalesOrder() {
      this.salesService.getAssociatedSalesOrdersForProduct(this.product.id).subscribe(response => {
         let res: ApiResponse = response as ApiResponse;
         this.associatedSalesOrders = res.responsePayload;
      });
   }

   async getBlanksManufacturerList() {
      await this.blanksManufacturerMetadataService.getDistinctBlanksManufacturerList().toPromise().then(res => {
         if (res && res.responseStatus.status === 'SUCCESS' && res.responsePayload != null) {
            this.blanksManufacturerList = res.responsePayload;
         }
      });
   }

   async loadBlanksManufacturerMetadata(id: string) {
      await this.blanksManufacturerMetadataService.getBlanksManufacturerMetadata(id).toPromise().then(res => {
         if (res && res.responseStatus.status === 'SUCCESS' && res.responsePayload != null) {
            this.product.blanksManufacturerMetadata = res.responsePayload;
            this.getBlanksManufacturerMetadataListForSupplier(this.product.blanksManufacturerMetadata.supplierId);
         }
      });
   }

   onManufacturerChange(supplierId: string) {
      console.log('onManufacturerChange = ', supplierId);
      this.resetBlanksManufacturerMetadata(supplierId);
      this.getBlanksManufacturerMetadataListForSupplier(supplierId);
   }

   resetBlanksManufacturerMetadata(supplierId: string) {
      this.blanksManufacturerMetadataListForSupplier = [];
      this.product.blanksManufacturerMetadata = new BlanksManufacturerMetadata;
      this.product.blanksManufacturerMetadata.supplierId = supplierId;
      this.product.blanksManufacturerMetadata.manufacturerSeries = undefined;
      if (this.createAction) {
         this.product.productAdditionalDetails.productVendorInfoList = [];
         this.selectedProductVendorInfo.vendorId = undefined;
         this.product.sku = undefined;
         this.product.color = undefined;
         this.product.treatment = undefined;
         this.product.description = undefined;
         this.selectedTreatment = undefined;
         this.selectedColor = undefined;
      }
   }

   async getBlanksManufacturerMetadataListForSupplier(supplierId: string) {
      await this.blanksManufacturerMetadataService.getBlanksManufacturerMetadataListForSupplier(supplierId).toPromise().then(res => {
         if (res && res.responseStatus.status === 'SUCCESS' && res.responsePayload != null) {
            this.blanksManufacturerMetadataListForSupplier = res.responsePayload;
         }
      });
   }

   onManufacturerSkuChange(selectedManufacturerSku: string) {
      console.log('selectedManufacturerSku = ', selectedManufacturerSku, this.blanksManufacturerMetadataListForSupplier);
      if (this.blanksManufacturerMetadataListForSupplier != null && this.blanksManufacturerMetadataListForSupplier && this.blanksManufacturerMetadataListForSupplier.length > 0) {
         let tempBlanksManufacturerData = this.blanksManufacturerMetadataListForSupplier.find(x => x.manufacturerSeries === selectedManufacturerSku);
         console.log('found BlanksManufacturerData = ', tempBlanksManufacturerData);
         this.product.blanksManufacturerMetadata = JSON.parse(JSON.stringify(tempBlanksManufacturerData));
         this.product.blanksManufacturerMetadataId = this.product.blanksManufacturerMetadata.id;
      }
      this.generateAndSetBlanksSku();
   }

   generateAndSetBlanksSku() {
      this.productService.generateBlanksSku(this.product, this.selectedColor?.threeDigitCode).subscribe(res => {
         if (res && res.responseStatus.status === 'SUCCESS' && res.responsePayload != null) {
            let tempProduct: Product = res.responsePayload;
            this.product.sku = tempProduct.sku;
            this.product.description = tempProduct.description;
         }
      });;
   }

   onColorChange(event: any) {
      let colorName = this.product.color;
      let colorObjArr = this.colorList.filter(x => x.name === colorName);
      //console.log('Color Change = ', event, colorObjArr);
      this.selectedColor = colorObjArr[0];
      this.generateAndSetBlanksSku();
   }

   onTreatmentChange(selectedTreatmentName: string) {
      console.log(selectedTreatmentName);
      const selectedTreatment = this.treatmentList.find(treatment => treatment.name === selectedTreatmentName);

      if (selectedTreatment) {
         console.log("Selected Treatment Object:", selectedTreatment);
         this.selectedTreatment = selectedTreatment;
         this.generateAndSetBlanksSku();
      }
   }

   previewColourImage(color: MetadataColour, entering: boolean, event?: any) {
      let eventMouse = event as MouseEvent;
      const element = event.target as HTMLElement;
      if (color && color.swatchList && color.swatchList.length > 0) {
         if (entering) {

            element.style.backgroundImage = 'url("' + color.swatchList[0].fileSignedUrl + '")';
            element.style.backgroundSize = 'cover'; // Make sure the image covers the entire element
            //element.style.color = 'white';
         } else {
            element.style.backgroundImage = '';
            element.style.backgroundSize = ''; // Make sure the image covers the entire element
            //element.style.color = '';
         }
      }

      
   }

   /*********************************** File Handling  - Start ******************************/

   private resetAttachments() {
      this.product.productImages = [];
      this.product.mockTemplates = [];
      this.product.specSheets = [];
   }

   populateLocalFileList() {
      if (this.product.productImages.length > 0) {
         //this.setSortOrderForAttachment(false);
      }
      this.imagesToUploadPostProductCreation = this.populateLocalFileinList(this.product.productImages);
      this.mockTemplatesToUploadPostProductCreation = this.populateLocalFileinList(this.product.mockTemplates);
      this.specSheetsToUploadPostProductCreation = this.populateLocalFileinList(this.product.specSheets);
   }

   private populateLocalFileinList(attachmentList: ProductAttachment[]): File[] {
      let list: File[] = [];
      if (attachmentList !== undefined && attachmentList.length > 0) {
         attachmentList.forEach(element => {
            if (element.islocal && !element.toDelete) {
               list.push(element.localfile);
               //element.localfile=undefined; This created issue when first create attempt has issues like sku exists. Then subsequent attempt could not find the file
               element.fileSignedUrl = undefined;
               element.thumbnailSignedUrl = undefined;
            }
         });
      }
      return list;
   }

   private resetPostProductCreationUploads() {
      this.imagesToUploadPostProductCreation = [];
      this.mockTemplatesToUploadPostProductCreation = [];
      this.specSheetsToUploadPostProductCreation = [];
   }

   async updateAttachments() {
      await this.deletMarkedFile(this.product.productImages);
      await this.deletMarkedFile(this.product.mockTemplates);
      await this.deletMarkedFile(this.product.specSheets);
      await this.uploadLocalFileToRepo(this.imagesToUploadPostProductCreation, 'IMAGES');
      this.productService.loadProductImagesUrls(this.product, this.searchCriteria.licensorId, this.searchCriteria.brandId);
      this.productService.loadProductAttachmentsUrls(this.product, 'MOCK_TEMPLATES', this.searchCriteria.licensorId, this.searchCriteria.brandId);
      this.productService.loadProductAttachmentsUrls(this.product, 'SPEC_SHEETS', this.searchCriteria.licensorId, this.searchCriteria.brandId);
      this.uploadLocalFileToRepoNonBlocking(this.mockTemplatesToUploadPostProductCreation, 'MOCK_TEMPLATES');
      this.uploadLocalFileToRepoNonBlocking(this.specSheetsToUploadPostProductCreation, 'SPEC_SHEETS');
   }

   async deletMarkedFile(list: ProductAttachment[]) {
      if (list !== undefined && list.length > 0) {
         for (const element of list) {
            if (element.toDelete && !element.islocal) {
               await this.deleteFilesFromRepo(element.id, element.fileCategory);
            }
         }
      }
   }

   async uploadLocalFileToRepo(files: File[], category: string) {
      if (files.length > 0) {
         for (let index = 0; index < files.length; index++) {
            const element = files[index];
            await this.uploadSelectedFile(category, element);
         }
      }
   }

   uploadLocalFileToRepoNonBlocking(files: File[], category: string) {
      if (files.length > 0) {
         for (let index = 0; index < files.length; index++) {
            const element = files[index];
            this.uploadSelectedFileNonBlocking(category, element);
         }
      }
   }

   uploadSelectedFileNonBlocking(filecategory: string, selectedFileToUpload: File) {
      let fileName = selectedFileToUpload.name;
      this.toastrService.info('Uploading ' + fileName);
      this.productService.uploadFileForProductWithProgress(this.product, selectedFileToUpload, filecategory).subscribe(
         (res) => {
            if (res === 'UPLOAD_SUCCESS') {
               this.toastrService.success(fileName + ' Uploaded Successfully');
               this.productService.loadProductAttachmentsUrls(this.product, filecategory, this.searchCriteria.licensorId, this.searchCriteria.brandId);
            } else if (res === 'UPLOAD_FAILED') {
               this.toastrService.error(fileName + ' - File upload failed');
            }
         }, (err) => {
            console.error('Error while upload' + err);
            this.toastrService.error(selectedFileToUpload.name + ' Upload Failed:' + JSON.stringify(err));
            this.resetUploadFlags();
         }
      );
   }

   async uploadSelectedFile(filecategory: string, selectedFileToUpload: File) {
      this.fileUploading = true;
      let fileName = selectedFileToUpload.name;
      this.toastrService.info('Uploading ' + fileName);
      await this.productService.uploadFileForProductWithProgress(this.product, selectedFileToUpload, filecategory).toPromise().then(
         () => {
            this.toastrService.success(fileName + ' Uploaded Successfully');
         }, (err) => {
            console.error('Error while upload' + err);
            this.toastrService.error(selectedFileToUpload.name + ' Upload Failed:' + JSON.stringify(err));
            this.uploadError = true;
            this.resetUploadFlags();
         }
      );
   }

   async deleteFilesFromRepo(id: string, category: string) {
      if (!this.createAction /* && confirm("Do you really want to delete the file?")*/) {
         const obj: FileDeleteCriteria = new FileDeleteCriteria;
         obj.id = id;
         await this.productService.deleteFile(obj).toPromise().then((res) => {
            let status: ServiceStatus = res as ServiceStatus;
            if (status.status == 'SUCCESS') {
               this.toastrService.success(status.message);
               //this.refreshLocalListOfUrlsForCategory(category);
            }
            else {
               this.toastrService.error(status.message);
            }

         }, (err) => {
            this.toastrService.error('File Deletion Failed : ' + JSON.stringify(err));
         });
      }
   }

   deleteLocalFile(id: string, list: ProductAttachment[]): ProductAttachment[] {
      return list.filter(element => element.id != id);
   }

   resetUploadFlags() {
      this.fileUploading = false;
      this.fileUploaded = false;
   }

   /*********************************** File Handling  - End *************************************/


   /*********************************** Product Vendor Info - Start ******************************/


   loadProductVendorInfoList(productId: string) {
      this.productService.getProductVendorInfoList(productId).subscribe(res => {
         if (res && res != null && res.length > 0) {
            this.product.productAdditionalDetails.productVendorInfoList = res;
         }
      });
   }

   addProductVendorInfo() {
      if (this.selectedProductVendorInfo.vendorId === this.product.blanksManufacturerMetadata.supplierId) {
         this.toastrService.info('Selected Vendor cannot be same as Manufacturer.');
         return;
      }

      if (this.product.productAdditionalDetails.productVendorInfoList === undefined || this.product.productAdditionalDetails.productVendorInfoList === null) {
         this.product.productAdditionalDetails.productVendorInfoList = [];
      }
      if (!this.checkIfItemExists(this.product.productAdditionalDetails.productVendorInfoList, this.selectedProductVendorInfo.vendorId)) {
         let tempBlanksManufacturerMetadata = this.blanksManufacturerList.find(x => x.supplierId === this.selectedProductVendorInfo.vendorId);
         if (tempBlanksManufacturerMetadata) {
            let pvi: ProductVendorInfo = new ProductVendorInfo;
            pvi.parentId = this.product.id;
            pvi.vendorId = tempBlanksManufacturerMetadata.supplierId;
            pvi.vendorName = tempBlanksManufacturerMetadata.manufacturer;
            pvi.vendorSku = this.product.blanksManufacturerMetadata.manufacturerSeries;
            this.product.productAdditionalDetails.productVendorInfoList.push(pvi);
         }
      }
   }

   removeProductVendorInfo(productVendorInfo: ProductVendorInfo) {
      this.product.productAdditionalDetails.productVendorInfoList.splice(this.product.productAdditionalDetails.productVendorInfoList.indexOf(productVendorInfo), 1);
   }

   checkIfItemExists(list: ProductVendorInfo[], vendorIdToCheck: string) {
      console.log(list, vendorIdToCheck);
      if (list) {
         let temp = list.filter(itemInList => itemInList.vendorId === vendorIdToCheck);
         return (temp.length == 1);
      } else {
         return false;
      }
   }

   updateProductVendorInfoList() {
      if (this.product && this.product?.productAdditionalDetails?.productVendorInfoList && this.product?.productAdditionalDetails?.productVendorInfoList?.length > 0) {
         this.productService.updateProductVendorInfoList(this.product.id, this.product.productAdditionalDetails.productVendorInfoList).subscribe((res) => {
            let status: ServiceStatus = res as ServiceStatus;
            if (status.status == 'SUCCESS') {
               this.loadProductVendorInfoList(this.product.id);
            } else {
               this.toastrService.error(status.message);
            }
         });
      }
   }

   /*********************************** Product Vendor Info - End   ******************************/

}