import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { MetadataSize } from 'src/app/shared/model/product-metadata/metadata-size.model';
import { Product, ProductAttachment, SageItemCreationHelper } from 'src/app/shared/model/product.model';
import { OrderDetailSizeQuantity } from 'src/app/shared/model/sales/order-detail.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { ProductMetadataService } from 'src/app/shared/service/product-metadata.service';
import { SharedService } from 'src/app/shared/service/shared.service';

@Component({
  selector: 'app-create-item',
  templateUrl: './create-item.component.html',
  styleUrls: ['./create-item.component.css']
})
export class CreateItemComponent implements OnInit {


  constructor(private authService:AuthService, private productMetadataService:ProductMetadataService, private sharedService:SharedService) { }



  userAccessSubscription: Subscription;
  userAccess: UserAccess;

  currentLoggedInUserSubscription: Subscription;
  currentLoggedInUser: User;
  editable: boolean = false;
  sizesMetadataList: MetadataSize[] = [];
  ordeDetailSizeQuantityList: OrderDetailSizeQuantity[]=[];
  ordeDetailSizeQuantityListArr: OrderDetailSizeQuantity[][]=[];
  @Output() copySizeSelectionToAllStylesEvent = new EventEmitter<any>();
  //orderDetailId:string;
  @Input() sageItemCreationHelper:SageItemCreationHelper;
  product:Product;
  
  

  async ngOnInit() {
    this.handleSubscriptions();
    this.product=this.sageItemCreationHelper.product;    

  }
 

  handleSubscriptions() {
    this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
       this.userAccess = access;
    });
    this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
       this.currentLoggedInUser = currentLoggedInUser;
    });
    this.productMetadataService.sizesMetadataListObservable.subscribe(res => {
      this.sizesMetadataList = res;
      if(this.ordeDetailSizeQuantityListArr.length==0 && this.sizesMetadataList && this.sizesMetadataList.length>0){
        this.createOrderDetailSizeQtyList();
      }
   });
  }
  createOrderDetailSizeQtyList() {
    if(this.sizesMetadataList && this.sizesMetadataList.length>0){
      this.ordeDetailSizeQuantityListArr.length=0;
      this.ordeDetailSizeQuantityList.length=0;
      this.sizesMetadataList.forEach(sizeMetadata => {
        
         let orderDetailSizeQuantity = new OrderDetailSizeQuantity;
          orderDetailSizeQuantity.parentId = this.sageItemCreationHelper.product.id;
          orderDetailSizeQuantity.size = sizeMetadata.size;
          orderDetailSizeQuantity.selected = false;
          orderDetailSizeQuantity.activeSize = sizeMetadata.status;
          this.ordeDetailSizeQuantityList.push(orderDetailSizeQuantity);
      
      });
      this.ordeDetailSizeQuantityListArr=this.sharedService.splitIntoChunks(this.ordeDetailSizeQuantityList,10);
      this.sageItemCreationHelper.sizeSelectionList=this.ordeDetailSizeQuantityList;
    }
  }



  
  handleThumbnailError(productAttachment: ProductAttachment, event) {
    if(productAttachment){
    if (isNaN(productAttachment?.thumbnailErrorCount)) {
      productAttachment.thumbnailErrorCount = 0;
    }
    event.target.src = productAttachment.fileSignedUrl;
    var x: number = productAttachment.thumbnailErrorCount;
    productAttachment.thumbnailErrorCount = x + 1;
    if (productAttachment.thumbnailErrorCount < 2) {
      event.target.src = productAttachment.fileSignedUrl;
      productAttachment.thumbnailSignedUrl = productAttachment.fileSignedUrl;
    } else {
      event.target.src = "/assets/default-image.jpg"
    }
    }
  }

  resetSizeSelection(){
  this.ordeDetailSizeQuantityList.forEach(odsql => {
    odsql.selected=false;
  });
  }

  copySizeSelectionToAllStyles(){
    let arr=this.ordeDetailSizeQuantityList.filter(x=>x.selected);
    if(arr.length>0){
      this.copySizeSelectionToAllStylesEvent.emit(this.ordeDetailSizeQuantityList);
    }
  }

  // Convert outer Map to array
  get companyBasedAudits(): [string, Map<string, any[]>][] {
    console.log("companyBasedAudits",Array.from(this.sageItemCreationHelper.auditMap));
    return Array.from(this.sageItemCreationHelper.auditMap);
  }

  // Convert inner Map to array
  getStatusBasesAuditsForCompany(companyBasedAudit: Map<string, any[]>): [string, any[]][] {
    return Array.from(companyBasedAudit.entries());
  }

  getStatusBasesAuditsForCompanyErrorCount(companyBasedAudit,statusType): number{
    if(companyBasedAudit.get(statusType)){
      return companyBasedAudit.get(statusType).length;
    }else{
      return 0;
    }
  }

  
}
