import { Component, HostListener, Input, OnInit, Output } from '@angular/core';
import { filter } from 'lodash';
import moment from 'moment';
import { BrowserStack } from 'protractor/built/driverProviders';
import { SearchCriteriaBuilder } from 'src/app/shared/model/common/search-criteria-builder.model';
import { SearchDetails } from 'src/app/shared/model/common/search-details.model';
import { FilterConfig } from 'src/app/shared/model/common/search-filter.model';
import { SearchService } from 'src/app/shared/service/search.service';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent<V> implements OnInit {

  constructor(private searchService:SearchService) {
   }

  ngOnInit(): void {
    this.tempSetFilterConfig();
    if(this.searchOnInit){
      
    this.search();
    }
    
  }
  tempSetFilterConfig() {
    if(this.searchConfig && this.searchConfig.filterConfigs && this.searchConfig.filterConfigs.length>0){
      this.searchConfig.filterConfigs.forEach(filterConfig => {
        this.setConfig(filterConfig);
      });
    }
    if(this.searchConfig && this.searchConfig.defaultNonDisplayFilterConfigs && this.searchConfig.defaultNonDisplayFilterConfigs.length>0){
      this.searchConfig.defaultNonDisplayFilterConfigs.forEach(filterConfig => {
        this.setConfig(filterConfig);
      });
    }
  }
  
  private setConfig(filterConfig: FilterConfig) {
    switch (filterConfig.filterType) {
      case 'DATE_RANGE':
        this.chooseDateRange(filterConfig);
        break;
      case 'IN_LIST':
        this.chooseInList(filterConfig);
        break;
      case 'IN_LIST_INDICATOR':
      this.chooseInListIndicator(filterConfig);
      break;
      case 'IN_CHECKLIST':
        this.chooseInCheckList(filterConfig);
        break;
      default:
        break;
    }
  }

   /**
   * Search Starts
   */
    showMoreVisible:boolean;
    showMoreDisabled:boolean;
    totalSearchCount:number=0;
    selectedFilterConfig:FilterConfig;
    showBackDrop:boolean;
    //searchCriteria:SearchCriteria;
    @Input() parentElementId:string;
    @Input() searchConfig:SearchDetails;
    @Input() existingList:V[];
    @Input() placeholder:string='Search....';
    @Input() searchOnInit:boolean=true;
    @Input() sortColumns:{columnName:string,sortByName:string}[]=[];
    @Input() showSort:boolean=true;
    @Input() searchApp:string='';
    @Input() searchBarColLength:number=6;
    @Input() searchAddlSectionColLength:number=6;
  

    //@Input() filterConfigs:FilterConfig[];
    /* = [{ columnName: 'SKU', sortByName: 'sku' },
  { columnName: 'Last Updated', sortByName: 'updated_on' },
  { columnName: 'Status', sortByName: 'approval_status' }
  ];*/
  
  
    searchInput(event?:any){
      if(event?.target.value.length===0 && event.type !== 'focus'){
        
        this.search(event);
     }
    }
  
    search(event?:Event){
      
      this.loadEntity(false);
    }
  
    loadMore(){
      this.loadEntity(true);
   }
  
   loadEntity(loadMore: boolean) {
    this.setSearchCriteria(loadMore);
    /*Just chnage this service call accordingly*/
    this.searchService.search(this.searchConfig.searchCriteria,this.searchApp).subscribe((res:SearchCriteriaBuilder) => {
      this.handleLoadEntityResponse(loadMore,res);
    },
       (err) => {
          this.handleLoadEntityError(err);
       });
  }
  
   handleLoadEntityError(err:any) {
    console.error(err);
    this.showMoreDisabled=false;
  }
  handleLoadEntityResponse(loadMore:boolean, res:SearchCriteriaBuilder) {
    let resultObjArr:V[]=res.searchResult;
    
    if(loadMore){
      this.searchConfig.lastSelection=0;
   }else{
    this.totalSearchCount=res.totalCount;
     if(this.existingList.length>0){
      this.existingList.splice(0,this.existingList.length);
     }
   }
   resultObjArr.forEach(element => {
    this.existingList.push(element);
  });
   this.searchConfig.setResultMetadata(res)
   //this.onSortColumnChange();
   this.showMoreDisabled=false;
   
   //return localList;
  }
  
  private setSearchCriteria(loadMore: boolean) {
    this.searchConfig.searchCriteria.loadMore = loadMore;
    this.searchConfig.resetLoadMoreCount();
  }
  
  
    @HostListener("window:scroll", ["$event"])
     onWindowScroll() {
       let pos=0;
       let max=0;
       /*if(this.parentElementId && this.parentElementId!==''){
        
        pos = (document.getElementById(this.parentElementId).scrollTop ) + document.getElementById(this.parentElementId).offsetHeight;
        max = document.getElementById(this.parentElementId).scrollHeight;
        
       }else{*/
         pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
        max = document.documentElement.scrollHeight;
       /*}*/
        if (pos >= (max - (max*0.5))) {
          this.showMoreVisible=true;
        }else{
          this.showMoreVisible=false;
        }
        if (pos >= (max -  (max*0.1))) {
          
           //if (this.filteredProductList?.length !== this.productSearchService?.productSearchDetails.totalCount) {
           if(this.searchConfig.searchCriteria.loadedCount<this.searchConfig.searchCriteria.totalCount && this.searchConfig.searchCriteria.loadedCount<500){
              document.getElementById('showMoreId')?.click();
              this.showMoreDisabled=true;
           }
        }
     }
  
     
  
     /**
      * Search Ends
      * 
      */

      changeSortCriteria(){
       
       this.search();
     }
     sort(mode:string) {
      this.searchConfig.lastSelection=0;
      this.searchConfig.searchCriteria.sortMode=mode;  
      
      this.search();
   }
     
reset(){this.searchConfig.resetFilterConfigs();
  this.closeNav();
}

getSelectedFilterCriteriaCount():number{
  let count:number=0;
if(this.searchConfig.filterConfigs && this.searchConfig.filterConfigs.length>0){
  this.searchConfig.filterConfigs.forEach(filterConfig => {
    if(!filterConfig.location || (filterConfig.location && filterConfig.location!=='INLINE')){
    switch (filterConfig.filterType) {
      case 'DATE_RANGE':
        if(filterConfig.currentSelection && filterConfig.currentSelection.startDate!=null &&filterConfig.currentSelection.endDate!=null){
          count++;
        }
        break;
      case 'IN_LIST':
        if(filterConfig.currentSelection!=='ALL'){
          count++
        }
        break;
      case 'IN_LIST_INDICATOR':
      if(filterConfig.currentSelection!=='ALL'){
         count++
      }
      break;
      
      case 'IN_CHECKLIST':
        let atleastOneSelected:boolean;
        filterConfig.options.forEach(Option => {
          if(Option.selected===true){
            atleastOneSelected=true;
          }       
        });
        if(atleastOneSelected){
          count++;
        }
        
        break;
      default:
        break;
    }
  }
  });
}
  return count;
}

//DROPDOWN STARTS
chooseInList(filterConfig:FilterConfig) {
   this.searchConfig.chooseInList(filterConfig);
}

chooseInListIndicator(filterConfig:FilterConfig) {
   this.searchConfig.chooseInListIndicator(filterConfig);
 }

/*chooseLikeInListWithOr(filterConfig:FilterConfig) {
  if ('ALL' === filterConfig.currentSelection) {
    this.searchConfig.setFilterLikeInListWithOr(filterConfig.fieldName, 'String', []);
  } else {
    this.searchConfig.setFilterLikeInListWithOr(filterConfig.fieldName, 'String', [filterConfig.currentSelection]);
  }
}*/
//DROPDOWN ENDS

//CHECKBOX LIST STARTS
chooseInCheckList(filterConfig:FilterConfig) {
   this.searchConfig.chooseInCheckList(filterConfig);
}

//CHECKBOX LIST ENDS

//DATE RANGE STARTS


chooseDateRange(filterConfig:FilterConfig,event?:any,searchAfterDateChange?:boolean) {
  this.searchConfig.chooseDateRange(filterConfig,event,searchAfterDateChange);
  
 }
  /**CLEAN IT */

  
   locale:any={
      format: 'MM/DD/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
      displayFormat: 'MMM DD, YYYY', // default is format value
      direction: 'ltr', // could be rtl
      weekLabel: 'W',
      separator: ' To ', // default is ' - '
      cancelLabel: 'Cancel', // detault is 'Cancel'
      applyLabel: 'Confirm', // detault is 'Apply'
      clearLabel: 'Clear', // detault is 'Clear'
      customRangeLabel: 'Custom range',
      //daysOfWeek: moment.weekdaysMin(),
      //monthNames: moment.monthsShort(),
      firstDay: 1 // first day is monday
  }
  ranges: any = {
    //'No Selection': [undefined, undefined],
   'Today': [moment(), moment()],
   'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
   'Next 7 Days': [moment(),moment().add(6, 'days')],
   'This Month': [moment().startOf('month'), moment().endOf('month')],
   'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')],
   'This & Next 2 Month': [moment().startOf('month'), moment().add(2, 'month').endOf('month')],
   'Last 7 Days': [moment().subtract(6, 'days'), moment()],
   'Last 30 Days': [moment().subtract(29, 'days'), moment()],
   'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
 }
 alwaysShowCalendars: boolean=true;

invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

isInvalidDate = (m: moment.Moment) =>  {
  return this.invalidDates.some(d => d.isSame(m, 'day') )
  
}

//DATE RANGE ENDS


//Genric filter side bar starts
filterVisible:boolean=true;
embedded:boolean;
showHideFilter(){
  this.filterVisible=!this.filterVisible;
  
}
openNav() {
  this.showBackDrop=true;
  document.getElementById("myNav").style.width = "50%";
  if(this.searchConfig.filterConfigs && this.searchConfig.filterConfigs.length>0 && !this.selectedFilterConfig){
    this.selectedFilterConfig=this.searchConfig.filterConfigs[0];
    if(this.selectedFilterConfig.filterType==='DATE_RANGE'){
      this.chooseDateRange(this.selectedFilterConfig);
    }
    
  }
}

closeNav() {
  this.showBackDrop=false;
  document.getElementById("myNav").style.width = "0%";
  
  this.search();
}

onKeyupModalFilter(value:string,filterConfig:FilterConfig) {
  if (value.trim().length > 0) {
     
  } else {
     
  }
}

selectedAllForSearch(selection:boolean,filterConfig:FilterConfig){
  this.searchConfig.selectedAllForSearch(selection,filterConfig);
}

getFilteredOptions(selectedFilterConfig:FilterConfig,searchText:string){
  if(searchText && searchText!=null && searchText.trim()!==''){

  }
  return selectedFilterConfig;

}
//selectedFilterConfig.options.filter(x=>x[selectedFilterConfig.optionDislpayVar].includes(modalSearchInput.value))
//generic filter side bar ends


}