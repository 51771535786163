import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ComposeEmail } from 'src/app/shared/model/compose-email.model';
import { EmailTemplateConfig } from 'src/app/shared/model/email-template-config.model';
import { Product, SageItemCreationHelper } from 'src/app/shared/model/product.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { ComposeEmailService } from 'src/app/shared/service/compose-email.service';
import { ProductCatalogService } from 'src/app/shared/service/product-catalog.service';
import { ProductService } from 'src/app/shared/service/product.service';
import { SharedService } from 'src/app/shared/service/shared.service';
import { Location } from '@angular/common';
import { OrderDetailSizeQuantity } from 'src/app/shared/model/sales/order-detail.model';
import { ToastrService } from 'ngx-toastr';
import { SageService } from 'src/app/shared/service/sage/sage.service';
import { error } from 'console';

@Component({
  selector: 'app-multiple-create-item',
  templateUrl: './multiple-create-item.component.html',
  styleUrls: ['./multiple-create-item.component.css']
})
export class MultipleCreateItemComponent implements OnInit {



  constructor(private authService:AuthService,private productCatalogService:ProductCatalogService,
    private sageService:SageService,private composeEmailService:ComposeEmailService,private sharedService:SharedService,
    private location: Location,private toastrService:ToastrService) { }

  userAccessSubscription: Subscription;
  userAccess: UserAccess;

  currentLoggedInUserSubscription: Subscription;
  currentLoggedInUser: User;
  selectedProductList:Product[]=[];
  sageItemCreationHelperList:SageItemCreationHelper[]=[];


 // composeEmail: ComposeEmail = new ComposeEmail;
  // showEmailEditor:boolean=false;

  ngOnInit(): void {
    this.handleSubscriptions();
    this.selectedProductList = this.productCatalogService.selectedProductsForCatalog;
    if(this.selectedProductList && this.selectedProductList.length>10){
      this.toastrService.error("Only 10 SKUs item creation allowed");
     this.goBack(); 
    }else{
    this.loadProductAditionalDetails(this.selectedProductList);
    }

  }
  handleSubscriptions() {
    this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
       this.userAccess = access;
    });
    this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
       this.currentLoggedInUser = currentLoggedInUser;
    });
  }


  loadProductAditionalDetails(productList: Product[]) {
    if (productList && productList != null && productList.length > 0) {
      productList.forEach(product => {
        if (product !== null && product != undefined) {
          //this.productService.loadProductAttachmentsUrls(product,'PNL',product.licensorId,product.brandId);
          //this.productService.loadProductImagesUrls(product,product.licensorId,product.brandId);
          //this.productService.loadProductAttachmentsUrls(product,'MASTER_ARTS',product.licensorId,product.brandId);
          //this.productService.loadProductAttachmentsUrls(product,'SEPERATIONS',product.licensorId,product.brandId);
          // this.productService.loadProductAttachmentsUrls(product, 'APPROVAL_DOCS', product.licensorId, product.brandId);
          //this.productService.loadProductAttachmentsUrls(product,'PHOTO_APPROVAL',product.licensorId,product.brandId);
          //this.loadPrinterMatrix();
          let sageItemCreationHelper:SageItemCreationHelper=new SageItemCreationHelper();
          sageItemCreationHelper.product=product;
          this.sageItemCreationHelperList.push(sageItemCreationHelper);
        }
      });
    }
  }

  copySizeSelectionToAllStylesEvent(orderDetailSizeQuantityList:OrderDetailSizeQuantity[]){
    let selectedSizeArr:string[]=orderDetailSizeQuantityList.filter(x=>x.selected).map(odsql => odsql.size);
    this.sageItemCreationHelperList.forEach(sageItemCreationHelper => {
      sageItemCreationHelper.sizeSelectionList.forEach(sizeSelection => {
        if(selectedSizeArr.includes(sizeSelection.size)){
          sizeSelection.selected=true;
        }else{
          sizeSelection.selected=false;
        }
      });
    });

  }
  createItems(){
    let sageItemCreationHelperWithSelection:SageItemCreationHelper[]=[];
    let mockPresent=false;
    
    this.sageItemCreationHelperList.forEach(sich => {
      if(!sich.product.convertedToSKU){
        mockPresent=true;
      }
      let sizeSelection=sich.sizeSelectionList.filter(x=>x.selected);
      if(sizeSelection.length>0){
        sageItemCreationHelperWithSelection.push(sich);
      }
    });
    if(mockPresent){
      this.toastrService.error("One of the Product Selected is a Mock. Please select SKUs only");
      return;
    }
    if(sageItemCreationHelperWithSelection.length>0){
      this.sageService.createSageItems("AD-HOC",Date.now()+"","MULTI ITEM CREATION",sageItemCreationHelperWithSelection).subscribe(response=>{
        if (response.responseStatus.status === 'SUCCESS') {
          let auditItems = response.responsePayload;
          this.processAuditItems(auditItems);
        }
       
      },error=>{
        this.toastrService.error("Error while creating items in Sage", error);
      });
    }else{
      this.toastrService.error("Please select the size for any style");
    }
  }
  processAuditItems(auditItems: any) {
    let hasError:boolean=false;
    
   if(auditItems && auditItems.length>0){
  
    this.sageItemCreationHelperList.forEach(sageItemCreationHelper => {
     let auditsForProduct=auditItems.filter(x=>x.productId===sageItemCreationHelper.product.id);
     sageItemCreationHelper.createItemAudit=auditsForProduct;
     let auditMap: Map<string, Map<string, any[]>> = new Map();
     sageItemCreationHelper.createItemAudit.forEach(x=>{
      if(!auditMap.get(x.companyCode)){
        auditMap.set(x.companyCode,new Map());
      }
      let companySpecificAuditsMap:Map<String,any[]>=auditMap.get(x.companyCode);
      let statusType='Success';
      if(['FAILED','UNKNOWN','EXCEPTION'].includes(x.status)){
        statusType='Error';
      }
      if(statusType==='Error'){
        hasError=true;
      }
      if(!companySpecificAuditsMap.get(statusType)){
        companySpecificAuditsMap.set(statusType,[]);
      }
      let companySpecificAudits:any[]=companySpecificAuditsMap.get(statusType);
      
      companySpecificAudits.push(x);
     });
     sageItemCreationHelper.auditMap=auditMap;
     console.log(sageItemCreationHelper.product.sku,sageItemCreationHelper.auditMap);
    });
    if(hasError){
      this.toastrService.warning("Please find the Item creation status below");
    }else{
      this.toastrService.success("Items Createed Successfully ");  
    }
    
   }else{
    this.toastrService.success("Items Createed Successfully ");
   }
  }
/*
  sendApprovalEmail(selectedProductList:Product[]){
    this.showEmailEditor=true;
    let emailConfig: EmailTemplateConfig = new EmailTemplateConfig;
    //emailConfig.productId=this.product.id;
    emailConfig.brandId=this.selectedProductList[0].brandId;
    emailConfig.licensorId=this.selectedProductList[0].licensorId;
    if(selectedProductList && selectedProductList.length>0){
      selectedProductList.forEach(product => {
        emailConfig.productSkuList.push(product.sku);
        emailConfig.productIdList.push(product.id);
      });
    }
    //emailConfig.sku=this.product.sku;
    emailConfig.templateName='productApprovalEmail';
    
    this.composeEmail=new ComposeEmail;
    this.getEmailTemplate(emailConfig);
    let availableAtt=[];
    if(selectedProductList && selectedProductList.length>0){
      selectedProductList.forEach(prod => {
        availableAtt=availableAtt.concat(prod.photoApproval).concat(prod.approvalDocs);
      });
    }
    this.composeEmail.emailAttachments=this.sharedService.productAttachmentToEmailAttachment(availableAtt.filter(x=>x.isSelected));
   }

   getEmailTemplate(emailConfig:EmailTemplateConfig){
    this.composeEmailService.getEmailTemplate(emailConfig).subscribe(response => {
       let composeEmailTemp:ComposeEmail = response.responsePayload;
       this.composeEmail.emailContent=composeEmailTemp.emailContent;
       this.composeEmail.emailTo=composeEmailTemp.emailTo;
       this.composeEmail.emailCc=composeEmailTemp.emailCc;
       this.composeEmail.emailSubject=composeEmailTemp.emailSubject;
       this.composeEmail.emailTemplateConfig=emailConfig;
       document.getElementById('composeEmailToggler').click();
    });
 }

 async emailSent(composeEmail:ComposeEmail,productList:Product[]){
  if(composeEmail && composeEmail.emailTemplateConfig){
    let config:EmailTemplateConfig=composeEmail.emailTemplateConfig;
    if(config.templateName==='productApprovalEmail'){
      if (productList && productList != null && productList.length > 0) {
        productList.forEach(async product => {
        this.productService.logApprovalEmail(composeEmail,product,'APPROVAL_DOCS');
        await this.productService.handleApprovalEmailSentForProduct(product,'EMAIL','APPROVAL_DOCS');
        });
      }
     
    }
  
  }
}*/

goBack(){
  this.location.back();
}

}
