import { ProductAttachment } from '../product.model';
import { PurchaseOrderDetailView } from './purchase-order-detail-view.model';

export class PurchaseOrderView {
   id:string;
   companyCode:string;
   poNumber: string;
   poDate: Date;
   poStatus: string;
   reference: string;
   description: string;
   vendorCode: string;
   vendorName: string;
   vendorAddress: string;
   shipToCode: string;
   shipViaCode: string;
   billToCode:string;
   terms: string;
   exfactoryDate: Date;
   etaDate: Date;
   comment: string;
   totalQty: number;
   totalCost: number;
   details: PurchaseOrderDetailView[] = [];
   poAttachments: ProductAttachment[]=[];
   povdLocationMasterSelector:string;
   poCreationDate:Date;
	poCreatedBy:string;
	poUpdateCount:number=0;
   cancellationReason:string;

   //tnnasient UI Fields
   shipViaList:any[]=[];
   shipToList:any[]=[];
   billToList:any[]=[];
   editable:boolean;
   //
}



export class PurchaseOrderViewList {
purchaseOrderViews:PurchaseOrderView[]=[];

}


export class PurchaseOrderOverrideConsentView {
   purchaseOrderView:PurchaseOrderView;
   notes:string;
   errors:string[];
   }