<div class="container-fluid">
<div class="floatBtn d-flex flex-column" *ngIf="!hideFloatBtns">  
    <a [routerLink]="['/submissions/multi-approval']" class="btn btn-circle btn-circle-md mb-2" style="background-color: tomato;" *ngIf="selectedProductForCatalogCount>0 && userAccess?.canCreateApprovalForms && isEligibleForMultipleSubmission() " matTooltip="Multiple Approval Submissions" matTooltipPosition="left" matTooltipClass="bg-dark"><span><img style=" max-width:60%;" src="/assets/multipleForms4.png"></span></a>
    <a [routerLink]="['/sage/item/multi-create']" class="btn btn-circle btn-circle-md mb-2 d-flex align-items-center justify-content-center" style="background-color: #005f86;" *ngIf="selectedProductForCatalogCount>0 && userAccess?.canCreateItemSageAdhoc" matTooltip="Create Item In Sage" matTooltipPosition="left" matTooltipClass="bg-dark"><span><img src="/assets/sage_logo3.png" style="width: 60px; height: 60px; object-fit: contain;"></span></a><!--fa-file-pdf-->
    <button id="sendEmail" type="button" class="btn btn-circle btn-circle-md  mb-2 bg-navy-blue" (click)="sendProductEmail()" *ngIf="selectedProductForCatalogCount>0 && selectedProductForCatalogCount&lt;11 && userAccess?.canCreateProduct"   matTooltip="Send Product Email" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fa fa-envelope text-light fa-lg"></i></button>
    <button type="button" (click)="createCollection()"  class=" btn btn-circle btn-circle-md  bg-success mb-2" *ngIf="selectedProductForCatalogCount>1 && selectedProductForCatalogCount&lt;7 &&  userAccess?.canCreateProduct"  matTooltip="Create collection of selected products" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-layer-group fa-lg" style="color: white;"></i></button>
    <button type="button" (click)="downloadSelectedImages()"  class=" btn btn-circle btn-circle-md bg-info mb-2" *ngIf="selectedProductForCatalogCount>0"  matTooltip="Download selected product images as zip" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-file-archive fa-lg" style="color: white;"></i></button>
    <button type="button" (click)="resetCatalogSelection()"  class=" btn btn-circle btn-circle-md bg-dark mb-2" *ngIf="selectedProductForCatalogCount>0"  matTooltip="Reset product selection for catalog" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-undo-alt fa-lg" style="color: white;"></i></button>
    <button type="button" (click)="selectAll()"  class=" btn btn-circle btn-circle-md bg-warning mb-2" *ngIf="userAccess?.canCreateCatalog && filteredProductList?.length>0"  matTooltip="Select all in current list" matTooltipPosition="left" matTooltipClass="bg-dark"><span><img style=" max-width:50%;" src="/assets/select-all.png"></span></button>
    <a [routerLink]="['/product-catalog']" class="btn btn-circle btn-circle-md mb-2" style="background-color: tomato;" *ngIf="selectedProductForCatalogCount>0" matTooltip="Preview Catalog" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-eye  fa-lg" ></i></a><!--fa-file-pdf-->
    <button type="button" class="btn btn-circle btn-circle-md bg-secondary mb-2" matTooltip="Scroll To Top" onclick="window.scrollTo({top: 0, left: 0, behavior: 'smooth'});"  matTooltipPosition="left" matTooltipClass="bg-dark">
      <i class="fas fa-arrow-up fa-lg" style="color:white"></i>
   </button>
</div>

    <div class="row">
        <div class="col-sm-3 col-lg-2 col-md-3 border" *ngIf="showSideBar">
            <div>
                <div class="list-group list-group-item bg-dark text-white mt-2">
                    <div class="d-flex justify-content-between padding-0">
                        <div><i class="fas fa-filter mr-1"></i></div>
                        <h6>Filters</h6>
                        <button type="button" class="btn btn-sm bg-white py-0" (click)="resetAdvSearch()"   matTooltip="Reset applied filters" matTooltipPosition="right" matTooltipClass="bg-dark">
                            <i class="fas fa-undo fa-sm"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <div >
                    <!--GENERIC STARTS-->
                    <div *ngFor="let type1 of filterTypes;index as typeInex">
                        <div class="row m-1">
                            <button class="btn btn-sm color-3 btn-block"  (click)="loadFilterItem(type1)"
                                data-toggle="modal" attr.data-target="#{{type1}}FilterModal">{{filterTypesNames[typeInex]}}
                                <i class="fas fa-plus"></i>
                            </button>
                        </div>
                        <div class="row ml-1">
                            <div class="col-sm-11" *ngFor="let item of getFilteredListForSearch(type1);index as index">
                                <ng-container *ngIf="item && item!=='' && item!==' '">
                                <div class="custom-control custom-checkbox mr-sm-2">
                                    <input type="checkbox" class="custom-control-input " id="{{type1+index}}"  (change)="selectedForSearch($event,item,type1)"
                                    [checked]="isSelectedForSearch(item,type1)">
                                    <label class="custom-control-label text-break" for="{{type1+index}}">{{item}}</label>
                                </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <!--GENERIC ENDS-->
                </div>
                <div class="m-1 d-flex">
                    <button class="btn bg-dark text-white btn-block" (click)="search()" 
                        >Apply Filters</button>
                </div>
            </div>
           
        </div>
        <div class="col-sm col-lg col-md mt-1">
            <section class="gallery-block cards-gallery">
                <div class="container-fluid">
                    <div class="row d-flex justify-content-center">
                        <h2 class="d-inline">Products</h2>
                        <button type="button"
                            [routerLink]="['/createProduct/create/'+serachCriteria.licensorId+'/'+serachCriteria.brandId+'/'+brandSku]"
                            class="btn btn-circle btn-circle-md btn-circle btn-circle-md-md d-inline ml-4" *ngIf="userAccess?.canCreateProduct && !hideAddProduct"
                            matTooltip="Create New Product" matTooltipPosition="right" matTooltipClass="bg-dark">
                            <i class="fas fa-plus fa-lg text-light"></i>
                        </button>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-sm-5 d-flex d-inline">
                            <input type="search" name="SkuCode" [(ngModel)]="searchStringForSearch" class="form-control" (keyup.enter)="searchBtn.click()" 
                                id="inlineFormInputGroupUsername" placeholder="Search by attributes" #SkuCode   (input)="searchInput($event)">
                                <div class="ml-1">
                                    <button type="button" class="btn  {{!(searchStringForSearch.length>0)?'bg-dark text-white':'bg-dark text-white'}} " (click)="search($event)" [disabled]="!(searchStringForSearch.length>0)"
                                       #searchBtn >Search</button>
                                </div>
                                <div class="ml-2 d-flex align-items-center" >
                                    <a class="badge badge-pill bg-dark" matTooltip="For all products &#13;&#8226; with Color - Blue&#13; Search : BLUE &#13;&#8226; with Color - Blue & Garment Type - Tee&#13; Search : BLUE TEE &#13;&#8226; with Color - Blue & Garment Type - Tee & Category - Baby  &#13; Search : BLUE TEE BABY" 
                                    matTooltipPosition="right" matTooltipClass="allow-cr extended-tooltip"><i class="fas fa-info" ></i></a>
                                </div>
                        </div>
                        
                        <div class="col-sm-2 d-flex justify-content-start align-items-center">
                            <!--<div class="custom-control custom-checkbox mr-sm-2 ml-1" >
                                <input type="checkbox" class="custom-control-input f-13" id="excludeMock" name="excludeMock"
                                [checked]="searchConfig.excludeMock" [(ngModel)]="searchConfig.excludeMock" (change)="search()">
                                <label class="custom-control-label text-break align-middle" for="excludeMock"><span>Exclude Mocks</span></label>
                            </div>-->
                            <select class="form-control form-control-sm custom-select mr-1" id="excludeProductType" name="excludeProductType" [(ngModel)]="searchConfig.excludeProductType" (change)="search()" [disabled]="searchConfig.excludeProductType === 'SALES'" ><!--[(ngModel)]="excludeProductType" -->
                                <option value="ALL">Show All</option>
                                <option value="SKU">Show Mocks Only</option>
                                <option value="MOCK">Show Sku Only</option>
                            </select>
                        </div>
                        <div class="col-sm-auto d-flex d-inline">
                           <label class="col-form-label text-nowrap">Sort By:&nbsp;</label>
                           <select class="form-control form-control-sm custom-select mr-1" id="sortBy" name="sortBy" [(ngModel)]="selectedSortByName" (change)="changeSortCriteria()">
                              <option *ngFor="let sortColumn of sortColumns" [ngValue]="sortColumn.sortByName">
                                 {{sortColumn.columnName}}</option>
                           </select>
                           <span (click)="sort('ASC')" *ngIf="selectedSortByName && sortMode==='ASC'"><i class="fas fa-sort-amount-up-alt fa-lg mr-1" style="color:black; vertical-align: middle;"></i></span>
                           <span (click)="sort('DESC')" *ngIf="selectedSortByName && sortMode==='ASC'"><i class="fas fa-sort-amount-down fa-lg mr-1" style="color:grey; vertical-align: middle;"></i></span>
                           <span (click)="sort('ASC')" *ngIf="selectedSortByName && sortMode==='DESC'"><i class="fas fa-sort-amount-up-alt fa-lg mr-1" style="color:grey; vertical-align: middle;"></i></span>
                           <span (click)="sort('DESC')" *ngIf="selectedSortByName && sortMode==='DESC'"><i class="fas fa-sort-amount-down fa-lg" style="color:black; vertical-align: middle;"></i></span>
                        </div>
                    </div>
                    <div class="mt-1">
                        <p>Showing {{productList?.length}} of {{totalSearchCount}} Product(s)<small class="text-muted">&nbsp;&nbsp;&nbsp;Enhance search criteria for more products.</small></p>
                    </div><!--of {{productList?.length}}-->
                    <div class="row mt-2">
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-2 px-2"
                                *ngFor="let product of filteredProductList; index as index" [@fadeInOut]>
                                <app-product-image-card id="imageCard_{{index}}" [index]="index" [product]="product" [userAccess]="userAccess" (onSelect)="childSelectionChanged($event)" (onDelete)="loadProducts(false)" [stopProductNavigation]="stopProductNavigation" [stopProductDeletion]="stopProductDeletion" [enableExclusiveSelection]="enableExclusiveSelection"></app-product-image-card>
                        </div>
                    </div>
                    <hr>
                    <div class="row justify-content-center">
                        <button type="button" id="showMoreId" [disabled]="showMoreDisabled" class="btn bg-dark text-white" *ngIf="filteredProductList?.length!==totalSearchCount" (click)="loadMoreProducts()">Show More</button>
                    </div>
                </div>
            </section>
        </div>
    </div>


<!-- ******************************************************************************MODAL**********************************************************-->
    <!--GENERIC MODAL STARTS-->
    <!-- GENERIC Modal -->
    <div class="modal fade" id="{{type}}FilterModal" tabindex="-1" role="dialog" aria-labelledby="FilterModalTitle"
        aria-hidden="true" *ngFor="let type of filterTypes;index as typeIndex">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">{{filterTypesNames[typeIndex]}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <input class="form-control mb-2" #modalSearchInput
                        (keyup)="onKeyupModalFilter($event,modalSearchInput.value,type)" placeholder="Find..">
                    <div class="mb-2 d-flex justify-content-end">
                        <a (click)=" selectedAllForSearch(true,type)" class="btn btn-sm btn-warning bg-white">Select All</a>
                        <a (click)=" selectedAllForSearch(false,type)" class=" btn btn-sm btn-danger bg-white ml-2">Unselect All</a>
                    </div>
                    <div class="ml-2" *ngFor="let item of getFilteredMasterListForModal(type)">
                        <ng-container *ngIf="item && item!=='' && item!==' '">
                        <div class="row">
                            <label class="cb-container">
                                <h6 class="ml-4 mt-1">{{item}}</h6>
                                <input type="checkbox" (change)="selectedForSearch($event,item,type)"
                                    [checked]="isSelectedForSearch(item,type)">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        </ng-container>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="m-1 d-flex">
                        <button class="btn bg-dark text-white btn-block" (click)="search()" 
                        data-dismiss="modal">Apply Filters</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- GENERIC MODAL ENDS-->
</div>

<!--EMAIL starts-->
<button id="composeEmailToggler" type="button" style="display: none;" data-toggle="modal" data-target="#composeEmail"></button>
<div *ngIf="showEmailEditor">
    <app-compose-email  [modalId]="'composeEmail'" [emailHeader]="'Email'" [composeEmail]="composeEmail" ></app-compose-email>
</div>
<!--EMail Ends-->