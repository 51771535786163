import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Product, StatusDetails } from 'src/app/shared/model/product.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { Customer } from 'src/app/shared/model/customer.model';
import { CustomerService } from 'src/app/shared/service/customer.service';
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { ApiResponse } from 'src/app/shared/model/api-response';

@Component({
  selector: 'app-status-change',
  templateUrl: './status-change.component.html',
  styleUrls: ['./status-change.component.css']
})
export class StatusChangeComponent implements OnInit {

  constructor(private customerService:CustomerService,public sharedService:SharedService,private metadataService:MetadataService) { }
  submissionDetailHeaders=['SKU','Status','Status Date','User', 'Licensor Comments','Philcos Comments'];
  @Input() header: string = 'SKU Approval Details';

  @Input() product: Product;
  @Input() userAccess:UserAccess;
  @Input() existingStatus:string;
  @Input() approvalStatusList:[]=[];
  customerList:Customer[]=[];
  courierList:string[]=[];
  @Input() modalId: string;
  @Input() editable: boolean=false;
  @Input() editBtnVisible: boolean=false;
  @Input() saveBtnText:string="Save";
  //@ViewChild('addEditContactForm') addEditContactForm: any;
  @Output() onSave = new EventEmitter<Product>();
  @Output() onCancel = new EventEmitter<Product>();
  @Output() onEditClicked = new EventEmitter<boolean>();
  

  ngOnInit(): void {
    this.loadCustomers();
    this.loadMetadataForClassificationAndType('GENERAL','COURIER');
  }
  loadCouriers() {
    throw new Error("Method not implemented.");
  }
  loadCustomers() {
    this.customerService.getCustomerListByTier(CustomerService.CUSTOMER_TIER_ALL).subscribe(res=>{
      //
      if(res && res!=null && res.responsePayload.length>0){
       let customerArr:Customer[]=res.responsePayload as Customer[];
       customerArr.forEach(x=>{
         this.customerList.push(x);
         //
       });
       this.customerList=this.sharedService.sortListByPropertyName(this.customerList,'customerName');
      }
    });
  }
  loadMetadataForClassificationAndType(classifictaion:string,type:string){
    this.metadataService.getMetadataValueListByClassificationAndType(classifictaion,type).subscribe((res: ApiResponse) => {
      if(res.responseStatus.status==='SUCCESS'){
         let sortedList=this.sharedService.sort(res.responsePayload);
         switch (type) {
           case 'COURIER': this.courierList=sortedList; break;
           default: break;
         }
         }
     });       
   }
  closeModal() {
    this.product.approvalStatus=this.existingStatus;
    this.onCancel.emit(this.product);
    //this.existingStatus=undefined;
    
  }

  saveModal() {
    if(this.product.approvalStatus!==this.existingStatus){
      this.product.statusDetails.forEach(element => {
        if(element.id===undefined || element.id===null){
          this.product.licensorComments=element.licensorComments;
          this.product.statusDate=element.statusDate;
          //
        }
      });
    }
     this.onSave.emit(this.product);
     //this.existingStatus=undefined;
  }

  statusChanged(product:Product,event:Event){
    let newTempStatusDetails=new StatusDetails;
    if(!product.statusDetails|| product.statusDetails.length===0){
      product.statusDetails=[];
    }
    let existingNewStatusEntry:boolean=false;
    this.product.statusDetails.forEach(element => {
      if(element.id===undefined || element.id===null){
        element.status=product.approvalStatus;
        element.statusDate=new Date;
        existingNewStatusEntry=true;
        newTempStatusDetails=element;
      }
    });
    //if(['Concept Pending','Pre-Pro Samples Submitted'].includes(product.approvalStatus)){
      if(!existingNewStatusEntry){
      let details:StatusDetails=new StatusDetails;
       details.status=product.approvalStatus;
       details.statusDate=new Date;
       product.statusDetails.push(details);
       newTempStatusDetails=details;
      }
      if(product.isCollection){
        this.sharedService.handleProductApprovalStatusChangeForCollection(newTempStatusDetails,product);
      }
    //}
  }
  remove(sd:StatusDetails){
    //
    this.product.statusDetails=this.product.statusDetails.filter(x =>sd!=x);
    this.product.approvalStatus=this.existingStatus;
  }

  editClicked(){
    this.onEditClicked.emit(true);
  }

}
