export class BlanksManufacturerMetadata {
   id: string;
   supplierId: string;
   manufacturer: string;
   manufacturerCode: string;
   manufacturerSeries: string;
   description: string;
   garment: string;
   blanksType: string;
   category: string;
   composition: string;
   quality: string;
   gsm: string;
   coo: string;
   code: string;
   specialityDescription: string;
   specialityType: string;
   replenishmentQty: number;
}
