import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SearchCriteriaBuilder } from '../model/common/search-criteria-builder.model';
import { SearchDetails } from '../model/common/search-details.model';
/*import { SearchCriteriaBuilder } from '../../model/common/search-criteria-builder.model';
import { SearchDetails } from '../../model/common/search-details.model';
import { ItemSearchDetails } from '../../model/wms/inventory/common/item-search-details.model';
import { CommonService } from './common.service';*/

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  salesOrderListSearchDetails:SearchDetails=new SearchDetails('SALES_ORDER_LIST','LAST_MODIFIED_DATE','DESC',50);
  salesOrderCostingListSearchDetails:SearchDetails=new SearchDetails('SALES_ORDER_LIST','LAST_MODIFIED_DATE','DESC',50);
  salesOrderEstimationListSearchDetails:SearchDetails=new SearchDetails('SALES_ORDER_LIST','LAST_MODIFIED_DATE','DESC',50);
  productionTrackerListSearchDetails:SearchDetails=new SearchDetails('PRODUCTION_TRACKER_LIST','SALES_ORDER_NUMBER','DESC',50);
  printerPortalListSearchDetails:SearchDetails=new SearchDetails('PRINTER_PORTAL_LIST','SALES_ORDER_NUMBER','DESC',50);
  taskListSearchDetails:SearchDetails=new SearchDetails('TASK_LIST','PLANNED_END_DATE','DESC',50);
  printerCapacityGroupedListSearchDetails:SearchDetails=new SearchDetails('PRINTER_CAPACITY_GROUPED_LIST','PRINTER_ACTUAL_SHIP_DATE','DESC',5000);
  fileClosureSummaryListSearchDetails:SearchDetails=new SearchDetails('FILE_CLOSURE_SUMMARY_LIST','SALES_ORDER_NUMBER','DESC',50);
  trimsInfoViewListSearchDetails:SearchDetails=new SearchDetails('TRIMS_INFO_VIEW_LIST','ID','DESC',50);
  royaltyMismatchListSearchDetails:SearchDetails=new SearchDetails('ROYALTY_MISMATCH_LIST','APPROVAL_DATE','ASC',50);
  salesArtTrackerViewListSearchDetails:SearchDetails=new SearchDetails('SALES_ART_TRACKER_VIEW_LIST','ORDER_SHIP_DATE','ASC',50);
  chargeBackListSearchDetails:SearchDetails=new SearchDetails('CHARGE_BACK_LIST','LAST_MODIFIED_DATE','DESC',50);
  salesPreProTrackerViewListSearchDetails:SearchDetails=new SearchDetails('SALES_PREPRO_TRACKER_VIEW_LIST','ORDER_SHIP_DATE','ASC',50);
  consolidatedInventoryReportSearchDetails:SearchDetails=new SearchDetails('CONSOLIDATED_INVENTORY_REPORT', 'ITEMCODE', 'ASC');
  blanksProductListSearchDetails:SearchDetails=new SearchDetails('BLANKS_PRODUCT_LIST','UPDATED_ON','DESC',50);
  /*inventoryItemSearchDetails:SearchDetails=new SearchDetails('INVENTORY_ITEMS','ITEM_CODE','ASC');
  receivingReqInventoryItemSearchDetails:SearchDetails=new SearchDetails('INVENTORY_ITEMS','ITEM_CODE','ASC');
  inventoryItemAvailabilitySearchDetails:SearchDetails=new SearchDetails('INVENTORY_ITEMS_AVAILABILITY','ITEMCODE','ASC');
  shippingReqInventoryItemSearchDetails:SearchDetails=new SearchDetails('INVENTORY_ITEMS_AVAILABILITY','ITEMCODE','ASC');//USed with item availability COmponet
  receivingRequisitionListSearchDetails:SearchDetails=new SearchDetails('RECEIVING_REQUSIITON_LIST','REQUEST_DATE','DESC');
  shippingRequisitionListSearchDetails:SearchDetails=new SearchDetails('SHIPPING_REQUSIITON_LIST','REQUEST_DATE','DESC');
*/
  constructor(private http: HttpClient) { }

   readonly rooturl = environment.apiEndPoint;
   
   readonly wmsRooturl = environment.wmsApiEndPoint;

   search(searchCriteria: SearchCriteriaBuilder, searchApp: string): Observable<SearchCriteriaBuilder> {
      if ('WMS' === searchApp) {
         let headers = new HttpHeaders().set('SEARCH_APP', searchApp);
         return this.http.post<any>(this.wmsRooturl + '/search/search', searchCriteria, { headers: headers });
      } else {
         return this.http.post<any>(this.rooturl + '/search/search', searchCriteria);
      }
   }

}
