import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApiResponse } from '../model/api-response';

@Injectable({
   providedIn: 'root'
})
export class BlanksManufacturerMetadataService {

   constructor(private http: HttpClient) { }

   readonly rooturl = environment.apiEndPoint;

   getDistinctBlanksManufacturerList(): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/blanks/manufacturer/metadata/distinct/manufacturer/list', null);
   }

   getBlanksManufacturerMetadataList(): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/blanks/manufacturer/metadata/list', null);
   }

   getBlanksManufacturerMetadataListForSupplier(supplierId: string): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/blanks/manufacturer/metadata/list/' + supplierId, null);
   }

   getBlanksManufacturerMetadata(id: string) {
      return this.http.post<any>(this.rooturl + '/blanks/manufacturer/metadata/' + id, null);
   }


}
