import {   PrinterBlanksDistributionGrouped, PrinterSizeDetails, PrinterSizeDetailsForSummary } from "../printer-size-details.model";

export class PrinterSummary {
   id: string;
   salesOrderId: string;

   printerName: string;
   supplierName: string;
   printerId: string;
   supplierId: string;

   printerSummaryDetail: PrinterSummaryDetail;
   lastModifiedDate: Date;
   lastModifiedBy: string;
   submissionCount:number;
   costingApprovalCount:number;
   submissionApprovalCount:number;
	approvalReason:string;
	latest:boolean;
	fullPackage:boolean;
	createdOn:Date;
	createdBy:string;
   priorApprovedPoReferenceId:string;
   psDiff:any;
   topDiff:any;
   pfcDiff:any;
   groupedAddlCharges:any;
   poStatus:string;

   diffMode:boolean=false;
}


export class PrinterSummaryDetail {
   printerName: string;
   printerId: string;
   jobType: string;
	//styleCode: string;
   printerSizeDetails: PrinterSizeDetailsForSummary[];
   printerCurrency: string;
   totalQty: number;
   totalCost: number;
   totalFilmCharges:number;
   totalOtherCharges:number;
   fullPackage:boolean;
   printerBlanksDistributionGroupedList:PrinterBlanksDistributionGrouped[]=[];
}

export class PrinterSummaryView {
   createdOn:Date;
   isCurrent:boolean;
   submissionCount:number;
   printerSummaries:PrinterSummary[]=[];
   costingApprovalCount:number;
   submissionApprovalCount:number;
	approvalReason:string;
	latest:boolean;
   hasPOCancellation:boolean=false;
   

}