
<div class="d-flex justify-content-center align-items-center mt-3" *ngIf="sageItemCreationHelperList">
    <div class="text-color-blue"><h3>Create Sage Items&nbsp;</h3></div>
</div>
<div  class="separator-blue" *ngIf="sageItemCreationHelperList && sageItemCreationHelperList.length>0">&nbsp;</div>

<ng-container *ngFor="let sageItemCreationHelper of sageItemCreationHelperList">
    <app-create-item [sageItemCreationHelper]="sageItemCreationHelper" (copySizeSelectionToAllStylesEvent)="copySizeSelectionToAllStylesEvent($event)"></app-create-item>
</ng-container>


<ng-container>
    <div class="floatBtn d-flex flex-column">  
       <button id="sendEmail" type="button" class="btn btn-circle  mb-2" (click)="createItems()"  style="background-color: #005f86;"
        *ngIf="userAccess?.canCreateItemSageAdhoc && sageItemCreationHelperList && sageItemCreationHelperList.length>0"
        matTooltip="Create Items In Sage" matTooltipPosition="below" matTooltipClass="bg-dark">
        <!--<i class="far fa-plus-square text-light fa-lg fa-2x"></i> -->
        <span><img src="/assets/sage_logo3.png" style="width: 60px; height: 60px; object-fit: contain;"></span>
        </button>
        <!--<a [routerLink]="['/sage/item/multi-create']" class="btn btn-circle btn-circle-md mb-2 d-flex align-items-center justify-content-center" style="background-color: #005f86;" *ngIf="selectedProductForCatalogCount>0 && userAccess?.canCreateApprovalForms" matTooltip="XXXXMultiple Item Creation" matTooltipPosition="left" matTooltipClass="bg-dark"><span><img src="/assets/sage_logo3.png" style="width: 60px; height: 60px; object-fit: contain;"></span></a>-->
        <a (click)="goBack()" class="btn btn-circle"  style="background-color: tomato;"
    matTooltip="Exit" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-arrow-left  fa-2x"></i></a>
        
    </div>
</ng-container>

<!--<ng-container>
    <div class="floatBtn d-flex flex-column">  
        <button id="sendEmail" type="button" class="btn btn-circle  mb-2 bg-navy-blue" (click)="sendApprovalEmail(selectedProductList)" 
        *ngIf="userAccess?.canCreateApprovalForms && selectedProductList && selectedProductList.length>0"
        matTooltip="Send Approval Email" matTooltipPosition="below" matTooltipClass="bg-dark">
        <i class="fa fa-envelope text-light fa-lg fa-2x"></i> 
        </button>
        <a (click)="goBack()" class="btn btn-circle"  style="background-color: tomato;"
    matTooltip="Exit" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-arrow-left  fa-2x"></i></a>
        
    </div>
</ng-container>-->
<!--<ng-container>
    <button id="composeEmailToggler" type="button" style="display: none;" data-toggle="modal" data-target="#composeEmail"></button>
    <div *ngIf="showEmailEditor">
        <app-compose-email  [modalId]="'composeEmail'" [emailHeader]="'Email'" [composeEmail]="composeEmail" (onSend)="emailSent($event,selectedProductList)"></app-compose-email>
        </div>
</ng-container>-->

