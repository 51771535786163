<div>
   <div class="row mt-2 d-flex justify-content-center">
      <h2 class="d-inline">
         WMS Inventory Report
      </h2>
   </div>
   <div>
      <div class="row">

         <div class="col-sm-3" style="align-self: end;">
            <app-search #searchComponent [placeholder]="'Item Code, Category, Garment etc. (space delimited)'" [searchConfig]="searchConfig" [existingList]="consolidatedInventoryReportList"
               [searchApp]="'WMS'" [searchBarColLength]="11" [searchAddlSectionColLength]="1"></app-search> <!-- [sortColumns]="sortColumns" -->
         </div>


         <div class="custom-control custom-checkbox mr-sm-2 ml-3" style="align-self: end;">
            <input type="checkbox" class="custom-control-input ml-1" name="showImage" id="showImage" [checked]="showImage" (change)="onToggleImage();" [(ngModel)]="showImage">
            <label class="custom-control-label f-11" for="showImage">Show Image</label>
         </div>


         <div class="col-sm-1" *ngIf="true">
            <div class="d-flex d-inline ">
               <div>
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Report Format</label>
                  <select class="form-control form-control-sm custom-select mr-1 f-11" id="selectedReportViewFormat" name="selectedReportViewFormat"
                     [(ngModel)]="searchConfig.searchCriteria.searchSubType" (change)="onReportViewFormatChange()">
                     <option value="TABBED_VIEW">Tabbed</option>
                     <option value="SINGLE_PAGE_VIEW">Single Page</option>
                  </select>
               </div>
            </div>
         </div>


         <div class="col-sm-1" *ngIf="true">
            <div class="d-flex d-inline ">
               <div>
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">WMS Client</label>
                  <select class="form-control form-control-sm custom-select mr-1 f-11" id="selectedWmsClient" name="selectedWmsClient" [(ngModel)]="selectedWmsClientId" (change)="onWmsClientChange()">
                     <option value="ALL">Show All</option>
                     <option value="10001">Philcos Canada</option>
                     <option value="10003">Philcos USA</option>
                  </select>
               </div>
            </div>
         </div>


         <div class="col-sm-1" *ngIf="true">
            <div class="d-flex d-inline ">
               <div>
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Item Type</label>
                  <select class="form-control form-control-sm custom-select mr-1 f-11" id="selectedItemTypeSearch" name="selectedItemTypeSearch" [(ngModel)]="selectedItemTypeSearch"
                     (change)="onItemTypeSearchChange()">
                     <option value="ALL">Show All</option>
                     <option value="BLANKS">Blanks</option>
                     <option value="SPL_BLANKS_ONLY">Special Blanks</option>
                     <option value="FINISHED_PRODUCTS">Finished Products</option>
                  </select>
               </div>
            </div>
         </div>

         <div class="col-sm-2" *ngIf="true">
            <div class="d-flex d-inline ">
               <div>
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Inventory</label>
                  <select class="form-control form-control-sm custom-select mr-1 f-11" id="selectedInventoryCriteria" name="selectedInventoryCriteria" [(ngModel)]="selectedInventoryCriteria"
                     (change)="onInventoryCriteriaSearchChange()">
                     <option value="EXCLUDE_MAJORS">Exclude Majors Inventory</option>
                     <option value="INCLUDE_MAJORS" *ngIf="userAccess?.isSupportAdmin">Include Majors Inventory</option>
                     <option value="ONLY_MAJORS" *ngIf="searchConfig.searchCriteria.searchSubType!=='TABBED_VIEW'">Only Majors Inventory</option>
                  </select>
               </div>
            </div>
         </div>

         <div class="col-sm-1" *ngIf="true">
            <div class="d-flex d-inline ">
               <div>
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Category</label>
                  <select class="form-control form-control-sm custom-select mr-1 f-11" id="selectedCategory" name="selectedCategory" [(ngModel)]="selectedCategory" (change)="onCategoryChange()">
                     <option value="ALL">Show All</option>
                     <option *ngFor="let category of categoryList" [ngValue]="category.name">{{category.name}}</option>
                  </select>
               </div>
            </div>
         </div>


         <div class="col-sm-1" *ngIf="true">
            <div class="d-flex d-inline ">
               <div>
                  <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Garment</label>
                  <select class="form-control form-control-sm custom-select mr-1 f-11" id="selectedGarment" name="selectedGarment" [(ngModel)]="selectedGarment" (change)="onGarmentChange()">
                     <option value="ALL">Show All</option>
                     <option *ngFor="let garment of garmentList" [ngValue]="garment.name">{{garment.name}}</option>
                  </select>
               </div>
            </div>
         </div>


         <div class="col-sm-1 d-flex justify-content-end" style="align-self: end;" *ngIf="true">
            <button type="button" class="btn btn-sm btn-warning" (click)="createExcel()"><i class="fas fa-file-download"></i> &nbsp;Export</button>
         </div>

      </div>
   </div>
</div>


<!-- --------------------------------------------- TABBED VIEW --------------------------------------------- -->

<ng-container *ngIf="(searchConfig.searchCriteria.searchSubType === 'TABBED_VIEW') && consolidatedInventoryReportList?.length>0">
   <mat-tab-group animationDuration="0ms">
      <ng-container *ngFor="let cir of consolidatedInventoryReportList; index as cirIndex">
         <mat-tab>
            <ng-template mat-tab-label>
               <h6><span class="ml-2 font-weight-normal f-11">{{cir.warehouseAlias}} </span></h6>
            </ng-template>

            <div class="overflow-hidden">

               <div class="thead-dark m-1 f-12">
                  <span>Total Records: {{cir?.inventoryItemLinksViewList?.length}} </span>
                  <span *ngIf="'INCLUDE_MAJORS'===selectedInventoryCriteria" class="text-danger">(Inventory includes Majors)</span>
               </div>


               <div class="tableContainerSticky">
                  <table class="table table-condensed table-sm table-bordered">

                     <thead class="thead-dark">
                        <tr class="f-12">
                           <th style="width: 2%;" class=" align-middle text-center  border">
                              
                              <input type="checkbox"  name="selectAllTabbedCheckbox_{{cirIndex}}" id="selectAllSinglePageCheckbox_{{cirIndex}}" style="vertical-align: text-bottom;" (click)="selectAllTabbedReport(cir)" [checked]="cir.selected">
                              
                           </th>
                           <th style="width: 2%;" class=" align-middle text-center  border">#</th>
                           <th class=" align-middle text-center text-nowrap border" *ngIf="showImage" style="width: 60px;">Image</th>
                           <th style="width:auto;" class="align-middle text-center  border">Item Code</th>
                           <th style="width: fit-content;" class=" align-middle text-center  border">Item Description</th>
                           <th style="width: auto;" class=" align-middle text-center  border">Category</th>
                           <th style="width: auto;" class=" align-middle text-center  border">Garment</th>
                           <th style="width: auto;" class=" align-middle text-center  border">Client</th>
                           <th style="width: auto;" class=" align-middle text-center  border">Warehouse</th>
                           <th style="width: auto" class=" align-middle text-center  border">Qty At Hand</th>
                        </tr>
                     </thead>

                     <tbody>
                        <ng-container *ngFor="let inventoryItemLinksView of cir.inventoryItemLinksViewList; index as rowIndex">
                           <tr class="f-11" [style.background-color]="rowIndex%2===1?'rgba(0,0,0,.05)':''">

                              <td style="width: auto;" class=" align-middle text-center  border">
                                 <input type="checkbox"  name="select_{{cirIndex}}_{{rowIndex}}" id="select_{{cirIndex}}_{{rowIndex}}" (click)="onRowSelectTabbedReport(inventoryItemLinksView)" [checked]="inventoryItemLinksView.selected"> 
                              </td>                              

                              <td style="width: auto;" class=" align-middle text-center">{{rowIndex+1}}</td>
                              <td style="width: 60px;" *ngIf="showImage && inventoryItemLinksView.productImageUrl">
                                 <!--<img [src]="inventoryItemLinksView.productImageUrl" class="img-thumbnail img-fluid zoom-4 " style="width:60px;  height:60px; min-width: 60px;"
                                    [style.border-color]="'cadetblue'" onerror="this.src='/assets/default-image.jpg'" />-->
                                    <app-image-with-preview [defaultHeight]="'60px'" [defaultWidth]="'60px'"  [attachment]="inventoryItemLinksView.productAttachmentsDetails[0]">
                                    </app-image-with-preview>
                              </td>
                              <td style="width: 5%;" *ngIf="showImage && !inventoryItemLinksView.productImageUrl">&nbsp;</td>
                              <td style="width: auto;" class="align-middle text-left pl-2">{{inventoryItemLinksView.itemCode}}</td>
                              <td style="width: fit-content;" class="align-middle text-left pl-2">{{inventoryItemLinksView.itemName}}</td>
                              <td style="width: auto;" class="align-middle text-left pl-2">{{inventoryItemLinksView.category}}</td>
                              <td style="width: auto;" class="align-middle text-left pl-2">{{inventoryItemLinksView.garment}}</td>
                              <td style="width: auto;" class="align-middle text-left pl-2">{{inventoryItemLinksView.clientName}}</td>
                              <td style="width: auto;" class="align-middle text-left pl-2">{{inventoryItemLinksView.warehouseProviderName}} - {{inventoryItemLinksView.warehouseName}}</td>
                              <td style="width: auto;" class="align-middle text-center  pr-3">{{inventoryItemLinksView.stockInHand - inventoryItemLinksView.shipPendingQty}}</td>
                           </tr>
                        </ng-container>
                     </tbody>
                  </table>
               </div>
            </div>

         </mat-tab>
      </ng-container>
   </mat-tab-group>
</ng-container>


<!-- --------------------------------------------- SINGLE PAGE VIEW --------------------------------------------- -->

<ng-container *ngIf="(searchConfig.searchCriteria.searchSubType === 'SINGLE_PAGE_VIEW') && consolidatedInventoryReportList!==null 
   && consolidatedInventoryReportList!==undefined  && consolidatedInventoryReportList[0]!==null && consolidatedInventoryReportList[0]!==undefined">

   <div style="overflow: auto">

      <div class="thead-dark m-1 f-12">
         <span>Total Records: {{consolidatedInventoryReportList[0]?.rows?.length}} </span>
         <span *ngIf="'INCLUDE_MAJORS'===selectedInventoryCriteria" class="text-danger">(Inventory includes Majors)</span>
      </div>

      <div class="tableContainerSticky">
         <table class="table table-condensed table-sm table-bordered">
            <thead class="thead-dark">
               <tr class="f-12">
                  <th style="width: 2%;" class=" align-middle text-center  border">
                     <input type="checkbox"  name="selectAllSinglePageCheckbox" id="selectAllSinglePageCheckbox" style="vertical-align: text-bottom;" (click)="selectAllSinglePageReport(consolidatedInventoryReportList[0]?.rows)" [checked]="selectAllSinglePageReportChecked">
                  </th>
                  <th style="width: 2%;" class=" align-middle text-center  border">#</th>
                  <th class=" align-middle text-center text-nowrap border" *ngIf="showImage" style="width: 5%;">Image</th>
                  <ng-container *ngFor="let header of consolidatedInventoryReportList[0]?.headers; let headerIndex = index">
<!--                     
                     <ng-container *ngIf="(headerIndex === 1)">
                        <th style="width: auto" class="align-middle text-center pl-2  border" (click)="sortListByPropertyName(headerIndex)">{{header}}
                           <span *ngIf="headerIndex===currentSortColumnIndex && currentSortMode==='ASC'"><i class="fas fa-sort-amount-up-alt fa-lg mr-1" style="color:white; vertical-align: middle; cursor: pointer;"></i></span>
                           <span *ngIf="headerIndex===currentSortColumnIndex && currentSortMode==='DESC'"><i class="fas fa-sort-amount-down fa-lg" style="color:white; vertical-align: middle; cursor: pointer;"></i></span>
                           <span *ngIf="headerIndex!==currentSortColumnIndex"><i class="fas fa-sort fa-lg" style="color:#e4e1e196; vertical-align: middle; cursor: pointer;"></i></span>
                        </th>
                     </ng-container>
                  -->
                     <ng-container *ngIf="(headerIndex > 0) ">
                        <th style="width: auto" class="align-middle text-center pl-2  border" (click)="sortListByPropertyName(headerIndex)">{{header}}
                           <span *ngIf="headerIndex===currentSortColumnIndex && currentSortMode==='ASC'"><i class="fas fa-sort-amount-up-alt fa-lg mr-1" style="color:white; vertical-align: middle; cursor: pointer;"></i></span>
                           <span *ngIf="headerIndex===currentSortColumnIndex && currentSortMode==='DESC'"><i class="fas fa-sort-amount-down fa-lg" style="color:white; vertical-align: middle; cursor: pointer;"></i></span>
                           <span *ngIf="headerIndex!==currentSortColumnIndex"><i class="fas fa-sort fa-lg" style="color:#e4e1e196; vertical-align: middle; cursor: pointer;"></i></span>
                        </th>
                     </ng-container>
                  </ng-container>
               </tr>
            </thead>

            <ng-container *ngFor="let rowData of consolidatedInventoryReportList[0]?.rows; let rowDataIndex = index">
               
               <tr class="f-11" [style.background-color]="rowDataIndex%2===1?'rgba(0,0,0,.05)':''">

                  <td style="width: auto;" class=" align-middle text-center  border">
                     <input type="checkbox"  name="select_{{rowDataIndex}}" id="select_{{rowDataIndex}}" (click)="onRowSelectSinglePageReport(rowData)" [checked]="isRowSelectedSinglePageReport(rowData)"> 
                  </td>

                  <td style="width: auto;" class=" align-middle text-center  border">{{rowDataIndex+1}}</td>

                  <ng-container *ngIf="showImage">
                     <ng-container *ngFor="let counter of [].constructor(consolidatedInventoryReportList[0].headerCount); let columnIndex = index">
                        <ng-container *ngIf="columnIndex === 0">
                           <td style="width: 5%;" class=" align-middle text-center" *ngIf="rowData[columnIndex]?.productImageUrl">
                              <!--<img [src]=" rowData[0].productImageUrl" class="img-thumbnail img-fluid zoom-4 " style=" width:60px;  height:60px; min-width: 60px;"
                                 [style.border-color]="'cadetblue'" onerror="this.src='/assets/default-image.jpg'" />-->
                                 <app-image-with-preview [defaultHeight]="'60px'" [defaultWidth]="'60px'"  [attachment]="rowData[0]?.productAttachmentsDetails[0]">
                                 </app-image-with-preview>
                           </td>
                           <td style="width: 5%;"
                              *ngIf="!rowData[columnIndex]?.productImageUrl || rowData[columnIndex]?.productImageUrl === null || rowData[columnIndex]?.productImageUrl === undefined"> &nbsp;</td>
                        </ng-container>

                        <ng-container *ngIf="(columnIndex === 1 || columnIndex === 2 || columnIndex === 3 || columnIndex === 4)">
                           <td style="width: auto;" nowrap class="align-middle text-left pl-2  border">{{rowData[columnIndex]}}</td>
                        </ng-container>

                        <ng-container *ngIf="(columnIndex > 4)">
                           <td style="width: auto;" [style.background-color]="columnIndex===5?'#b9bcc0':''" class="align-middle text-center  pr-3 border">{{rowData[columnIndex]}}</td>
                        </ng-container>
                     </ng-container>
                  </ng-container>

                  <ng-container *ngIf="!showImage">
                     <ng-container *ngFor="let counter of [].constructor(consolidatedInventoryReportList[0].headerCount-1); let columnIndex = index">
                        <ng-container *ngIf="(columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 3)">
                           <td style="width: auto;" nowrap class="align-middle text-left pl-2  border">{{rowData[columnIndex+1]}}</td>
                        </ng-container>

                        <ng-container *ngIf="(columnIndex > 3)">
                           <td style="width: auto;" [style.background-color]="columnIndex===4?'#b9bcc0':''" class="align-middle text-center  pr-3 border">{{rowData[columnIndex+1]}}</td>
                        </ng-container>
                     </ng-container>
                  </ng-container>

               </tr>
            </ng-container>

         </table>
      </div>
   </div>

</ng-container>