import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Product, ProductAttachment } from 'src/app/shared/model/product.model';
import { ProductCatalogService } from 'src/app/shared/service/product-catalog.service';
import { ProductService } from 'src/app/shared/service/product.service';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { Router } from '@angular/router';
import { ProductSearchService } from 'src/app/shared/service/product-search.service';
import { ServiceStatus } from 'src/app/shared/model/service-status.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-image-card',
  templateUrl: './product-image-card.component.html',
  styleUrls: ['./product-image-card.component.css']
})
export class ProductImageCardComponent implements OnInit {
  

  constructor(private productSearchService:ProductSearchService,private router:Router,private productCatalogService: ProductCatalogService, private productService:ProductService, private sharedService:SharedService, private toastrService:ToastrService) { }
  @Input() product:Product;
  @Input() index:number;
  @Input() userAccess:UserAccess;
  @Output() onDelete = new EventEmitter<Product>();
  @Output() onSelect = new EventEmitter<boolean>();
  @Input() stopProductNavigation:boolean=false;
  @Input() stopProductDeletion:boolean=false;
  @Input() enableExclusiveSelection:boolean=false;
  //isSelectable:boolean=false;
 
  ngOnInit(): void {
     //this.evaluateIsSelectable();
  }
   evaluateIsSelectable():boolean {
      if(this.product.type==='BLANKS'){
         if((this.userAccess?.canCreateCatalog 
           && this.product.productImages && this.product.productImages.length>0 
            )){
               //this.isSelectable=true;
               return true;
            }else{
               //this.isSelectable=false;
               return false;
            }
         }else{
     if((this.userAccess?.canCreateCatalog 
      && this.product.approvalStatus!=='Concept Rejected' && this.product.productImages && this.product.productImages.length>0 
      && (this.product.brandActive || this.userAccess?.inactiveOverRideProductSelection) 
      && (this.product.licensorActive || this.userAccess?.inactiveOverRideProductSelection) 
      && (!this.product.exclusiveToCustomers || this.enableExclusiveSelection|| this.userAccess?.exclusiveOverRideProductSelection)
      )){
         //this.isSelectable=true;
         return true;
      }else{
         //this.isSelectable=false;
         return false;
            }
      }
   }

  handleThumbnailError(productAttachment:ProductAttachment,event){
     if(isNaN(productAttachment.thumbnailErrorCount)){
      productAttachment.thumbnailErrorCount=0;
     }
    event.target.src=productAttachment.fileSignedUrl;
    var x:number=productAttachment.thumbnailErrorCount;
    productAttachment.thumbnailErrorCount=x+1;
    if(productAttachment.thumbnailErrorCount<2){
       event.target.src=productAttachment.fileSignedUrl;
       productAttachment.thumbnailSignedUrl=productAttachment.fileSignedUrl;
    }else{
       event.target.src="/assets/default-image.jpg"
    }
    
 }
 
  deleteProduct(product: Product) {
    if (confirm("Do you really want to delete the product?\nThis will delete all files attached to the product")) {
       this.productCatalogService.checkAndRemoveForCatalog(product);
       this.productService.deleteProduct(product.id).subscribe((res) => {
         let serviceStatus:ServiceStatus=res as ServiceStatus;
         if(serviceStatus.status==='SUCCESS'){
            this.onDelete.emit(product);
            this.toastrService.success(serviceStatus.message);
         }else{
            this.toastrService.error('Error while deleting product - '+serviceStatus.message);
         }
       },
          (err) => {
             console.error(err);
          });
    }
 }

  selectionChanged(product:Product){
    this.productCatalogService.selectionChanged(product);
    this.onSelect.emit(this.isProductSelectedForCatalog(product.id));
 }

 isProductSelectedForCatalog(id: string): boolean {
    return this.productCatalogService.isProductSelected(id);
 }
 approvalStatusColor(status:string):string{
  if(this.sharedService.producatStatusColor.get(status)!==undefined){
     return this.sharedService.producatStatusColor.get(status);
  }else{
     return '';
  }
}

navigateToProduct(){
   
   //this.productSearchService.productSearchDetails.lastSelection
   
   this.productSearchService.productSearchDetails.lastSelection=this.index
   //this.router.navigate(['/product/view/'+this.product.licensorId+'/'+this.product.brandId+'/'+this.product.id]);
}

footerBgColor(product:Product):string{
   return (product.brandActive===true && product.licensorActive)?'active':'inactive';
}
   
   changePriority(product: Product) {
      if (this.userAccess?.canChangeProductPriority) {
         this.productService.changePriority(product.id, !product.priority).subscribe(res => {
            let serviceStatus: ServiceStatus = res as ServiceStatus;
            if (serviceStatus && serviceStatus.status === 'SUCCESS') {
               product.priority = !product.priority;
            } else {
               this.toastrService.error('Failed to change priority - ' + serviceStatus.message);
            }
         }, err => {
            this.toastrService.error('Error while changing priority - ' + err);
         });
      }
   }
}
