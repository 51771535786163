<div class="" >
    <div *ngIf="product" class="p-2" style="max-height: 50vh">
        
        <div class="row" >
            <div class="col-2">
                

                <div style="position: relative;">
                    <h6 class="mat-tab-label f-14" style="font-weight: 600 !important;font-size: 18px; opacity: 1;">{{product.sku}}</h6>
                    <img [src]="product.productImages[0]?.thumbnailSignedUrl" 
                    class="img-thumbnail img-fluid zoom-4 "
                    style="
                    display: block;
                    max-width: 120px;
                    max-height: 120px;
                    width: 100%;
                    height: auto;
                    -o-object-fit: contain;
                    object-fit: contain;"
                    [style.border-color]="'cadetblue'"
                    (error)="handleThumbnailError(product.productImages[0],$event)" />
                </div>
                <!--<a type="button" style="color: black;" matTooltip="View product" matTooltipPosition="below" matTooltipClass="bg-dark" target="_blank"
                           [routerLink]="['/product/view/'+orderDetail.product.licensorId+'/'+orderDetail.product.brandId+'/'+orderDetail.product.id]">-->
                        <!--<app-image-with-preview [defaultHeight]="'auto'" [defaultWidth]="'230px'" [disablePreview]="true" [attachment]="orderDetail.product.productImages[0]">
                        </app-image-with-preview>-->
                        <!--</a>--> 
            </div>
            
            <div class="col-5">
                <mat-tab-group>
                    
                    <!--<mat-tab>
                        <ng-template mat-tab-label>
                            <h6 class="f-14">Files</h6>
                        </ng-template>
                        <mat-tab-group>-->
                            <mat-tab style="">
                                <ng-template mat-tab-label>
                                    <h6 class="f-14">Size Selection</h6>
                                </ng-template>
                                <div class="my-1">
                                    <button type="button" class="btn btn-sm f-13 btn-dark mt-2" (click)="resetSizeSelection()">Reset Selection</button>
                                    <button type="button" class="btn btn-sm f-13 btn-secondary mt-2 ml-2" (click)="copySizeSelectionToAllStyles()">Copy Selection To All Styles</button>
                                </div>
                                <div class="mt-1" class="border" style="overflow-y: auto;overflow-x: hidden;max-height: 30vh;"> <!-- -->
                                    
                                    <div class="row ml-1" >
                                        <div *ngFor="let itemList of ordeDetailSizeQuantityListArr;index as indexArr" class=" col-auto mt-2">
                                            <div *ngFor="let item of itemList;index as index" >
                                            <ng-container *ngIf="item && item!=='' && item!==' '">
                                            <div class="custom-control custom-checkbox mr-sm-2 " >
                                                <input type="checkbox" class="custom-control-input " id="size_{{product.id}}_{{indexArr}}_{{index}}"  [(ngModel)]="item.selected"
                                               >
                                                <label class="custom-control-label text-break f-12" for="size_{{product.id}}_{{indexArr}}_{{index}}">{{item.size}}</label>
                                            </div>
                                            </ng-container>
                                        </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <h6 class="f-14">Product Details</h6>
                                </ng-template>
                                <div class="overflow-hidden mt-1">
                                    <div class="row f-13 my-1">
                                        <div class="col-4">
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">SKU: </label>
                                                <div class="col-sm-7">{{product.sku}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">Description: </label>
                                                <div class="col-sm-7">{{product.description}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">Licensor: </label>
                                                <div class="col-sm-7">{{product.licensorName}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">Brand: </label>
                                                <div class="col-sm-7">{{product.brandName}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">Approval Status: </label>
                                                <div class="col-sm-7">{{product.approvalStatus}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">licensorComments: </label>
                                                <div class="col-sm-7">{{product.licensorComments}}</div>
                                            </div>
                                            
                                        </div>
                                        <div class="col-4">
                                            
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">Color: </label>
                                                <div class="col-sm-7">{{product.color}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">Treatment: </label>
                                                <div class="col-sm-7">{{product.treatment}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">Category: </label>
                                                <div class="col-sm-7">{{product.category}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">Garment: </label>
                                                <div class="col-sm-7">{{product.garment}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted">Print Effect: </label>
                                                <div class="col-sm-7">{{product.printEffect}}</div>
                                            </div>
                                            <div class="mb-2 row">
                                                <label class="col-sm-5 text-muted"># Of Screens: </label>
                                                <div class="col-sm-7">{{product.screenCount}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                            <!--<mat-tab>
                            <ng-template mat-tab-label>
                                <h6 class="f-14">Brand PNL</h6>
                            </ng-template>
                            <div class="f-13 my-1 text-danger">{{orderDetailInfo.orderDetail.pnlSpeciality}}</div>
                            <div class="overflow-hidden mt-3">
                                    <app-attachment-operations [header]="'Brand PNL'" [editable]="false" [showHeader]="false"
                                    [createAction]="false" [attachments]="getPreferredPnl(orderDetailInfo.orderDetail)" 
                                    [canUpload]="false" [canDownload]="userAccess?.canDownloadPnls" [canDelete]="false" [viewCreatedBy]="false"></app-attachment-operations>
                            </div>
                            </mat-tab>  -->
                        <!--</mat-tab-group>
                    </mat-tab>-->
                </mat-tab-group>
            </div>
            <div class="col-5">
                <!--<div style="overflow-y: auto;overflow-x: hidden;max-height: 40%;" class="border">
                <ng-container *ngFor="let audit of sageItemCreationHelper.createItemAudit">
                    <div class="p-1">
                        <span class="f-11" style="font-weight: 700;">{{audit.companyCode}} :{{audit.sageSkuCode}}</span> - <span class="f-11 text-sanger">{{audit.error}}</span>
                    </div>
                    <hr>

                </ng-container>
                </div>-->
                <ng-container *ngIf="sageItemCreationHelper.createItemAudit && sageItemCreationHelper.createItemAudit.length>0">
                  
                    <div *ngFor="let companyBasedAduit of companyBasedAudits" class="card mb-1">
                        
                        <div class="card-header p-2 f-13">{{companyBasedAduit[0]}} <span class="badge badge-pill badge-danger ml-auto f-11" *ngIf="getStatusBasesAuditsForCompanyErrorCount(companyBasedAduit[1],'Error')>0">{{getStatusBasesAuditsForCompanyErrorCount(companyBasedAduit[1],'Error')}} Error(s)</span></div> <!-- Outer Map Key (Category) -->
                            <div class=" card-body p-1" style="max-height: 15vh;overflow-y: auto;"><!-- -->
                                <div *ngFor="let statusBasedAudits of getStatusBasesAuditsForCompany(companyBasedAduit[1])">
                                    
                                       <!-- <div class="card-title m-1">{{ statusBasedAudits[0] }}</div>--> <!-- Inner Map Key (Subcategory) -->
                                        <ul [class]="statusBasedAudits[0]==='Success'?'text-success':'text-danger'">
                                            <li *ngFor="let audit of statusBasedAudits[1]">
                                                <span class="f-11"><span class="text-muted">{{audit.sageSkuCode}}</span><span *ngIf="audit.error && audit.error.length>0"> - {{audit.error}}</span></span>
                                            </li>
                                        </ul>
                                
                                </div>
                            </div>
                        </div>

                </ng-container>
            
            </div>   
        </div>
    </div>
</div>
<hr>
