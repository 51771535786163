import { PrinterMatrixComponent } from 'src/app/product-management/printer/printer-matrix/printer-matrix.component';
import { PrinterMatrix } from './printer-matrix.model';
import { OrderDetailSizeQuantity } from './sales/order-detail.model';
import { BlanksManufacturerMetadata } from './blanks-manufacturer-metadata.model';

export class Product {

    id: string;
    sku: string;
    licensorId: string;
    brandId: string;
    productName: string;
    licensorName: string;
    brandName: string;
    description: string;
    productImages: ProductAttachment[] = [];
    masterArts: ProductAttachment[] = [];
    pngFront: ProductAttachment[] = [];
    pngBack: ProductAttachment[] = [];
    seperations: ProductAttachment[] = [];
    approvalDocs: ProductAttachment[] = [];
    photoApproval: ProductAttachment[] = [];
    brandPnl: ProductAttachment[] = [];
    tagList: string[];
    color: string;
    garment: string;
    treatment: string;
    approvalStatus: string;
    artStatus:string;
    base64ImageDataUri = '';
    category: string;
    printEffect: string;
    screenCount: number;
    notes: string;
    imageCode: string;
    pantoneColors: string;
    licensorComments: string;
    brandCategory: string;
    statusDetails:StatusDetails[]=[];
    sampleDetails:SampleDetails[]=[];
    skuTrackerRetailerInfo:SkuTrackerRetailerInfo[]=[];
    statusDate:Date;
    skuTrackerExpanded:boolean=false;
    skuTrackerEditable:boolean=false;
    convertedToSKU: boolean=false;
    brandActive:boolean=false;
    licensorActive:boolean=false;
    licensingPreProApprovalRequired:boolean=false;
    exclusiveCustomers: ExclusiveCustomer[] = [];
    exclusiveToCustomers: boolean = false;
    photoApprovalRequired:boolean = false;
    isCollection:boolean=false;
    isCollectionMember:boolean=false;
    printerMatrix:PrinterMatrix=new PrinterMatrix;
    propogateStatusToMembers:boolean;
    photographer:string;
    priority:boolean;
    version:number;
    preferredPnlId:string;
    preProSampleApprovedPrinterId: string;
    preProApproved:boolean;
    origination:string;
    // Start - Blanks Product
    type: string;
    mockTemplates: ProductAttachment[] = [];
    specSheets: ProductAttachment[] = [];
    blanksManufacturerMetadataId: string;
    productAdditionalDetails:ProductAdditionalDetails;
    // Transient
    blanksManufacturerMetadata: BlanksManufacturerMetadata = new BlanksManufacturerMetadata();
    // End - Blanks Product
    constructor() {
        this.id = null;
        this.brandId = null;
        this.licensorId = null;
        this.productName = null;
        this.licensorName = null;
        this.brandName = null;
        this.description = null;
        this.productImages = null;
        this.tagList = [];
        this.productImages = [];
        this.masterArts = [];
        this.pngFront = [];
        this.pngBack = [];
        this.seperations = [];
        this.approvalDocs = [];
        this.photoApproval =[];
        this.exclusiveCustomers = []
        this.preProSampleApprovedPrinterId = null;
        this.mockTemplates = [];
        this.specSheets = [];
        this.productAdditionalDetails=new ProductAdditionalDetails();
    }

}


export class ProductAttachment {
    fileSignedUrl: any
    thumbnailSignedUrl: any;
    id: string;
    licensorId: string;
    brandId: string;
    productId: string;

    parentId:string;

    localfile: File;
    licensorName: string;
    brandName: string;
    sku: string;
    originalFileName: string;
    fileCategory: string;
    fileName: string;
    uploadDate: Date;
    created_by: string;
    sortOrder: number;
    endPoint: string;
    bucketName: string;
    thumbnailErrorCount: number;
    keyNameV2: string;
    toDelete: boolean = false;
    islocal: boolean = false;
    isSelected: boolean = false;
    updatedOn: Date;
    mimeType:string;
    liked:boolean;
    groupReferenceId:string;
    constructor() {
        this.thumbnailErrorCount = 0;
    }
}

export class CatalogList {
    classification: string = '';
    classificationValue: string = '';
    products: Product[] = [];
}

export class SubmissionSheetDetails {
    productId: string;
    licensorId: string;
    brandId: string;
    category: string;
    subCategory: string;
    pdfFormId: string;
    attachmentId: string;

    submissionSheetVariableDetails: SubmissionSheetVariableDetails[];
    pdfSrc: string;
    pdfFileName: string;
    s3TempFolder: string;
    status: string;
    statusDesc: string;
    sku:string;
    fileFormatToSave:string;
    formFlattening:boolean;
	fileType:string;
}

export class SubmissionSheetVariableDetails {
    id: string;
    varNameAlias: string;
    varName: string;
    varType: string;
    varSubType: string;
    varValue: string;
    varValueSource: string;
    varValueImageArrIndex: number;
    fileSignedUrl: string;
    thumbnailSignedUrl: string;
    productAttachmentId: string;
    multiValueDelimeter:string;
    fillable:boolean;
    display:boolean;
    sortOrder:number;
    objectIndex:number;
    images:ProductAttachment[]=[];
}

export class StatusDetails {
    id:string;
    productId: string;
    licensorId: string;
    brandId: string;
    sku:string;
    status:string;
    statusDate:Date;
    licensorComments:string;
    philcosComments:string;
    createdBy:string;
    createdOn:Date;
    current:boolean;
    fromCollection:boolean;
    propogateStatusToMembers:boolean;
}
export class SampleDetails {

    id:string;
    productId: string;
    licensorId: string;
    brandId: string;
    sku:string;
//Sample request
    sampleReqDate:Date;
    customerId:string;
    customerOrderDueDate:Date;
    requestTo:string;
    sampleReqQty:number;
//Sample received
    samplesReceivedDate:Date;
    samplesReceivedQty:number;
//PreProPhoto SUbmission    
    preProPhotoSamplesReqDate:Date;
    preProPhotoSamplesResDate:Date;
    preProPhotoSamplesQty:number;

//PRe Pro Submission
    
    preProSamplesReqDate:Date;
    preProSamplesQty:number;
    preProSamplesTrackingNo:string;
    preProSamplesResDate:Date;
    preProSamplesCourier:string;

//FInal Submission
    finalSamplesQty:number;
    finalSamplesReqDate:Date;
    finalSamplesTrackingNo:string;
    finalSamplesResDate:Date;
    finalSamplesCourier:string;
    
 //Generic   
    notes:string;
    createdBy:string;
    createdOn:Date;
    current:boolean;
    delete:boolean;
}

export class ProgressDetails {
    id:string;
    fileName: string;
    parentId: string;
    brandId: string;
    progress:number;
}

export class SkuTrackerRetailerInfo{
    id:string;
    productId: string;
    licensorId: string;
    brandId: string;
    sku:string;
    notes:string;
    createdBy:string;
    createdOn:Date;
    current:boolean;
    customerId:string;
    orderQty:number;
    orderDueDate:Date;
    orderShippedDate:Date;
    retailCost:number;
    wholesaleCost:number;
    status:string;
    comments:string;
    delete:boolean;


}

export class ExclusiveCustomer{
    id:string;
    parentId:string;
    customerId:string;
    customerName:string;
    lastModifiedDate:Date;
    lastModifiedBy:string;
    createdBy:string;
    createdOn:Date;
    expiry:Date;
    //expiryModifiedBy:string;
    reason:string;
    expired:boolean;
	
}

export class GarmentCategoryCombinations{
    garment:string;
    categoryList:string[]=[];
	
}

export class GroupedProductAttachment{
    groupItemConfig:any=new Object();
    groupAttachmentList:ProductAttachment[]=[];
    groupName:string;
    nonGrouped:boolean=false;
	
}


export class SageItemCreationHelper{
    product:Product;
    sizeSelectionList:OrderDetailSizeQuantity[]=[];
    createItemAudit: any[]=[];
    auditMap: Map<string, Map<string, any[]>>;
}

export class ProductVendorInfo {
   id: string;
   parentId: string;
   vendorId: string;
   vendorName: string;
   vendorSku: string;
   lastModifiedDate: Date;
   lastModifiedBy: string;
   createdBy: string;
   createdOn: Date;
}

export class ProductAdditionalDetails{
    constructor() {
        this.productVendorInfoList=[];
    }
    productVendorInfoList: ProductVendorInfo[] = [];
 }