import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SearchDetails } from 'src/app/shared/model/common/search-details.model';
import { FilterConfig } from 'src/app/shared/model/common/search-filter.model';
declare var $: any;

@Component({
   selector: 'app-external-filter',
   templateUrl: './external-filter.component.html',
   styleUrls: ['./external-filter.component.css']
})
export class ExternalFilterComponent implements OnInit {

   constructor() { }

   @Input() searchConfig: SearchDetails;
   @Output() event = new EventEmitter<string>();
   selectedFilterConfig: any = {};

   ngOnInit(): void {
   }

   selectFilterConfig(filterConfig: FilterConfig) {
      this.selectedFilterConfig = filterConfig;
      this.openModal();
   }

   openModal() {
      $('#SelectedFilterModal').modal('show');
   }

   applyFilter() {
      this.event.emit('APPLY_FILTER');
   }

   onKeyupModalFilterForFilterConfig(value: string, filterConfig: FilterConfig) {
      if (value.trim().length > 0) {

      } else {

      }
   }
}
