<ng-container *ngIf="searchConfig &&  searchConfig.filterType === 'EXTERNAL' && searchConfig.filterConfigs && searchConfig.filterConfigs.length>0">

   <div class="list-group list-group-item bg-dark text-white mt-2">
      <div class="d-flex justify-content-between padding-0">
         <div><i class="fas fa-filter mr-1"></i></div>
         <h6>Filters</h6>
         <button type="button" class="btn btn-sm bg-white py-0" (click)="searchConfig.resetFilterConfigs();applyFilter()" matTooltip="Reset applied filters" matTooltipPosition="right"
            matTooltipClass="bg-dark">
            <i class="fas fa-undo fa-sm"></i>
         </button>
      </div>
   </div>

   <div>
      <div *ngFor="let filterConfig of searchConfig.filterConfigs;index as filterConfigIndex">
         <div class="row m-1">
            <button class="btn btn-sm color-3 btn-block" (click)="selectFilterConfig(filterConfig)">{{filterConfig.label}}
               <i class="fas fa-plus"></i>
            </button>
         </div>
         <div class="row ml-1" *ngIf="filterConfig.options">
            <div class="col-sm-11" *ngFor="let option of filterConfig.options;index as index">
               <ng-container *ngIf="option.selected">
                  <div class="custom-control custom-checkbox mr-sm-2">
                     <input type="checkbox" class="custom-control-input " id="{{filterConfig.fieldName+index}}"
                        (change)="option.selected=$event.target.checked;searchConfig.chooseInCheckList(filterConfig)" [checked]="option.selected">
                     <label class="custom-control-label text-break" for="{{filterConfig.fieldName+index}}">{{option[filterConfig.optionDislpayVar]}}</label>
                  </div>
               </ng-container>
            </div>
         </div>
      </div>

      <div class="m-1 d-flex">
         <button class="btn bg-dark text-white btn-block" (click)="applyFilter()">Apply Filters</button>
      </div>
   </div>

   <!-- ******************************************************************************MODAL**********************************************************-->

   <!-- START - GENERIC Modal FILTER CONFIG -->
   <div class="modal fade" id="SelectedFilterModal" tabindex="-1" role="dialog" aria-labelledby="FilterModalTitle">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
         <div class="modal-content">

            <div class="modal-header">
               <h5 class="modal-title" id="{{selectedFilterConfig.label}}modalLongTitle">{{selectedFilterConfig.label}}</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span>&times;</span>
               </button>
            </div>

            <div class="modal-body">`
               <ng-container *ngIf="selectedFilterConfig.filterType==='IN_CHECKLIST'">
                  <input class="form-control mb-2" #modalSearchInput1 placeholder="Find.." (keyup)="onKeyupModalFilterForFilterConfig(modalSearchInput1.value,selectedFilterConfig)">
                  <div class="mb-2 d-flex justify-content-end">
                     <a (click)=" searchConfig.selectedAllForSearch(true,selectedFilterConfig)" class="btn btn-sm btn-warning bg-white">Select All</a>
                     <a (click)=" searchConfig.selectedAllForSearch(false,selectedFilterConfig)" class=" btn btn-sm btn-danger bg-white ml-2">Unselect All</a>
                  </div>
                  <div class="ml-2" *ngFor="let option of selectedFilterConfig.options">
                     <ng-container *ngIf="selectedFilterConfig.optionDislpayVar">
                        <!-- For complex JSON Objects-->
                        <div class="row" *ngIf="option[selectedFilterConfig.optionDislpayVar].toUpperCase().includes(modalSearchInput1.value.toUpperCase())">
                           <label class="cb-container">
                              <h6 class="ml-4 mt-1">{{option[selectedFilterConfig.optionDislpayVar]}}</h6>
                              <input type="checkbox" (change)="option.selected=$event.target.checked;searchConfig.chooseInCheckList(selectedFilterConfig)" [checked]="option.selected" #check>
                              <!-- (change)="selectedForSearch($event,item,type)"-->
                              <span class="checkmark"></span>
                           </label>
                        </div>
                     </ng-container>
                  </div>
               </ng-container>
            </div>

            <div class="modal-footer">
               <div class="m-1 d-flex">
                  <button class="btn bg-dark text-white btn-block" (click)="applyFilter()" data-dismiss="modal">Apply Filters</button>
               </div>
            </div>
         </div>
      </div>
   </div>

</ng-container>