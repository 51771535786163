import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { User } from '../model/user.model';
import { RoleService } from './role.service';
import { Permission } from '../model/permission.model';
import { UserAccess } from '../model/user-access.model';
import { Role } from '../model/role.model';
//import * as mime from 'mime-types';
import { Product, ProductAttachment, StatusDetails, SubmissionSheetVariableDetails } from '../model/product.model';
import { EmailAttachment } from '../model/compose-email.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
declare var $: any;

@Injectable({
   providedIn: 'root'
})

export class SharedService {


   tabSelectionMap = new Map<string, number>();
   producatStatusColor: Map<string, string>;
   productionStatusReasonColor: Map<string, string>;
   licComReqMandAppStatus: string[] = ['Concept Requires Revision', 'Pre-Pro Samples Requires Revision', 'Concept Approved with Changes'];
   licComReqOptAppStatus: string[] = ['Concept Retired', 'Concept Rejected', 'Pre-Pro Samples Rejected', 'Pre-Pro Approved with Changes', 'Pre-Pro Production'];
   licPreProApprovalNotRequiredStatus: string[] = ['Pre-Pro Samples Approved','Pre-Pro Approved with Changes','Fully Approved','Final Samples Submitted','Testing Doc Ready To Submit','Testing Approved','Testing Pending','Testing Requires Revision','Testing Approved with Changes','Testing Rejected','Concept Rejected','Concept Retired'];


   constructor(private dialog: MatDialog) {
      this.loadProducatStatusColor();
      this.loadProductionStatusReasonColor();


   }

   /*Blanks issue – Pink
Printer costing issue – blue
Freight cost issue- yellow
Sales resubmission issue- grey
Customer requested discount – florescent green
Chargebacks - orange*/

   loadProductionStatusReasonColor() {
      this.productionStatusReasonColor = new Map<string, string>();
      this.productionStatusReasonColor.set("Blanks Issue", "pink");
      this.productionStatusReasonColor.set("Printer Costing Issue", "blue");
      this.productionStatusReasonColor.set("Freight Cost Issue", "yellow");
      this.productionStatusReasonColor.set("Sales Resubmission Issue", "gray");
      this.productionStatusReasonColor.set("Customer Requested Discount", "green");
      this.productionStatusReasonColor.set("Chargebacks", "orange");
      this.productionStatusReasonColor.set("Re-Costing Required", "neonGreen");
      this.productionStatusReasonColor.set("Re-Estimation Required", "neonGreen");
      this.productionStatusReasonColor.set("Re-Submission", "tomato");
      this.productionStatusReasonColor.set("Submission", "turquoise");
      
      

      
   }

   loadProducatStatusColor() {
      this.producatStatusColor = new Map<string, string>();
      this.producatStatusColor.set("Not Submitted", "amber");
      this.producatStatusColor.set("Concept Pending", "blue");
      this.producatStatusColor.set("Concept Requires Revision", "pink");
      this.producatStatusColor.set("Concept Approved", "green");
      this.producatStatusColor.set("Concept Approved with Changes", "green");
      this.producatStatusColor.set("Concept Retired", "red");
      this.producatStatusColor.set("Concept Rejected", "red");
      this.producatStatusColor.set("Pre-Pro Samples Submitted", "blue");
      this.producatStatusColor.set("Pre-Pro Samples Requires Revision", "pink");
      this.producatStatusColor.set("Pre-Pro Samples Approved", "green");
      this.producatStatusColor.set("Pre-Pro Samples Rejected", "red");
      this.producatStatusColor.set("Final Samples Submitted", "blue");
      this.producatStatusColor.set("Fully Approved", "green");
      this.producatStatusColor.set("Revised Art Completed", "purple");
      this.producatStatusColor.set("Concept Ready To Submit", "turquoise");
      this.producatStatusColor.set("Pre-Pro Approved with Changes", "green");
      this.producatStatusColor.set("Pre-Pro Production", "blue");
      //Testing
      /*
Testing Document Ready to be Submitted – Aqua colour + Email notification to be sent same as
Concept ready to submit requirements
- Testing Pending – Blue colour
- Testing Require Revision – Pink colour (group email)
- Testing Approved – Green colour (group email)
- Testing Approved with Changes – Green colour (group email)
- Testing Rejected – Red colour (group email)
      */
      this.producatStatusColor.set("Testing Doc Ready To Submit", "turquoise");
      this.producatStatusColor.set("Testing Pending", "blue");
      this.producatStatusColor.set("Testing Requires Revision", "pink");
      this.producatStatusColor.set("Testing Approved", "green");
      this.producatStatusColor.set("Testing Approved with Changes", "green");
      this.producatStatusColor.set("Testing Rejected", "red");
      


   }

   setTabSelection(key:string,tab:number){
      console.log('Set Tab Selection - ',key,tab);
      this.tabSelectionMap.set(key,tab);
   }

   getTabSelection(key:string):number{
      console.log('Set Tab Selection - ',key,this.tabSelectionMap.get(key));
      return this.tabSelectionMap.get(key);
   }

   removeTabSelection(key:string){
      this.tabSelectionMap.delete(key);
   }



   // Checking if token is set
   private hasToken() {
      return !!localStorage.getItem('access_token');
   }





   /**
    * 
    * @param permissions 
    */
   createUserAccess(permissions: Permission[], isUserSupportAdmin: boolean): UserAccess {
      let newAccess: UserAccess = new UserAccess;
      newAccess.canViewUsersSection = this.isPermissionAvailable(permissions, "users.section", isUserSupportAdmin);
      newAccess.canCreateUser = this.isPermissionAvailable(permissions, "user.create", isUserSupportAdmin);
      newAccess.canEditUser = this.isPermissionAvailable(permissions, "user.edit", isUserSupportAdmin);
      newAccess.canDeleteUser = this.isPermissionAvailable(permissions, "user.delete", isUserSupportAdmin);
      newAccess.canViewLicensorsSection = this.isPermissionAvailable(permissions, "licensors.section", isUserSupportAdmin);

      newAccess.canCreateLicensor = this.isPermissionAvailable(permissions, "licensor.create", isUserSupportAdmin);
      newAccess.canEditLicensor = this.isPermissionAvailable(permissions, "licensor.edit", isUserSupportAdmin);
      newAccess.canDeleteLicensor = this.isPermissionAvailable(permissions, "licensor.delete", isUserSupportAdmin);
     
      newAccess.canViewPreProTracker = this.isPermissionAvailable(permissions, "prepro.tracker.view", isUserSupportAdmin);
      newAccess.canEditPreProTracker = this.isPermissionAvailable(permissions, "prepro.tracker.edit", isUserSupportAdmin);

      newAccess.canViewBrandsSection = this.isPermissionAvailable(permissions, "brands.section", isUserSupportAdmin);
      newAccess.canCreateBrand = this.isPermissionAvailable(permissions, "brand.create", isUserSupportAdmin);
      newAccess.canEditBrand = this.isPermissionAvailable(permissions, "brand.edit", isUserSupportAdmin);
      newAccess.canDeleteBrand = this.isPermissionAvailable(permissions, "brand.delete", isUserSupportAdmin);
      newAccess.canUploadBrandPnls = this.isPermissionAvailable(permissions, "brand.pnl.upload", isUserSupportAdmin);
      newAccess.canDeleteBrandPnls = this.isPermissionAvailable(permissions, "brand.pnl.delete", isUserSupportAdmin);
      newAccess.canDefaultPnl = this.isPermissionAvailable(permissions, "brand.default.pnl", isUserSupportAdmin);

      newAccess.canViewProductsSection = this.isPermissionAvailable(permissions, "products.section", isUserSupportAdmin);
      newAccess.canCreateProduct = this.isPermissionAvailable(permissions, "product.create", isUserSupportAdmin);
      newAccess.canEditProduct = this.isPermissionAvailable(permissions, "product.edit", isUserSupportAdmin);
      newAccess.canDeleteProduct = this.isPermissionAvailable(permissions, "product.delete", isUserSupportAdmin);
      newAccess.canViewArtTracker = this.isPermissionAvailable(permissions, "art.tracker.view", isUserSupportAdmin);
      newAccess.canEditArtTracker = this.isPermissionAvailable(permissions, "art.tracker.edit", isUserSupportAdmin);
      newAccess.canCloneProductAdvanced = this.isPermissionAvailable(permissions, "product.clone.advanced", isUserSupportAdmin);
      

      newAccess.canUploadVisuals = this.isPermissionAvailable(permissions, "product.visuals.upload", isUserSupportAdmin);
      newAccess.canDownloadVisuals = this.isPermissionAvailable(permissions, "product.visuals.download", isUserSupportAdmin);
      newAccess.canDeleteVisuals = this.isPermissionAvailable(permissions, "product.visuals.delete", isUserSupportAdmin);

      newAccess.canUploadMasterArts = this.isPermissionAvailable(permissions, "product.masterarts.upload", isUserSupportAdmin);
      newAccess.canDownloadMasterArts = this.isPermissionAvailable(permissions, "product.masterarts.download", isUserSupportAdmin);
      newAccess.canDeleteMasterArts = this.isPermissionAvailable(permissions, "product.masterarts.delete", isUserSupportAdmin);

      newAccess.canUploadPngFront = this.isPermissionAvailable(permissions, "product.png.front.upload", isUserSupportAdmin);
      newAccess.canDownloadPngFront = this.isPermissionAvailable(permissions, "product.png.front.download", isUserSupportAdmin);
      newAccess.canDeletePngFront = this.isPermissionAvailable(permissions, "product.png.front.delete", isUserSupportAdmin);

      newAccess.canUploadPngBack = this.isPermissionAvailable(permissions, "product.png.back.upload", isUserSupportAdmin);
      newAccess.canDownloadPngBack = this.isPermissionAvailable(permissions, "product.png.back.download", isUserSupportAdmin);
      newAccess.canDeletePngBack = this.isPermissionAvailable(permissions, "product.png.back.delete", isUserSupportAdmin);

      newAccess.canUploadPhotoApproval = this.isPermissionAvailable(permissions, "product.photoapproval.upload", isUserSupportAdmin);
      newAccess.canDownloadPhotoApproval = this.isPermissionAvailable(permissions, "product.photoapproval.download", isUserSupportAdmin);
      newAccess.canDeletePhotoApproval = this.isPermissionAvailable(permissions, "product.photoapproval.delete", isUserSupportAdmin);

      newAccess.canUploadPrinterFiles = this.isPermissionAvailable(permissions, "product.printerfiles.upload", isUserSupportAdmin);
      newAccess.canDownloadPrinterFiles = this.isPermissionAvailable(permissions, "product.printerfiles.download", isUserSupportAdmin);
      newAccess.canDeletePrinterFiles = this.isPermissionAvailable(permissions, "product.printerfiles.delete", isUserSupportAdmin);
      newAccess.canDownloadPnls = this.isPermissionAvailable(permissions, "product.pnls.download", isUserSupportAdmin);
      newAccess.canPreferPnl = this.isPermissionAvailable(permissions, "product.prefer.pnl", isUserSupportAdmin);
      newAccess.canViewProductSimilarDesigns = this.isPermissionAvailable(permissions, "product.similar.designs.view", isUserSupportAdmin);
      newAccess.canCreateCatalog = this.isPermissionAvailable(permissions, "product.catalog.create", isUserSupportAdmin);
      newAccess.canChangeProductPriority = this.isPermissionAvailable(permissions, "product.priority.change", isUserSupportAdmin);


      newAccess.canEditAdhocProduct = this.isPermissionAvailable(permissions, "adhoc.product.edit", isUserSupportAdmin);
      newAccess.canDeleteAdhocProduct = this.isPermissionAvailable(permissions, "adhoc.product.delete", isUserSupportAdmin);


      //Metadata
      newAccess.canViewMetadata = this.isPermissionAvailable(permissions, "metadata.view", isUserSupportAdmin);
      newAccess.canCreateMetadata = this.isPermissionAvailable(permissions, "metadata.create", isUserSupportAdmin);
      newAccess.canEditMetadata = this.isPermissionAvailable(permissions, "metadata.edit", isUserSupportAdmin);
      newAccess.canDeleteMetadata = this.isPermissionAvailable(permissions, "metadata.delete", isUserSupportAdmin);

      //Metadata
      newAccess.canViewProductMetadata = this.isPermissionAvailable(permissions, "product.metadata.view", isUserSupportAdmin);
      newAccess.canCreateProductMetadata = this.isPermissionAvailable(permissions, "product.metadata.create", isUserSupportAdmin);
      newAccess.canEditProductMetadata = this.isPermissionAvailable(permissions, "product.metadata.edit", isUserSupportAdmin);
      newAccess.canDeleteProductMetadata = this.isPermissionAvailable(permissions, "product.metadata.delete", isUserSupportAdmin);

      // UPC
      newAccess.canViewUpc = this.isPermissionAvailable(permissions, "product.upc.view", isUserSupportAdmin);
      newAccess.canCreateUpc = this.isPermissionAvailable(permissions, "product.upc.create", isUserSupportAdmin);

      //Brand Rights Overview
      newAccess.canViewBrandRights = this.isPermissionAvailable(permissions, "brand.rights.view", isUserSupportAdmin);
      newAccess.canCreateBrandRights = this.isPermissionAvailable(permissions, "brand.rights.create", isUserSupportAdmin);
      newAccess.canEditBrandRights = this.isPermissionAvailable(permissions, "brand.rights.edit", isUserSupportAdmin);
      newAccess.canDeleteBrandRights = this.isPermissionAvailable(permissions, "brand.rights.delete", isUserSupportAdmin);

      //Licensor Royalty Overview
      newAccess.canViewRoyalty = this.isPermissionAvailable(permissions, "licensor.royalty.view", isUserSupportAdmin);
      newAccess.canCreateRoyalty = this.isPermissionAvailable(permissions, "licensor.royalty.create", isUserSupportAdmin);
      newAccess.canEditRoyalty = this.isPermissionAvailable(permissions, "licensor.royalty.edit", isUserSupportAdmin);
      newAccess.canDeleteRoyalty = this.isPermissionAvailable(permissions, "licensor.royalty.delete", isUserSupportAdmin);

      // PDF Forms
      newAccess.canViewPdfForms = this.isPermissionAvailable(permissions, "pdfforms.view", isUserSupportAdmin);
      newAccess.canCreatePdfForms = this.isPermissionAvailable(permissions, "pdfforms.create", isUserSupportAdmin);
      newAccess.canEditPdfForms = this.isPermissionAvailable(permissions, "pdfforms.edit", isUserSupportAdmin);
      newAccess.canDeletePdfForms = this.isPermissionAvailable(permissions, "pdfforms.delete", isUserSupportAdmin);

      // SKU Tracker
      newAccess.canViewSkuTracker = this.isPermissionAvailable(permissions, "skutracker.view", isUserSupportAdmin);
      newAccess.canEditSkuTracker = this.isPermissionAvailable(permissions, "skutracker.edit", isUserSupportAdmin);

      //Submission Sheet & Apporval forms
      newAccess.canCreateSubmissionSheet = this.isPermissionAvailable(permissions, "submissionsheet.create", isUserSupportAdmin);
      newAccess.canCreateApprovalForms = this.isPermissionAvailable(permissions, "approvalforms.create", isUserSupportAdmin);
      newAccess.canCreatePrinterMatrix = this.isPermissionAvailable(permissions, "printermatrix.create", isUserSupportAdmin);
      newAccess.canCreatePhotoApproval = this.isPermissionAvailable(permissions, "photoApproval.create", isUserSupportAdmin);

      // Approval Docs
      newAccess.canViewProductApprovalSection = this.isPermissionAvailable(permissions, "product.approval.section.view", isUserSupportAdmin);
      newAccess.canUploadApprovalDocs = this.isPermissionAvailable(permissions, "product.approvaldocs.upload", isUserSupportAdmin);
      newAccess.canDownloadApprovalDocs = this.isPermissionAvailable(permissions, "product.approvaldocs.download", isUserSupportAdmin);
      newAccess.canDeleteApprovalDocs = this.isPermissionAvailable(permissions, "product.approvaldocs.delete", isUserSupportAdmin);

      // SKU Dashboard
      newAccess.canViewSkuDashbaord = this.isPermissionAvailable(permissions, "skudashboard.view", isUserSupportAdmin);

      // Customer Management
      newAccess.canViewCustomers = this.isPermissionAvailable(permissions, "customer.view", isUserSupportAdmin);
      newAccess.canCreateCustomers = this.isPermissionAvailable(permissions, "customer.create", isUserSupportAdmin);
      newAccess.canEditCustomers = this.isPermissionAvailable(permissions, "customer.edit", isUserSupportAdmin);
      newAccess.canDeleteCustomers = this.isPermissionAvailable(permissions, "customer.delete", isUserSupportAdmin);

      // Reports
      newAccess.canViewLicensorSampleReport = this.isPermissionAvailable(permissions, "licensor.sample.report.view", isUserSupportAdmin);
      newAccess.canViewUpcReport = this.isPermissionAvailable(permissions, "upc.report.view", isUserSupportAdmin);

      // Approval Docs
      newAccess.canUploadLicensorFiles = this.isPermissionAvailable(permissions, "licnesor.files.upload", isUserSupportAdmin);
      newAccess.canDownloadLicensorFiles = this.isPermissionAvailable(permissions, "licnesor.files.download", isUserSupportAdmin);
      newAccess.canDeleteLicensorFiles = this.isPermissionAvailable(permissions, "licnesor.files.delete", isUserSupportAdmin);

      // Contract Docs
      newAccess.canViewLicensorContractFiles = this.isPermissionAvailable(permissions, "licnesor.contract.files.view", isUserSupportAdmin);
      newAccess.canUploadLicensorContractFiles = this.isPermissionAvailable(permissions, "licnesor.contract.files.upload", isUserSupportAdmin);
      newAccess.canDownloadLicensorContractFiles = this.isPermissionAvailable(permissions, "licnesor.contract.files.download", isUserSupportAdmin);
      newAccess.canDeleteLicensorContractFiles = this.isPermissionAvailable(permissions, "licnesor.contract.files.delete", isUserSupportAdmin);

      //BRand Files
      newAccess.canUploadBrandFiles = this.isPermissionAvailable(permissions, "brand.files.upload", isUserSupportAdmin);
      newAccess.canDownloadBrandFiles = this.isPermissionAvailable(permissions, "brand.files.download", isUserSupportAdmin);
      newAccess.canDeleteBrandFiles = this.isPermissionAvailable(permissions, "brand.files.delete", isUserSupportAdmin);

      // Brand Trend Files
      newAccess.canUploadBrandTrendFiles = this.isPermissionAvailable(permissions, "brand.trend.files.upload", isUserSupportAdmin);
      newAccess.canDeleteBrandTrendFiles = this.isPermissionAvailable(permissions, "brand.trend.files.delete", isUserSupportAdmin);

      // Roles & Permission 
      newAccess.isSupportAdmin = this.isPermissionAvailable(permissions, "user.support.admin", isUserSupportAdmin);

      //
      newAccess.inactiveOverRideProductSelection = this.isPermissionAvailable(permissions, "inactive.product.selection.override", false);
      newAccess.exclusiveOverRideProductSelection = this.isPermissionAvailable(permissions, "exclusive.product.selection.override", false);
      newAccess.canDeleteSkuTrackerItems = this.isPermissionAvailable(permissions, "skutracker.items.delete", isUserSupportAdmin);

      //Blanks
      newAccess.canViewBlanks = this.isPermissionAvailable(permissions, "blanks.view", isUserSupportAdmin);
      newAccess.canCreateBlanks = this.isPermissionAvailable(permissions, "blanks.create", isUserSupportAdmin);
      newAccess.canEditBlanks = this.isPermissionAvailable(permissions, "blanks.edit", isUserSupportAdmin);
      newAccess.canDeleteBlanks = this.isPermissionAvailable(permissions, "blanks.delete", isUserSupportAdmin);

      // Sales
      newAccess.canViewSalesOrder = this.isPermissionAvailable(permissions, "sales.order.view", isUserSupportAdmin);
      newAccess.canViewAllSalesOrder = this.isPermissionAvailable(permissions, "sales.order.view.all", isUserSupportAdmin);
      
      newAccess.canExportSalesOrder = this.isPermissionAvailable(permissions, "sales.order.export", isUserSupportAdmin);
      newAccess.canExportSalesOrderRef = this.isPermissionAvailable(permissions, "sales.order.export.ref", isUserSupportAdmin);
      newAccess.canExportSalesOrderHistory = this.isPermissionAvailable(permissions, "sales.order.export.history", isUserSupportAdmin);
      newAccess.canCreateCatalogForSalesOrder = this.isPermissionAvailable(permissions, "sales.order.create.catalog", isUserSupportAdmin);
      newAccess.canCreateSalesOrder = this.isPermissionAvailable(permissions, "sales.order.create", isUserSupportAdmin);
      newAccess.canEditSalesOrder = this.isPermissionAvailable(permissions, "sales.order.edit", isUserSupportAdmin);
      newAccess.canCloneSalesOrder = this.isPermissionAvailable(permissions, "sales.order.clone", isUserSupportAdmin);
      newAccess.canDeleteSalesOrder = this.isPermissionAvailable(permissions, "sales.order.delete", isUserSupportAdmin);
      newAccess.canSubmitSalesOrder = this.isPermissionAvailable(permissions, "sales.order.submit", isUserSupportAdmin);
      newAccess.canUnsubmitSalesOrder = this.isPermissionAvailable(permissions, "sales.order.unsubmit", isUserSupportAdmin);
      newAccess.canReassignSalesOrder = this.isPermissionAvailable(permissions, "sales.order.reassign", isUserSupportAdmin);
      newAccess.canChangeProductionStatus = this.isPermissionAvailable(permissions, "sales.order.change.production.status", isUserSupportAdmin);
      newAccess.canOverrideProductionStatus = this.isPermissionAvailable(permissions, "sales.order.override.production.status", isUserSupportAdmin);
      newAccess.canRequestForReCosting = this.isPermissionAvailable(permissions, "sales.order.request.recosting", isUserSupportAdmin);
      newAccess.canSalesForAllCustomers = this.isPermissionAvailable(permissions, "sales.customers.all", isUserSupportAdmin);
      

      // Sales File Attachments
      newAccess.canUploadCustomerPO = this.isPermissionAvailable(permissions, "sales.customerpo.upload", isUserSupportAdmin);
      newAccess.canDeleteCustomerPO = this.isPermissionAvailable(permissions, "sales.customerpo.delete", isUserSupportAdmin);
      newAccess.canDownloadCustomerPO = this.isPermissionAvailable(permissions, "sales.customerpo.download", isUserSupportAdmin);
      newAccess.canRenameCustomerPO = this.isPermissionAvailable(permissions, "sales.customerpo.rename", isUserSupportAdmin);

      newAccess.canUploadSalesFiles = this.isPermissionAvailable(permissions, "sales.files.upload", isUserSupportAdmin);
      newAccess.canDeleteSalesFiles = this.isPermissionAvailable(permissions, "sales.files.delete", isUserSupportAdmin);
      newAccess.canDownloadSalesFiles = this.isPermissionAvailable(permissions, "sales.files.download", isUserSupportAdmin);
      newAccess.canRenameSalesFiles = this.isPermissionAvailable(permissions, "sales.files.rename", isUserSupportAdmin);
      newAccess.canQuickViewSalesFiles = this.isPermissionAvailable(permissions, "sales.files.quickView", isUserSupportAdmin);

      //sales Fullfillment
      newAccess.canViewProductionTracker = this.isPermissionAvailable(permissions, "production.tracker.view", isUserSupportAdmin);
      newAccess.canEditProductionTracker = this.isPermissionAvailable(permissions, "production.tracker.edit", isUserSupportAdmin);
      newAccess.canViewProductionTrackerList = this.isPermissionAvailable(permissions, "production.tracker.list.view", isUserSupportAdmin);

      // Costing
      newAccess.canViewCostingSection = this.isPermissionAvailable(permissions, "costing.section.view", isUserSupportAdmin);
      newAccess.canViewCostingAllocation = this.isPermissionAvailable(permissions, "costing.allocation.view", isUserSupportAdmin);
      newAccess.canEditCostingAllocation = this.isPermissionAvailable(permissions, "costing.allocation.edit", isUserSupportAdmin);
      newAccess.canSubmitCostingAllocation = this.isPermissionAvailable(permissions, "costing.allocation.submit", isUserSupportAdmin);
      newAccess.canSaveDraftCostingAllocation = this.isPermissionAvailable(permissions, "costing.allocation.save.draft", isUserSupportAdmin);
      
      newAccess.canViewCostingSummary = this.isPermissionAvailable(permissions, "costing.summary.view", isUserSupportAdmin);
      newAccess.canApproveCosting = this.isPermissionAvailable(permissions, "costing.approve", isUserSupportAdmin);
      newAccess.canViewRepeatOrderDetails = this.isPermissionAvailable(permissions, "repeat.order.details.view", isUserSupportAdmin);
      newAccess.canViewBlankSummary = this.isPermissionAvailable(permissions, "blank.summary.view", isUserSupportAdmin);
      newAccess.canViewPrinterSummary = this.isPermissionAvailable(permissions, "printer.summary.view", isUserSupportAdmin);

      // Estimation
      newAccess.canViewEstimationSection = this.isPermissionAvailable(permissions, "estimation.section.view", isUserSupportAdmin);
      newAccess.canViewEstimationCosting = this.isPermissionAvailable(permissions, "estimation.costing.view", isUserSupportAdmin);
      newAccess.canViewEstimationCostingSummary = this.isPermissionAvailable(permissions, "estimation.costing.summary.view", isUserSupportAdmin);
      newAccess.canEditEstimationCostingAllocation = this.isPermissionAvailable(permissions, "estimation.costing.allocation.edit", isUserSupportAdmin);
      newAccess.canSubmitEstimationCostingAllocation = this.isPermissionAvailable(permissions, "estimation.costing.allocation.submit", isUserSupportAdmin);
      newAccess.canAcknowledgeEstimation = this.isPermissionAvailable(permissions, "estimation.costing.acknowledge", isUserSupportAdmin);

      //sales dashboard 
      newAccess.canViewSalesDashbaord = this.isPermissionAvailable(permissions, "sales.dashboard.view", isUserSupportAdmin);

      //Vendor Management
      newAccess.canViewVendors = this.isPermissionAvailable(permissions, "vendors.view", isUserSupportAdmin);
      newAccess.canCreateVendors = this.isPermissionAvailable(permissions, "vendors.create", isUserSupportAdmin);
      newAccess.canEditVendors = this.isPermissionAvailable(permissions, "vendors.edit", isUserSupportAdmin);
      newAccess.canDeleteVendors = this.isPermissionAvailable(permissions, "vendors.delete", isUserSupportAdmin);

      newAccess.canViewProductionCoordinatorCustomerAssignment = this.isPermissionAvailable(permissions, "production.coordinator.customer.assignment.view", isUserSupportAdmin);
      
      //Printer Portal Management
      newAccess.canViewPrinterPortalList = this.isPermissionAvailable(permissions, "printer.portal.list.view", isUserSupportAdmin);

      //File Closure Summary
      newAccess.canViewFileClosureSummary = this.isPermissionAvailable(permissions, "file.closure.summary.view", isUserSupportAdmin);
      newAccess.canDownloadFileClosureSummary = this.isPermissionAvailable(permissions, "file.closure.summary.download", isUserSupportAdmin);

      //Task Management
      newAccess.canViewTaskManagement = this.isPermissionAvailable(permissions, "task.management.view", isUserSupportAdmin);

      // Printer Capacity
      newAccess.canViewPrinterCapacity = this.isPermissionAvailable(permissions, "printer.capacity.view", isUserSupportAdmin);
      newAccess.canDownloadPrinterCapacity = this.isPermissionAvailable(permissions, "printer.capacity.download", isUserSupportAdmin);

      //Royalty Mismatch
      newAccess.canViewRoyaltyMismatchList = this.isPermissionAvailable(permissions, "royalty.mismatch.list.view", isUserSupportAdmin);
      newAccess.canDownloadRoyaltyMismatch = this.isPermissionAvailable(permissions, "royalty.mismatch.list.download", isUserSupportAdmin);

      //Accounts - ChargeBack
      newAccess.canViewChargebackSummary = this.isPermissionAvailable(permissions, "chargeback.list.view", isUserSupportAdmin);
      newAccess.canExportChargeBackSummary = this.isPermissionAvailable(permissions, "chargeback.list.export", isUserSupportAdmin);
      newAccess.canMaintainChargeBack = this.isPermissionAvailable(permissions, "chargeback.maintain", isUserSupportAdmin);
      newAccess.canDeleteChargeBack = this.isPermissionAvailable(permissions, "chargeback.delete", isUserSupportAdmin);
      newAccess.canMaintainChargeBackFiles = this.isPermissionAvailable(permissions, "chargeback.files.maintain", isUserSupportAdmin);
      newAccess.canDownloadChargeBackFiles = this.isPermissionAvailable(permissions, "chargeback.files.download", isUserSupportAdmin);
      newAccess.canViewChargeBackFiles = this.isPermissionAvailable(permissions, "chargeback.files.view", isUserSupportAdmin);

      //Sage

      newAccess.canCreateItemSageAdhoc = this.isPermissionAvailable(permissions, "sage.create.item.adhoc", isUserSupportAdmin);
      newAccess.canRetryCreateItemSage = this.isPermissionAvailable(permissions, "sage.create.item.retry", isUserSupportAdmin);
      newAccess.canEditPurchaseOrder = this.isPermissionAvailable(permissions, "sage.edit.purchase.order", isUserSupportAdmin);
      newAccess.canRetryPurchaseOrderPdf = this.isPermissionAvailable(permissions, "sage.retry.purchase.order.pdf", isUserSupportAdmin);
      newAccess.canConsentPurchaseOrderOverride = this.isPermissionAvailable(permissions, "sage.override.purchase.order", isUserSupportAdmin);
      
      
      // WMS
      newAccess.canViewWmsReports = this.isPermissionAvailable(permissions, "wms.report.view", isUserSupportAdmin);
      newAccess.canViewWmsDashboard = this.isPermissionAvailable(permissions, "wms.dashboard.view", isUserSupportAdmin);
      
      // Blanks Product
      newAccess.canViewBlanksProduct = this.isPermissionAvailable(permissions, "blanks.product.view", isUserSupportAdmin);
      newAccess.canMaintainBlanksProduct = this.isPermissionAvailable(permissions, "blanks.product.maintain", isUserSupportAdmin);
      newAccess.canMaintainMockTemplates = this.isPermissionAvailable(permissions, "mock.templates.maintain", isUserSupportAdmin);
      newAccess.canMaintainSpecSheets = this.isPermissionAvailable(permissions, "spec.sheets.maintain", isUserSupportAdmin);
      newAccess.canDownloadMockTemplates = this.isPermissionAvailable(permissions, "mock.templates.download", isUserSupportAdmin);
      newAccess.canDownloadSpecSheets = this.isPermissionAvailable(permissions, "spec.sheets.download", isUserSupportAdmin);

      return newAccess;
   }

   /**
    * 
    * @param permissions 
    * @param id 
    */
   isPermissionAvailable(permissions: Permission[], id: string, isUserSupportAdmin: boolean): boolean {
      if (isUserSupportAdmin) {
         return true;
      } else {
         let temp: Permission[] = permissions.filter(itemInList => itemInList.securityId === id);
         return (temp.length == 1);
      }
   }

   sortListByPropertyName(list: any, propertyName: string, mode?:string): [] {
      if (propertyName && list && list != null) {
         let sortedList: [] = list.sort(function (a, b) {
            if (a[propertyName] != null && a[propertyName] != undefined && b[propertyName] != null && b[propertyName] != undefined) {
               const propertyValue = a[propertyName];
               if (typeof propertyValue === 'string') {
                  var textA = a[propertyName].toUpperCase();
                  var textB = b[propertyName].toUpperCase();
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
               }
               if (propertyValue instanceof Date) {
                  const dateA: Date = new Date(a[propertyName]);
                  const dateB: Date = new Date(b[propertyName]);
                  return (dateA.getTime() > dateB.getTime());
               }
               if (typeof propertyValue === 'number') {
                  const numA = (a[propertyName]);
                  const numB = (b[propertyName]);
                  //console.log(numA,numB);
                  if(mode && mode==='DESC'){
                     return(numA > numB) ? -1 : (numA < numB) ? 1 : 0;
                  }else{
                     return(numA < numB) ? -1 : (numA > numB) ? 1 : 0;
                  }
               }
            }
         });
         return sortedList;
      }
      return list;
   }

   sort(list: string[]): string[] {
      if (list != null) {
         let sortedList: string[] = list.sort(function (a, b) {
            var textA = a.toUpperCase();
            var textB = b.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
         });
         return sortedList;
      }
   }

   isSupportAdminUser(roles: Role[]): boolean {
      let temp: Role[] = roles?.filter(itemInList => itemInList.roleId === 'SUPPORT_ADMIN');
      return (temp?.length == 1);
   }
   loadMimeTypes(attachments: ProductAttachment[]) {
      if (attachments && attachments.length > 0) {
         attachments.forEach(element => {
            this.loadMimeType(element);
         });
      }
   }

   loadMimeType(productAttachment: ProductAttachment) {
      productAttachment.mimeType = this.getMimeType(productAttachment.originalFileName);
   }
   getMimeType(fileName: string): string {
      let imageExtensionArr: string[] = ["bmp", "cod", "gif", "jpe", "jpeg", "jpg", "jfif", "svg", "tif", "tiff", "ico", "png"];
      var ext = fileName.substr(fileName.lastIndexOf('.') + 1);
      if (ext && imageExtensionArr.includes(ext.toLowerCase())) {
         return "image/" + ext;
      }
      return "notApplicable";//mime.lookup(fileName);
   }

   isImage(productAttachment: ProductAttachment): boolean {
      if (!productAttachment.mimeType) {
         productAttachment.mimeType = this.getMimeType(productAttachment.originalFileName);
      }
      return productAttachment.mimeType.startsWith('image');
   }

   productAttachmentToEmailAttachment(productAttachments: ProductAttachment[]): EmailAttachment[] {
      let retVal: EmailAttachment[] = [];
      if (productAttachments && productAttachments.length > 0) {
         productAttachments.forEach(element => {
            let emailAttachment: EmailAttachment = new EmailAttachment;
            emailAttachment.attachmentFileName = element.originalFileName;
            emailAttachment.attachmentdId = element.id;
            emailAttachment.attachmentSource = 'AWS-S3';
            emailAttachment.attachmentExternal = true;
            emailAttachment.attachmentSelected = element.isSelected;
            retVal.push(emailAttachment);
         });
      }
      return retVal;
   }

   getListOfAttachmentNames(emailAttachments: EmailAttachment[]): any {
      let retVal: string[] = [];
      if (emailAttachments && emailAttachments.length > 0) {
         emailAttachments.forEach(element => {
            retVal.push(element.attachmentFileName);
         });
      }
      return retVal;
   }

   getConcatenatedString(inputArr: string[], length?: number): string {
      let retVal = inputArr.join(',');
      if (length && retVal.length > length) {
         retVal = retVal.substring(0, length - 3) + '...';
      }
      return retVal;
   }

   handleProductApprovalStatusChangeForCollection(statusDetails: StatusDetails, product: Product) {
      if (product.isCollection) {
         const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
               title: 'Propogate Collection Status',
               message: 'Do you want to continue to set the status to all the member products of the collection as "' + product.approvalStatus + '" ?',
               confirmBtnText: '  Yes  ',
               cancelBtnText: '   No   '
            }
         });
         confirmDialog.afterClosed().toPromise().then(result => {
            product.propogateStatusToMembers = false;
            statusDetails.propogateStatusToMembers = false;
            if (result === true) {
               product.propogateStatusToMembers = true;
               statusDetails.propogateStatusToMembers = true;
            }
         });
      }
   }
   /**
    * Method is use to download file.
    * @param data - Array Buffer data
    * @param type - type of the document.
    */
   downLoadFile(data: any, type: string, fileName?: string) {
      let blob = new Blob([data], { type: type });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
         if (fileName) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
         } else {
            window.navigator.msSaveOrOpenBlob(blob);
         }

      }
      else {
         if (fileName) {
            this.saveData(blob, fileName);
         } else {
            this.saveData(blob, "Export.xlsx");
         }
         /* let url = window.URL.createObjectURL(blob);
          
          let pwa = window.open(url);
          if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            alert('Please disable your Pop-up blocker and try again.');
          }*/
      }

   }
   saveData(dataBlob: Blob, fileName: string) {
      let a: HTMLAnchorElement = document.createElement("a");
      document.body.appendChild(a);
      // a.style = "display: none";
      var url = window.URL.createObjectURL(dataBlob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
   }



   setSelectedVariableImage(attachment: ProductAttachment, variable: SubmissionSheetVariableDetails) {
      variable.fileSignedUrl = attachment.fileSignedUrl;
      variable.thumbnailSignedUrl = attachment.thumbnailSignedUrl;
      variable.productAttachmentId = attachment.id;
   }

   getWeek(dowOffset: number, date: Date): number {
      /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */
      //let now=new Date();
      dowOffset = typeof (dowOffset) == 'number' ? dowOffset : 0; //default dowOffset to zero
      var newYear = new Date(date.getFullYear(), 0, 1);
      var day = newYear.getDay() - dowOffset; //the day of week the year begins on
      day = (day >= 0 ? day : day + 7);
      var daynum = Math.floor((date.getTime() - newYear.getTime() -
         (date.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;
      var weeknum;
      //if the year starts before the middle of a week
      if (day < 4) {
         weeknum = Math.floor((daynum + day - 1) / 7) + 1;
         if (weeknum > 52) {
            let nYear = new Date(date.getFullYear() + 1, 0, 1);
            let nday = nYear.getDay() - dowOffset;
            nday = nday >= 0 ? nday : nday + 7;
            /*if the next year starts before the middle of
              the week, it is week #1 of that year*/
            weeknum = nday < 4 ? 1 : 53;
         }
      }
      else {
         weeknum = Math.floor((daynum + day - 1) / 7);
      }
      return weeknum;
   }
   
   newGuid():string {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
          v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }

    isLicensorPreProApprovalMessageRequired(product){
      //return product.licensingPreProApprovalRequired && !(this.licPreProApprovalNotRequiredStatus.includes(product.approvalStatus));
      return product.licensingPreProApprovalRequired && !product.preProApproved;
   }

//Not confirmed or fully tested
deepClone(obj: any): any {
   if (obj === null || typeof obj !== 'object') {
       // If obj is null or not an object, return it as is
       return obj;
   }

   if (Array.isArray(obj)) {
       // If obj is an array, create a new array and recursively deep clone its elements
       return obj.map((item: any) => this.deepClone(item));
   }

   // If obj is an object, create a new object and recursively deep clone its properties
   const clonedObj: any = {};
   for (const key in obj) {
       if (Object.prototype.hasOwnProperty.call(obj, key)) {
           clonedObj[key] = this.deepClone(obj[key]);
       }
   }
   return clonedObj;
}

splitIntoChunks<T>(array: T[], chunkSize: number): T[][] {
   const result: T[][] = [];
   for (let i = 0; i < array.length; i += chunkSize) {
       result.push(array.slice(i, i + chunkSize));
   }
   return result;
}
}
