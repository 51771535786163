import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/shared/model/user.model';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/service/auth.service';
import { VendorsService } from 'src/app/shared/service/vendors/vendors.service';
import { BlanksSupplier } from 'src/app/shared/model/vendors/blanks-supplier.model';
import { TransformPipe } from 'src/app/transform.pipe';
import { SageService } from 'src/app/shared/service/sage/sage.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { VendorInfo } from 'src/app/shared/model/vendors/printer.model';
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-blanks-supplier',
  templateUrl: './blanks-supplier.component.html',
  styleUrls: ['./blanks-supplier.component.css']
})
export class BlanksSupplierComponent implements OnInit {

   createAction: boolean = false;
   viewAction: boolean = false;
   editable: boolean = false;
   sageIntegrationViewList:any[]=[];
   countriesWithRegionsDetails: any;
   currencyListFromMetadata: string[] = [];

   constructor(private authService: AuthService, private router: Router, private vendorService: VendorsService, private metadataService: MetadataService,
      private activatedRoute: ActivatedRoute, private toastrService: ToastrService, private sageService:SageService, private dialog: MatDialog, private httpClient: HttpClient,
      private transformPipe: TransformPipe) { }

   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;
   selectedBlanksSupplierId: string;
   blanksSupplier: BlanksSupplier;


   ngOnInit() {
      this.handleSubscriptions();
      //this.blanksSupplier = new BlanksSupplier();
      //this.getBlanksSupplier();
      this.evaluateAction();
      this.populateCountryDetails();
      this.loadCurrencyListFromMetadata();
      window.scrollTo(0, 0);
   }

   handleSubscriptions() {
      // Subscriptions
      this.userAccess = new UserAccess();
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });

      this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
         this.currentLoggedInUser = currentLoggedInUser;
      });
   }


   async getCompanyDetails() {
      await this.sageService.getAvailableCompanies().toPromise().then(
          response => {
             if (response.responseStatus.status === 'SUCCESS') {
                this.sageIntegrationViewList = response.responsePayload;
             }
          }
       );
    }


   loadCurrencyListFromMetadata() {
      this.metadataService.getMetadataValueListByClassificationAndType('SALES', 'CURRENCY').subscribe(response => {
         this.currencyListFromMetadata = response.responsePayload;
      }, err => {
         console.error('Error while loading Currency List from Metadata : ' + JSON.stringify(err));
      });
   }


   populateCountryDetails() {
      if (this.countriesWithRegionsDetails === null || this.countriesWithRegionsDetails === undefined) {
         this.httpClient.get("assets/country-region-data.json").subscribe(data => {
            this.countriesWithRegionsDetails = data;
         });
      }
   }


   onSubmit() {
      if (this.editable && !this.createAction) {
         this.updateBlanksSupplier();
      } else {
         this.createBlanksSupplier();
      }
   }


   getBlanksSupplier() {
      let blanksSupplierId = this.activatedRoute.snapshot.paramMap.get('id');
      this.selectedBlanksSupplierId = blanksSupplierId;
      if (blanksSupplierId) {
         this.vendorService.getBlanksSupplierById(blanksSupplierId).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.blanksSupplier = response.responsePayload;
            } else {
               this.toastrService.error(response.responseStatus.message);
            }
         });
      }
   }


   createBlanksSupplier() {
      this.vendorService.createBlanksSupplier(this.blanksSupplier).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.router.navigate(['blanksSupplier/list']);
         } else {
            console.error("BlanksSupplier Component - create blanksSupplier failed...", response.responseStatus.message);

            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }

   updateBlanksSupplier() {
      this.vendorService.updateBlanksSupplier(this.blanksSupplier).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.getBlanksSupplier();
            this.editable = false;
            this.viewAction = true;
            //this.router.navigate(['blanksSupplier/list']);
         } else {
            console.error("BlanksSupplier Component - update BlanksSupplier failed...", response.responseStatus.message);
            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }

   async evaluateAction() {
      const activatedRoutePath = this.activatedRoute.snapshot.routeConfig.path;
      if (this.activatedRoute.snapshot.paramMap.get('action') === "view") {
         this.viewAction = true;
         this.getBlanksSupplier();
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "create") {
         this.createAction = true;
         this.editable = true;
         await this.getCompanyDetails();
         this.blanksSupplier = new BlanksSupplier();
         this.retrieveBlanksSupplierDetails('Blanks Supplier', this.sageIntegrationViewList, this.blanksSupplier);
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "edit") {
         this.editable = true;
         this.createAction = false;
         this.getBlanksSupplier();
      }
   }

   
   goBack() {
      this.router.navigate(['blanksSupplier/list']);
   }


   edit(blanksSupplierId: string) {
      this.vendorService.getBlanksSupplierById(blanksSupplierId).subscribe(response => {
         this.blanksSupplier = response.responsePayload;
      });
      this.createAction = false;
      this.viewAction = false;
      this.editable = true;

   }

   cancelEdit() {
      this.getBlanksSupplier();
      this.editable = false;
      this.viewAction = true;
   }

   async addSageDetails(blanksSupplier: BlanksSupplier) {
      if (!blanksSupplier.vendorInfoList || blanksSupplier.vendorInfoList === null) {
         blanksSupplier.vendorInfoList = [];
      }
      await this.getCompanyDetails();
      this.retrieveBlanksSupplierDetails('Blanks Supplier', this.sageIntegrationViewList, this.blanksSupplier);
   }


   retrieveBlanksSupplierDetails(type: string, sageIntegrationViewList: any[], blanksSupplier: BlanksSupplier) {
      let companyList: any[] = [];
      let existingCompanyCodes = blanksSupplier.vendorInfoList.map(x => x.companyCode);

      sageIntegrationViewList.forEach(comp => {
         if (!existingCompanyCodes.includes(comp.companyCode)) {
            let entry: any = {};
            entry.id = comp.companyCode;
            entry.value = this.transformPipe.transform('company.name.' + comp.companyCode);
            companyList.push(entry);
         }
      });
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
         disableClose: true,
         data: {
            modalType: 'DROPDOWN_AND_TEXTINPUT',
            maxlength: 150,
            textInputRegex: '^[a-zA-Z][0-9]+$',
            textInputDefault: '',
            textInputRegexError: type + '\'s vendor code is invalid. The format must be X00000, for e.g. A00512',
            title: 'Add Blanks Supplier',
            message: 'To add a ' + type + ', please select the company & provide the ' + type + '\'s vendor code',
            confirmBtnText: 'Add ' + type,
            cancelBtnText: 'Cancel',
            detailsRequired: true,
            dropdownList: companyList
         }
      });
      confirmDialog.afterClosed().subscribe(response => {
         if (response) {
            let vendorCode = response.data.detailsText;
            let companyCode = response.data.dropdownSelection.id;
            if (!blanksSupplier.vendorInfoList || blanksSupplier.vendorInfoList === null) {
               blanksSupplier.vendorInfoList = [];
            }
            this.vendorService.retrieveVendorDetails(companyCode, vendorCode).subscribe(response => {
               if (response.responseStatus.status === 'SUCCESS') {
                  let vendorDetails: any[] = response.responsePayload.value;
                  if (!blanksSupplier.id) {
                     blanksSupplier.name = vendorDetails[0].vendorName;
                  }
                  let vendorInfo: VendorInfo = new VendorInfo();
                  if (vendorDetails[0].active === 'Active') {
                     vendorInfo.active = true;
                     if (!blanksSupplier.id) {
                        blanksSupplier.status = true;
                     }

                  }
                  vendorInfo.vendorType = type;
                  vendorInfo.companyCode = companyCode;
                  vendorInfo.vendorCode = vendorCode;
                  vendorInfo.vendorName = vendorDetails[0].vendorName;
                  blanksSupplier.vendorInfoList.push(vendorInfo);
               } else {
                  this.toastrService.error(response.responseStatus.message);
                  if (this.createAction) {
                     this.goBack();
                  }
               }
            }, err => {
               this.toastrService.error(err);
               if (this.createAction) {
                  this.goBack();
               }
            })
         } else {
            if (this.createAction) {
               this.goBack();
            }
         }
      });

   }

}
