import { SearchCriteriaBuilder } from "./search-criteria-builder.model";
import { FilterConfig, SearchFilter } from "./search-filter.model";

export class SearchDetails {
    searchType:string;
    searchCriteria:SearchCriteriaBuilder;
    filterType:string='INTERNAL';
    filterConfigs:FilterConfig[];
    filterConfigsSelectedCount:number;
    defaultNonDisplayFilterConfigs:FilterConfig[];
    lastSelection: number;
    loadMoreCount:number=25;
    private defaultSort:string;
    private defaultSortMode:string;

    constructor(searchType:string,defaultSort?:string,defaultSortMode?:string,loadMoreCount?:number){/*type:string,selectedSortByName:string,sortMode:string*/
        this.searchType=searchType;
        if(defaultSort){
        this.defaultSort=defaultSort;
        }
        if(defaultSortMode){
            this.defaultSortMode=defaultSortMode;
        }
        if(loadMoreCount){
            this.loadMoreCount=loadMoreCount;
        }
        this.reset();
        
    }

    reset() {
        
        this.searchCriteria=new SearchCriteriaBuilder(this.searchType);
        this.searchCriteria.selectedSortByName=this.defaultSort;
        this.searchCriteria.sortMode=this.defaultSortMode;
        this.resetLoadMoreCount();
     }
     resetLoadMoreCount() {
        this.searchCriteria.loadMoreCount = this.loadMoreCount;
     }

     setResultMetadata(searchresult:SearchCriteriaBuilder){
        
        this.searchCriteria.totalCount=searchresult.totalCount;
        this.searchCriteria.loadMore=searchresult.loadMore;
        this.searchCriteria.loadMoreCount=searchresult.loadMoreCount;
        this.searchCriteria.loadedCount=searchresult.loadedCount;
        this.searchCriteria.previousSearchQuery=searchresult.previousSearchQuery;
    }

    setFilterInList(filterName:string,filterType:string,values:string[]){
        let filter = this.setFilter( filterName, filterType, values, this.searchCriteria.filterInList);    
    }
    setFilterNotInList(filterName:string,filterType:string,values:string[]){
        let filter = this.setFilter( filterName, filterType, values, this.searchCriteria.filterNotInList);    
    }

    setFilterLikeInListWithAnd(filterName:string,filterType:string,values:string[]){
        let filter = this.setFilter( filterName, filterType, values, this.searchCriteria.filterLikeInListWithAnd);    
    }
    setFilterLikeInListWithOr(filterName:string,filterType:string,values:string[]){
        let filter = this.setFilter( filterName, filterType, values, this.searchCriteria.filterLikeInListWithOr);    
    }
    /*setFilterCustomDateRange(filterName:string,filterType:string,values:string[]){
        let filter = this.setFilter( filterName, filterType, values, this.searchCriteria.filterCustomDateRange);    
    }*/
    setFilterDateRange(filterName:string,filterType:string,values:string[]){
        let filter = this.setFilter( filterName, filterType, values, this.searchCriteria.filterDateRange);    
    }



    private setFilter(filterName: string, filterType: string, values: string[], filterList: SearchFilter[]) {
        let filter = this.getExistingFilter(filterList, filterName);    
        if (filter) {
            filter.filterName = filterName;
            filter.filterType = filterType;
            filter.values = values;
        } else {
            filter = new SearchFilter(filterName, filterType, values);
            filterList.push(filter);
        }
        return filter;
    }

    getExistingFilter(filterList: SearchFilter[], filterName: string) {
        let filter:SearchFilter;
        filterList.forEach(searchFilter => {
            if (searchFilter.filterName === filterName) {
                filter = searchFilter;
            }
        });
        return filter;
    }

   setFilterIndicatorList(filterName: string, filterType: string, values: string[]) {
      let filter = this.setFilter(filterName, filterType, values, this.searchCriteria.filterIndicatorList);
   }


   chooseInList(filterConfig: FilterConfig) {
      if ('ALL' === filterConfig.currentSelection) {
         this.setFilterInList(filterConfig.fieldName, 'String', []);
      } else {
         this.setFilterInList(filterConfig.fieldName, 'String', [filterConfig.currentSelection.toString()]);
      }
   }


   chooseInListIndicator(filterConfig: FilterConfig) {
      if ('ALL' === filterConfig.currentSelection) {
         this.setFilterIndicatorList(filterConfig.fieldName, 'String', []);
      } else {
         this.setFilterIndicatorList(filterConfig.fieldName, 'String', [filterConfig.currentSelection.toString()]);
      }
   }


   selectedAllForSearch(selection: boolean, filterConfig: FilterConfig) {
      if (filterConfig.options) {
         filterConfig.options.forEach(option => {
            option.selected = selection;
         });
      }
      this.chooseInCheckList(filterConfig);
   }

   chooseInCheckList(filterConfig: FilterConfig) {
      let arr: string[] = [];
      if (filterConfig.options) {

         filterConfig.options.forEach(option => {
            if (option.selected) {
               arr.push(option[filterConfig.optionValueVar]);
            }
         });
      }
      filterConfig.selectedCount = arr.length;
      this.setFilterInList(filterConfig.fieldName, 'String', arr);
   }
    
   chooseDateRange(filterConfig: FilterConfig, event?: any, searchAfterDateChange?: boolean) {
      if (!event) {
         filterConfig.currentSelection = undefined;
      }
      if (event && event.startDate && event.endDate) {
         this.setFilterDateRange(filterConfig.fieldName, 'String', [event.startDate.format("YYYY-MM-DD"), event.endDate.format("YYYY-MM-DD")]);
      } else {
         this.setFilterDateRange(filterConfig.fieldName, 'String', []);
      }
      //cant handle this from template as date chnage is being triggered no matter what
      /*if(filterConfig.currentSelection && searchAfterDateChange){
        this.search();
      }*/
      //}
      //If this.selected check is removed, event is triggered when date range componenet is initialized causing 2 searhced when loadin gthe page
   }

   resetFilterConfigs() {
      this.filterConfigs.forEach(filterConfig => {
         switch (filterConfig.filterType) {
            case 'DATE_RANGE':
               filterConfig.currentSelection = undefined;
               this.chooseDateRange(filterConfig);
               break;
            case 'IN_LIST':
               filterConfig.currentSelection = 'ALL'
               this.chooseInList(filterConfig);
               break;
            case 'IN_LIST_INDICATOR':
               filterConfig.currentSelection = 'ALL'
               this.chooseInListIndicator(filterConfig);
               break;
            case 'IN_CHECKLIST':
               filterConfig.options.forEach(Option => {
                  Option.selected = false;
               });
               this.chooseInCheckList(filterConfig);
               break;
            default:
               break;
         }
      });
   }
    
}
