import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from 'src/app/shared/model/user.model';
import { ApiResponse} from 'src/app/shared/model/api-response';
import { Observable} from "rxjs/index";
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { Dashboard } from '../model/dashoard/dashboard.model';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class UserManagementService {
  readonly baseUrl = environment.apiEndPoint;
  constructor(private http: HttpClient, private dialog:MatDialog,private toastrService:ToastrService) { }

  getUsers() : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl+'/user/list');
  }

  getUserById(id: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl+'/user/'+id);
  }

  getUserByToken(token: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl+'/user/token/'+token);
  }

  createUser(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl+'/user/create', user);
  }

  updateUser(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl+'/user/update/'+user.id,user);
  }

  deleteUser(id: string): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(this.baseUrl+id);
  }

  getUsersRoles() : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl+'/user/list');
  }

  getUsersRolePermissions(userId:string) : Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl+'/user/permissions/'+userId);
  }

  sendResetPasswordEmail(user: User){
    return this.http.post<ApiResponse>(this.baseUrl + '/login/password/forgot', user);
  }
  
  getUsersByRoleId(roleId: string){
   return this.http.post<ApiResponse>(this.baseUrl + '/user/list/roleId', roleId);
  }

  getAvailableDashboardsForUser(userId: string) : Observable<Dashboard[]> {
    return this.http.get<Dashboard[]>(this.baseUrl+'/dashboard/availableDashboards/'+userId);
  }

  setUserLandingPath(userId: string,landingPath:string){
    return this.http.post<ApiResponse>(this.baseUrl + '/user/landingPath/'+userId, landingPath);
   }
  showSelectionPopup(userId:string) {
        this.getAvailableDashboardsForUser(userId).subscribe(res=>{
          let availableDashboards:Dashboard[]=res;

          if (availableDashboards && availableDashboards.length > 0) {
            let avaiableDashBoardOptions: any[]=[];
            availableDashboards.forEach(dashboard => {
              let option={
              'id': dashboard.id, 'name': dashboard.title
              };
              avaiableDashBoardOptions.push(option);
            });
           
            const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
              disableClose: true,
              data: {
                title: 'Home Screen Configuration',
                modalType: 'DROPDOWN',
                message: 'Please select your preferred dashboard for home screen',
                confirmBtnText: 'Ok',
                cancelBtnText: 'Cancel',
                dropdownList: avaiableDashBoardOptions
              }
            });
            confirmDialog.afterClosed().subscribe(res => {
              if (res) {
                let selectedDashboard:Dashboard[]=availableDashboards.filter(x=>x.id===res);
                this.setUserLandingPathPreference('dashboard/'+selectedDashboard[0].name,userId);
              } else {

              }
            });
          }else{
            this.toastrService.info("No Dashboards available for your role");
          }
        });
          
       }
  setUserLandingPathPreference(landingPath: string, userId: string) {
   this.setUserLandingPath(userId,landingPath).subscribe(
    res=>{
        this.toastrService.success(res.responseStatus.message);
    },err=>{
      this.toastrService.error("Home screen configuration failed");
    }
   );
  }

}
